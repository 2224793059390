import { useCallback } from "react";
import { Contract } from "@/types";
import { ManageAccessDialog } from "./ManageAccessDialog";
import { AccessLevels } from "./types";
import { queryClient } from "@/contexts";
import { useSessionContext } from "@/contexts/SessionContext";
import { contractListQueryKeyPredicate, contractDetailQueryKey } from "@/querykeys";
import { router } from "@/routes/routes";

export interface ManageSingleContractAccessProps {
    contract: Contract;
    isOpen: boolean;
    onClose: () => void;
    refetchContracts: () => void;
}

export const ManageSingleContractAccess = ({
    contract,
    isOpen,
    onClose,
    refetchContracts,
}: ManageSingleContractAccessProps) => {
    const permissionLevel = contract.isPrivate ? AccessLevels.RESTRICTED : AccessLevels.PUBLIC;

    const { tenant } = useSessionContext();

    const handleRefetchContractsList = useCallback(() => {
        router.invalidate(); // Invalidate list query
        queryClient.removeQueries({ predicate: contractListQueryKeyPredicate });

        const queryKey = contractDetailQueryKey(tenant, contract.id);
        queryClient.removeQueries({
            predicate: (query) =>
                query.queryKey[0] === queryKey[0] &&
                query.queryKey[1] === queryKey[1] &&
                query.queryKey[2] === queryKey[2],
        });
        refetchContracts();
    }, [contract, tenant, refetchContracts]);

    return (
        <ManageAccessDialog
            isOpen={isOpen}
            onClose={onClose}
            refetchContracts={handleRefetchContractsList}
            contractIds={[contract.id]}
            defaultAccess={permissionLevel}
        />
    );
};
