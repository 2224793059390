import { FormControl, FormControlLabel, Radio, RadioGroup, Stack, Typography } from "@mui/material";
import { FormattedMessage } from "react-intl";
import { AccessLevel } from "./types";

export interface AccessLevelPickerProps {
    accessLevel: AccessLevel;
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

export const AccessLevelPicker = ({ accessLevel, onChange }: AccessLevelPickerProps) => {
    return (
        <FormControl>
            <RadioGroup name="access-radio-group" value={accessLevel} onChange={onChange}>
                <FormControlLabel
                    sx={{ m: 1 }}
                    value="public"
                    control={<Radio />}
                    label={
                        <Stack direction="column">
                            <Typography variant="subtitle1">
                                <FormattedMessage defaultMessage="Public" />
                            </Typography>
                            <Typography variant="body2">
                                <FormattedMessage defaultMessage="Contracts marked as public are visible to all users with view access." />
                            </Typography>
                        </Stack>
                    }
                />
                <FormControlLabel
                    sx={{ m: 1 }}
                    value="restricted"
                    control={<Radio />}
                    label={
                        <Stack direction="column">
                            <Typography variant="subtitle1">
                                <FormattedMessage defaultMessage="Restricted" />
                            </Typography>
                            <Typography variant="body2">
                                <FormattedMessage defaultMessage="Restricted contracts can also be viewed by users with admin role or contract responsibles." />
                            </Typography>
                        </Stack>
                    }
                />
            </RadioGroup>
        </FormControl>
    );
};
