import { gql, useMutation } from "@apollo/client";
import { useFeatureToggle } from "@ignite-analytics/feature-toggle";
import { Calendar, Lock, Trash, Users } from "@ignite-analytics/icons";
import { track } from "@ignite-analytics/track";
import { useRouter } from "@tanstack/react-router";
import * as React from "react";
import { useCallback, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { contractDetailQueryKey, contractListQueryKeyPredicate } from "@/querykeys";
import { useErrorHandler } from "@/errorHandling/ErrorHandlerContext";
import { useSnackbar } from "@/contexts/useSnackbar";
import { useSessionContext } from "@/contexts/SessionContext";
import { queryClient } from "@/contexts";
import { Entry, RiddleMenuWithButton } from "@/components/ui/Menu";
import { Row } from "../contractListItem";
import { BulkCreateReminderDialog } from "./BulkCreateReminderDialog";
import { BulkSetContractResponsiblesDialog } from "./BulkSetContractResponsiblesDialog";
import { ConfirmBulkDeleteContractsDialog } from "./ConfirmBulkDeleteContractsDialog";
import { ManageBulkContractAccess } from "@/components/ManageAccessPopup";
import { AccessLevel, AccessLevels } from "@/components/ManageAccessPopup/types";

type Props = {
    selectedRowIds: string[];
    selectedRows: Row[];
    resetSelectedRowIds: () => void;
};
const deleteContractsMutation = gql`
    mutation deleteContracts($ids: [ID!]!) {
        deleteContracts(ids: $ids) {
            deletedIds
        }
    }
`;

const bulkAddContractPersonsMutation = gql`
    mutation bulkAddContractResponsibles($input: BulkAddContractResponsiblesRequest!) {
        bulkAddContractResponsibles(input: $input) {
            contractIds
        }
    }
`;

const bulkCreateRemindersMutation = gql`
    mutation bulkCreateReminders($input: BulkCreateRemindersRequest!) {
        bulkCreateReminders(input: $input) {
            reminderIds
        }
    }
`;

export const BulkEditMenu: React.FC<Props> = ({ selectedRowIds, selectedRows, resetSelectedRowIds }) => {
    const bulkManageAccessFeatureToggle = useFeatureToggle("bulk-edit-manage-access");
    const router = useRouter();
    const [manageAccessPopupOpen, setManageAccessPopupOpen] = useState(false);
    const [confirmDeleteContractsOpen, setConfirmDeleteContractsOpen] = useState(false);
    const [addContractResponsiblesOpen, setAddContractResponsiblesOpen] = useState(false);
    const [createReminderOpen, setCreateReminderOpen] = useState(false);
    const { formatMessage } = useIntl();
    const { handleError } = useErrorHandler();
    const { postSnackbar } = useSnackbar();

    const [deleteContracts] = useMutation(deleteContractsMutation);
    const [bulkAddContractResponsibles] = useMutation(bulkAddContractPersonsMutation);
    const [bulkCreateReminders] = useMutation(bulkCreateRemindersMutation);
    const { tenant } = useSessionContext();

    const handleRefetchContractsList = useCallback(() => {
        router.invalidate(); // Invalidate list query
        queryClient.removeQueries({ predicate: contractListQueryKeyPredicate });
        selectedRowIds.forEach((contractId) => {
            const queryKey = contractDetailQueryKey(tenant, contractId);
            queryClient.removeQueries({
                predicate: (query) =>
                    query.queryKey[0] === queryKey[0] &&
                    query.queryKey[1] === queryKey[1] &&
                    query.queryKey[2] === queryKey[2],
            });
        });
    }, [router, selectedRowIds, tenant]);

    const handleBulkManageAccessSubmitted = useCallback(() => {
        track("Contracts: Bulk manage access", {
            numContracts: selectedRowIds.length,
        });
        handleRefetchContractsList();
    }, [handleRefetchContractsList, selectedRowIds]);

    const handleDeleteContracts = useCallback(() => {
        setConfirmDeleteContractsOpen(false);
        track("Contracts: Bulk delete contracts", {
            numContracts: selectedRowIds.length,
        });
        deleteContracts({
            variables: { ids: selectedRowIds },
            onCompleted: () => {
                postSnackbar({
                    message: <FormattedMessage defaultMessage="Contracts deleted successfully." />,
                });
                handleRefetchContractsList();
                resetSelectedRowIds();
            },
            onError: handleError,
        });
    }, [deleteContracts, handleError, postSnackbar, handleRefetchContractsList, resetSelectedRowIds, selectedRowIds]);

    const handleBulkAddContractResponsibles = useCallback(
        (responsibleIds: string[]) => {
            setAddContractResponsiblesOpen(false);
            track("Contracts: Bulk add contract responsibles", {
                numContracts: selectedRowIds.length,
                numResponsibles: responsibleIds.length,
            });
            bulkAddContractResponsibles({
                variables: { input: { contractIds: selectedRowIds, responsibleIds } },
                onCompleted: () => {
                    postSnackbar({
                        message: <FormattedMessage defaultMessage="Contract responsibles added successfully." />,
                    });
                    handleRefetchContractsList();
                },
                onError: handleError,
            });
        },
        [bulkAddContractResponsibles, handleError, postSnackbar, handleRefetchContractsList, selectedRowIds]
    );

    const handleBulkCreateReminders = useCallback(
        (title: string, offsetMonths: number) => {
            setCreateReminderOpen(false);
            track("Contracts: Bulk create reminders", {
                numContracts: selectedRowIds.length,
                offsetMonths,
            });
            bulkCreateReminders({
                variables: {
                    input: {
                        title,
                        contractIds: selectedRowIds,
                        offsetMonths,
                    },
                },
                onCompleted: () => {
                    postSnackbar({
                        message: <FormattedMessage defaultMessage="Contract alerts added successfully." />,
                    });
                    handleRefetchContractsList();
                },
                onError: handleError,
            });
        },
        [bulkCreateReminders, handleError, postSnackbar, handleRefetchContractsList, selectedRowIds]
    );

    let entries: Entry[] = [
        {
            label: formatMessage({ defaultMessage: "Add contract responsibles" }),
            icon: { pos: "start", component: <Users /> },
            onClick: () => setAddContractResponsiblesOpen(true),
        },
        {
            label: formatMessage({ defaultMessage: "Create alerts" }),
            icon: { pos: "start", component: <Calendar /> },
            onClick: () => setCreateReminderOpen(true),
        },
        {
            label: formatMessage({ defaultMessage: "Delete" }),
            danger: true,
            icon: { pos: "start", component: <Trash /> },
            onClick: () => setConfirmDeleteContractsOpen(true),
        },
    ];
    if (bulkManageAccessFeatureToggle) {
        entries = [
            {
                label: formatMessage({ defaultMessage: "Manage access" }),
                icon: { pos: "start", component: <Lock /> },
                onClick: () => setManageAccessPopupOpen(true),
            },
            ...entries,
        ];
    }

    const getDefaultAccess = useCallback((): AccessLevel => {
        if (selectedRows.some((row) => row.isPrivate)) {
            return AccessLevels.RESTRICTED;
        }
        return AccessLevels.PUBLIC;
    }, [selectedRows]);

    return (
        <>
            <RiddleMenuWithButton
                disabled={selectedRowIds.length === 0}
                buttonLabel={formatMessage({ defaultMessage: "Bulk actions" })}
                color="secondary"
                size="small"
                entries={entries}
            />
            {manageAccessPopupOpen && bulkManageAccessFeatureToggle && (
                <ManageBulkContractAccess
                    onClose={() => setManageAccessPopupOpen(false)}
                    isOpen={manageAccessPopupOpen}
                    contractIds={selectedRowIds}
                    refetchContracts={handleBulkManageAccessSubmitted}
                    defaultAccess={getDefaultAccess()}
                />
            )}
            <ConfirmBulkDeleteContractsDialog
                open={confirmDeleteContractsOpen}
                onConfirm={handleDeleteContracts}
                onCancel={() => setConfirmDeleteContractsOpen(false)}
                numContracts={selectedRowIds.length}
            />
            <BulkSetContractResponsiblesDialog
                open={addContractResponsiblesOpen}
                onCancel={() => setAddContractResponsiblesOpen(false)}
                onConfirm={handleBulkAddContractResponsibles}
                numContracts={selectedRowIds.length}
            />
            <BulkCreateReminderDialog
                open={createReminderOpen}
                onCancel={() => {
                    setCreateReminderOpen(false);
                }}
                onConfirm={handleBulkCreateReminders}
                selectedRows={selectedRows}
            />
        </>
    );
};
