import { GridColDef } from "@mui/x-data-grid-pro";
import { useMemo } from "react";
import { FormattedDate } from "react-intl";
import { Stack, Typography } from "@mui/material";
import { StatusCell } from "./StatusCell";
import { SupplierChip } from "@/components/SuppliersChip";
import { Row } from "./types";
import { TitleCustomField } from "@/components/CustomField/TitleCustomField";
import { totalOverviewRoute } from "@/routes/total-overview";
import { UserAvatars } from "@/components/UserAvatars";

export const useColumns = () => {
    return useMemo<GridColDef<Row>[]>(
        () => [
            {
                field: "title",
                headerName: "Contract",
                flex: 1,
                renderCell: (params) => {
                    return (
                        <TitleCustomField
                            contractId={params.row.id ?? ""}
                            title={params.row.title}
                            from={totalOverviewRoute.fullPath}
                        />
                    );
                },
            },
            {
                field: "supplier",
                headerName: "Supplier",
                flex: 1,
                renderCell: (params) => {
                    if (!params.row.supplier) return null;
                    return <SupplierChip name={params.row.supplier?.name ?? ""} id={params.row.supplier?.id ?? ""} />;
                },
            },
            {
                field: "startDate",
                headerName: "Start date",
                flex: 1,
                renderCell: (params) => {
                    return (
                        <Stack direction="row" alignItems="center" height="100%">
                            <Typography>
                                <FormattedDate
                                    value={params.row.startDate ?? undefined}
                                    day="2-digit"
                                    month="short"
                                    year="numeric"
                                />
                            </Typography>
                        </Stack>
                    );
                },
            },
            {
                field: "endDate",
                headerName: "End date",
                flex: 1,
                renderCell: (params) => {
                    return (
                        <Stack direction="row" alignItems="center" height="100%">
                            <Typography>
                                <FormattedDate
                                    value={params.row.endDate ?? undefined}
                                    day="2-digit"
                                    month="short"
                                    year="numeric"
                                />
                            </Typography>
                        </Stack>
                    );
                },
            },
            {
                field: "responsible",
                headerName: "Responsible",
                flex: 1,
                renderCell: (params) => {
                    return (
                        <Stack direction="row" alignItems="center" height="100%">
                            <Typography>
                                <UserAvatars users={params.row.responsible} maxAvatars={5} />
                            </Typography>
                        </Stack>
                    );
                },
            },
            {
                field: "status",
                headerName: "Status",
                flex: 1,
                renderCell: (params) => {
                    return <StatusCell startDate={params.row.startDate ?? null} endDate={params.row.endDate ?? null} />;
                },
            },
        ],
        []
    );
};
