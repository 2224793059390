import { Chip } from "@mui/material";
import { FormattedMessage } from "react-intl";

export interface UserRoleChipProps {
    role: string;
}

export const UserRoleChip: React.FC<UserRoleChipProps> = ({ role }) => {
    if (role === "global.admin") {
        return <Chip label={<FormattedMessage defaultMessage="Admin" />} />;
    }

    if (role === "global.editor") {
        return <Chip label={<FormattedMessage defaultMessage="Editor" />} />;
    }

    if (role === "global.viewer") {
        return <Chip label={<FormattedMessage defaultMessage="Viewer" />} />;
    }

    if (role === "global.contract-editor") {
        return <Chip label={<FormattedMessage defaultMessage="Contract Editor" />} />;
    }

    if (role === "global.contract-viewer") {
        return <Chip label={<FormattedMessage defaultMessage="Contract Viewer" />} />;
    }

    return <Chip label={role} />;
};
