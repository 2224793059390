import { useIntl } from "react-intl";
import { SidebarCard } from "./SidebarCard";
import { NotesV2 } from "@/components/Notes/NotesV2";

import { useNotes } from "@/components/Notes/NotesV2/hooks";
import { detailRoute } from "@/routes/detail/$id";

export const NotesCard: React.FC = () => {
    const { id } = detailRoute.useParams();
    const notes = useNotes(id);

    const { formatMessage } = useIntl();
    return (
        <SidebarCard label={formatMessage({ defaultMessage: "Notes" })}>
            <NotesV2
                notes={notes.notes}
                onUpdate={(id, text) => notes.update(id, text)}
                onCreate={(text) => notes.add(text)}
                onDelete={(id) => notes.delete(id)}
            />
        </SidebarCard>
    );
};
