import { getLocale } from "@ignite-analytics/locale";
import { LicenseInfo } from "@mui/x-license-pro";
import { RouterProvider } from "@tanstack/react-router";
import React, { useEffect } from "react";

import FatalErrorFallback from "./components/FatalErrorFallback";
import { ContextProviders } from "./contexts";
import { MicroAppMountValues } from "./types";
import { env } from "./env";
import { router } from "./routes/routes";

LicenseInfo.setLicenseKey(env.REACT_APP_MUI_TOKEN);

const App: React.FC<MicroAppMountValues> = ({
    locale = getLocale(),
    theme = "ignite-riddle",
    path,
    sessionContext,
}) => {
    React.useEffect(() => {
        const data = { source: "ignite-breadcrumbs", payload: [{ text: "Contracts" }] };
        // Hack to make it work when switching microapps as tanstack router doesn't know whats going on
        const location = window.location.pathname.split("contracts")[1];
        const params = new URLSearchParams(window.location.search);
        router.navigate({ to: location, search: Object.fromEntries(params) });
        window.postMessage(data, window.location.origin);
    }, []);
    useEffect(() => {
        const onRouteChange = (event: MessageEvent) => {
            if (
                event.data.type === "route-change" &&
                ["breadcrumbs", "globalSearchBar", "sidebar"].includes(event.data.context)
            ) {
                router.navigate({ to: event.data.path, from: event.data.from });
            }
        };
        window.addEventListener("message", onRouteChange);
        return () => {
            window.removeEventListener("message", onRouteChange);
        };
    }, []);

    return (
        <ContextProviders locale={locale} theme={theme} path={path} sessionContext={sessionContext}>
            <RouterProvider
                context={{ session: sessionContext }}
                router={router}
                basepath={path}
                defaultErrorComponent={FatalErrorFallback}
            />
        </ContextProviders>
    );
};

export default App;
