import { Divider, Stack, ToggleButton, ToggleButtonGroup, Typography } from "@mui/material";
import { GridToolbarContainer, GridToolbarProps } from "@mui/x-data-grid-pro";
import { useMemo } from "react";
import { FormattedMessage } from "react-intl";
import { SearchField } from "@/pages/list/SearchField";

import { GridToolbarTotalItemsContainer } from "@/components/ContractsTablePro/Toolbar/GridToolbarTotalItemsContainer";
import { ContractStatus } from "./types";

export interface TableToolbarProps extends GridToolbarProps {
    searchTerm: string | undefined;
    onSearch: (value: string) => void;
    totalNumberOfRows: number;
    onSetStatusFilter: (status: ContractStatus) => void;
    statusFilter: ContractStatus | undefined;
}

export const TableToolbar: React.FC<TableToolbarProps> = ({
    searchTerm,
    onSearch,
    totalNumberOfRows,
    onSetStatusFilter,
    statusFilter,
}) => {
    // The toolbar is memoized to prevent unnecessary re-renders of this complex component tree.
    // Since the toolbar contains many child components and is rendered for every row in the data grid,
    // memoizing it improves performance by only re-rendering when its dependencies change.
    // The dependencies include all the props that could trigger a re-render like density, searchTerm,
    // selectedRowIds etc.
    // Ref: https://mui.com/x/react-data-grid/faq/#how-to-pass-props-to-the-data-grid-for-better-performance
    const toolbar = useMemo(() => {
        return (
            <GridToolbarContainer>
                <Stack
                    direction="row"
                    width="100%"
                    justifyContent="space-between"
                    alignItems="center"
                    gap={1}
                    padding={2.5}
                >
                    <Stack direction="row" spacing={1} alignItems="center">
                        <Stack direction="row" spacing={2} alignItems="center" pr={1}>
                            <GridToolbarTotalItemsContainer rowCount={totalNumberOfRows} />
                            <Divider orientation="vertical" flexItem />
                        </Stack>

                        <SearchField initialValue={searchTerm} onSearch={onSearch} width="300px" />
                    </Stack>
                    <Stack direction="row" ml="auto" spacing={1}>
                        <ToggleButtonGroup
                            value={statusFilter}
                            onChange={(event, value) => onSetStatusFilter(value)}
                            exclusive
                            size="small"
                        >
                            <ToggleButton value="expiring">
                                <Typography variant="textSm" fontWeight={500}>
                                    <FormattedMessage defaultMessage="Expiring contracts" />
                                </Typography>
                            </ToggleButton>
                            <ToggleButton value="expired">
                                <Typography variant="textSm" fontWeight={500}>
                                    <FormattedMessage defaultMessage="Expired contracts" />
                                </Typography>
                            </ToggleButton>
                        </ToggleButtonGroup>
                    </Stack>
                </Stack>
            </GridToolbarContainer>
        );
    }, [totalNumberOfRows, searchTerm, onSearch, onSetStatusFilter, statusFilter]);

    return toolbar;
};
