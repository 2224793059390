import { z } from "zod";

export type ContractPermissionEntry = {
    contractId: string;
    grantedReadAccess: boolean;
    isResponsible: boolean;
};

export const UserSchema = z.object({
    id: z.string(),
    firstName: z.string(),
    lastName: z.string(),
    email: z.string(),
});

export const ContractPermissionSchema = z.object({
    user: UserSchema,
    roleID: z.enum([
        "global.admin",
        "global.editor",
        "global.viewer",
        "global.contract-viewer",
        "global.contract-editor",
    ]),
    reads: z.array(
        z.object({
            contractId: z.string(),
            isResponsible: z.boolean(),
            grantedReadAccess: z.boolean(),
        })
    ),
    writes: z.array(
        z.object({
            contractId: z.string(),
            isResponsible: z.boolean(),
            grantedReadAccess: z.boolean(),
        })
    ),
});

export type ContractPermissions = z.infer<typeof ContractPermissionSchema>;
export type User = z.infer<typeof UserSchema>;
