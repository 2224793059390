import { Chip } from "@mui/material";
import { FormattedMessage } from "react-intl";

interface AccessChipProps {
    isPrivate: boolean;
}

export const AccessChip: React.FC<AccessChipProps> = ({ isPrivate }) => {
    if (isPrivate) {
        return <Chip label={<FormattedMessage defaultMessage="Restricted" />} color="warning" />;
    }
    return <Chip label={<FormattedMessage defaultMessage="Public" />} color="success" />;
};
