import { useFeatureToggle } from "@ignite-analytics/feature-toggle";
import { track } from "@ignite-analytics/track";
import { Button, Chip, Typography } from "@mui/material";
import Stack from "@mui/material/Stack";
import { GridDensity, GridPreferencePanelsValue, useGridApiRef } from "@mui/x-data-grid-pro";
import { useNavigate, useRouter } from "@tanstack/react-router";
import { AxiosResponseHeaders } from "axios";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { FormattedMessage } from "react-intl";
import { TablePro } from "src/components/ContractsTablePro";

import { MagicWand } from "@ignite-analytics/icons";
import { makeMessages as makeNoContractsMessages } from "src/components/NoContracts/messages";
import { NoContracts } from "src/components/NoContracts/noContracts";
import { NoContractsInOrg } from "src/components/NoContracts/noContractsInOrg";
import { makeContractsApiHttp as http } from "src/http";
import { contractsHelpCenterUrl } from "src/links";
import { contractListQueryKeyPredicate } from "@/querykeys";

import { ContractsTableToolbarProps } from "@/components/ContractsTablePro/Toolbar";
import { AppLayout } from "@/components/Layout/AppLayout";
import { LayoutsPopup } from "@/components/LayoutsPopup";
import { NewContractModal } from "@/components/NewContractModal";
import { UploadContractsModal } from "@/components/UploadContractsModal";
import { queryClient } from "@/contexts";
import { AddContractButton } from "./AddContractButton";
import { useColumnProps } from "./columns/useColumnProps";
import { readColumnVisibility, readDensity, saveColumnVisibility, saveDensity } from "./proTableManagmentDao";
import { useFilters } from "./useFilters/useFilters";
import { usePagination } from "./usePagination/usePagination";
import { useRows } from "./useRows/useRows";
import { listRoute } from "@/routes/list";
import { askDocumentExperimentRoute } from "@/routes/ask-document-experiment";
import { detailRoute } from "@/routes/detail/$id";

const ContractList: React.FC = () => {
    const tenant = localStorage.getItem("tenant") ?? "";
    const { newContract: newContractModal, searchTerm, status, sortOrder } = listRoute.useSearch();
    const [density, setDensity] = useState<GridDensity>(readDensity(tenant));
    const [openLayoutPopup, setOpenLayoutPopup] = useState(false);
    const [loading, setLoading] = useState(false);

    const {
        columnHeaders,
        pinnedColumns,
        handleSaveColumnWidth,
        orderedColumnIds,
        hasRenewalDate,
        handleColumnsOrderChange,
        sortByColumn,
        handleSortChange,
    } = useColumnProps({ setLoading });

    const { filters, onSearch } = useFilters({ setLoading });

    const {
        rows,
        totalNumberOfRows,
        isRowSelectable,
        resetRowSelectionModel,
        rowSelectionModel,
        onRowSelectionModelChange,
    } = useRows();

    const { page, pageSize, onPaginationChange } = usePagination({ setLoading });

    const router = useRouter();
    const navigate = useNavigate();
    const { contracts } = listRoute.useLoaderData();

    const [createContractModalOpen, setCreateContractModalOpen] = useState(newContractModal?.open ?? false);
    const [uploadContractsModalOpen, setUploadContractsModalOpen] = useState(false);

    const apiRef = useGridApiRef();

    const showBulkActionsFeatureToggle = useFeatureToggle("contracts-bulk-actions");
    const askDocumentExperimentFeatureToggle = useFeatureToggle("contracts-ask-document-questions-poc");

    useEffect(() => {
        track("Contracts List: page viewed");
    }, []);

    const navigateToContractDetails = (id: string) => {
        setCreateContractModalOpen(false);
        router.invalidate(); // created contract -> invalidate list cache
        queryClient.removeQueries({ predicate: contractListQueryKeyPredicate });
        navigate({
            from: listRoute.fullPath,
            to: detailRoute.fullPath,
            params: { id },
        });
        track("Contract Details: Contract created", { contractId: id });
    };

    const handleOpenAskDocumentPage = () => {
        navigate({
            from: listRoute.fullPath,
            to: askDocumentExperimentRoute.fullPath,
        });
    };

    const isContractListEmpty = useMemo(
        () => !searchTerm && !filters?.length && status === "all" && contracts?.total === 0,
        [searchTerm, filters, status, contracts?.total]
    );

    const noContractsMessages = makeNoContractsMessages();

    const onDensityChange = useCallback(
        (newDensity: GridDensity) => {
            saveDensity(tenant, newDensity);
            setDensity(newDensity);
        },
        [tenant]
    );

    const onOpenColumnsPreferences = useCallback(() => {
        apiRef?.current?.showPreferences(GridPreferencePanelsValue.columns);
    }, [apiRef]);

    const tableToolbarProps: ContractsTableToolbarProps = useMemo(() => {
        const selectedRows = rows.filter((row) => rowSelectionModel.includes(row.id));
        return {
            density,
            onDensityChange,
            onOpenColumnsPreferences,
            searchTerm,
            onSearch,
            setOpenLayoutPopup: () => setOpenLayoutPopup(true),
            hasRenewalDate,
            selectedRowIds: rowSelectionModel.map((id) => id.toString()),
            selectedRows,
            resetSelectedRowIds: resetRowSelectionModel,
            totalNumberOfRows,
        };
    }, [
        density,
        hasRenewalDate,
        onDensityChange,
        onOpenColumnsPreferences,
        onSearch,
        resetRowSelectionModel,
        rowSelectionModel,
        rows,
        searchTerm,
        totalNumberOfRows,
    ]);

    return (
        <AppLayout>
            <Stack spacing={3} height="100%">
                <Stack alignItems="center" justifyContent="space-between" direction="row">
                    <Typography variant="displayXs" component="h1" fontWeight="bold">
                        <FormattedMessage defaultMessage="Contract list" />
                    </Typography>
                    <Stack direction="row" gap={1}>
                        {askDocumentExperimentFeatureToggle && (
                            <Button startIcon={<MagicWand />} color="secondary" onClick={handleOpenAskDocumentPage}>
                                <Typography sx={{ pr: 1 }}>
                                    <FormattedMessage defaultMessage="Ask Ignite" />
                                </Typography>
                                <Chip label="BETA" color="ignite" />
                            </Button>
                        )}
                        <AddContractButton
                            onOpenCreateContractModal={() => setCreateContractModalOpen(true)}
                            onOpenUploadContractsModal={() => setUploadContractsModalOpen(true)}
                        />
                    </Stack>
                </Stack>

                {!loading && !isContractListEmpty && !contracts?.total && (
                    <NoContracts messages={makeNoContractsMessages()} />
                )}
                {!loading && isContractListEmpty && (
                    <NoContractsInOrg
                        helpCenterUrl={contractsHelpCenterUrl}
                        messages={noContractsMessages}
                        onCreateContract={() => setCreateContractModalOpen(true)}
                    />
                )}
                {contracts && contracts.total > 0 && (
                    <TablePro
                        sortDirection={sortOrder}
                        // NOTE: MUI gets very mad if you pass in an invalid column, and will issue onSortChange like crazy
                        //       This is somehow not reflected in the url, and we just get an infinite render loop
                        sortBy={sortByColumn}
                        headers={columnHeaders}
                        rows={rows}
                        rowSelectionModel={rowSelectionModel}
                        onRowSelectionModelChange={onRowSelectionModelChange}
                        isRowSelectable={isRowSelectable}
                        loading={loading}
                        checkboxSelectionVisibleOnly
                        checkboxSelection={showBulkActionsFeatureToggle}
                        totalNumberOfRows={totalNumberOfRows}
                        density={density}
                        orderedColumnsIds={orderedColumnIds}
                        onColumnsOrderChanged={handleColumnsOrderChange}
                        onColumnWidthChanged={handleSaveColumnWidth}
                        onColumnVisibilityModelChange={(model) => saveColumnVisibility(tenant, model)}
                        columnVisibilityModel={readColumnVisibility(tenant)}
                        onSortChange={handleSortChange}
                        currentPage={page}
                        pageSize={pageSize}
                        onPaginationChange={onPaginationChange}
                        apiRef={apiRef}
                        pinnedColumns={pinnedColumns}
                        tableToolbarProps={tableToolbarProps}
                    />
                )}

                <LayoutsPopup open={openLayoutPopup} onClose={() => setOpenLayoutPopup(false)} />

                <NewContractModal
                    prefilledSupplier={newContractModal?.supplier}
                    open={createContractModalOpen}
                    onClose={() => {
                        setCreateContractModalOpen(false);
                    }}
                    onSave={async (contract) => {
                        try {
                            const res = await http().post("contracts", contract);
                            const readContractIdFromHeaders = (headers: AxiosResponseHeaders) =>
                                headers.location.replace("/contracts/", "");
                            if (res.status === 201) {
                                const id = readContractIdFromHeaders(res.headers);
                                navigateToContractDetails(id);
                            }
                        } finally {
                            setCreateContractModalOpen(false);
                        }
                    }}
                />
                <UploadContractsModal
                    open={uploadContractsModalOpen}
                    onClose={() => {
                        setUploadContractsModalOpen(false);
                    }}
                />
            </Stack>
        </AppLayout>
    );
};

export default ContractList;
