import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Skeleton from "@mui/material/Skeleton";
import { useNavigate } from "@tanstack/react-router";
import { fm } from "src/contexts/IntlContext";
import { useContractLayout } from "src/hooks/useContractLayouts";
import { Layout, Group } from "src/types/Layout";
import { useEffect, useState } from "react";
import { track } from "@ignite-analytics/track";
import { useContractsApiFetch } from "src/hooks/useContractsApiFetch";
import { useContractsApiPut } from "src/hooks/useContractsApiPut";

import DragAndDropBoard from "src/components/DragAndDropBoard";
import { X } from "@ignite-analytics/icons";
import { Button, CircularProgress, IconButton, Stack, Tooltip, Typography } from "@mui/material";
import * as React from "react";
import { FormattedMessage } from "react-intl";
import { ContractFields, DataColumn } from "@/types";

import messages from "./messages";
import { merge } from "@/helpers/layoutMergeResolver";
import { useSnackbar } from "@/contexts/useSnackbar";
import { layoutsRoute } from "@/routes/layouts/$id";
import { listRoute } from "@/routes/list";

interface Localisation {
    remainingFieldsGroupName: string;
    defaultGroupName: string;
    header: string;
    back: string;
    cancel: string;
    save: string;
    layoutUpdated: string;
}

interface Props {
    columns: DataColumn[];
    layout: Layout | undefined;
    localisation: Localisation;
    onSave: (layout: Layout) => Promise<void>;
    onCancel: () => void;
}

const CustomFieldsLayoutSettings: React.FC<Props> = ({ columns, layout, localisation, onSave, onCancel }) => {
    const [mergedLayout, setMergedLayout] = useState<Layout>();
    useEffect(() => {
        const createDefaultLayout = (layoutColumns: DataColumn[], name: string) => ({
            id: "default",
            name: "Custom fields",
            groups: [
                {
                    name,
                    visible: true,
                    order: 0,
                    items: layoutColumns.map((field) => ({
                        refId: field.id,
                        name: field.name,
                        visible: true,
                        order: layoutColumns.indexOf(field),
                    })),
                },
            ],
        });

        const initialLayout = layout ?? createDefaultLayout(columns, localisation.defaultGroupName);
        setMergedLayout(merge(initialLayout, columns, localisation.defaultGroupName));
    }, [columns, layout, setMergedLayout, localisation.defaultGroupName]);

    const save = async () => {
        if (mergedLayout) {
            await onSave(mergedLayout);
        }
    };

    const updateGroups = (updatedGroups: Group[]) => {
        mergedLayout &&
            setMergedLayout({
                ...mergedLayout,
                groups: updatedGroups,
            });
    };

    return (
        <>
            {mergedLayout && (
                <CardContent data-testid="custom-fields-settings-card">
                    <Stack direction="row" justifyContent="space-between" p={3}>
                        <Typography>{localisation.header}</Typography>
                        <Tooltip title={localisation.back}>
                            <IconButton onClick={onCancel}>
                                <X />
                            </IconButton>
                        </Tooltip>
                    </Stack>
                    {mergedLayout?.groups ? (
                        <DragAndDropBoard groups={mergedLayout.groups} onUpdate={updateGroups} />
                    ) : (
                        <CircularProgress />
                    )}
                    <Stack direction="row" justifyContent="flex-end" alignItems="center" paddingY={1} spacing={1}>
                        <Button
                            color="secondary"
                            variant="outlined"
                            onClick={() => {
                                onCancel();
                            }}
                        >
                            {localisation.cancel}
                        </Button>
                        <Button onClick={save}>{localisation.save}</Button>
                    </Stack>
                </CardContent>
            )}
        </>
    );
};

export const LayoutsPage = () => {
    const { id } = layoutsRoute.useParams();

    const { layout, loading, refetch } = useContractLayout(id);
    const navigate = useNavigate();
    const { postSnackbar } = useSnackbar();
    const { data: allContractFields } = useContractsApiFetch<ContractFields>(`/contracts/metadata`);

    useEffect(() => {
        track("Contract layouts: A layout details previewd");
    }, []);

    const localisation: Localisation = {
        back: fm(messages.back).toString(),
        cancel: fm(messages.cancel).toString(),
        header: fm(messages.header).toString(),
        remainingFieldsGroupName: fm(messages.remainingFieldsGroupName).toString(),
        save: fm(messages.save).toString(),
        defaultGroupName: fm(messages.defaultCustomFieldsGroupName).toString(),
        layoutUpdated: fm(messages.layoutUpdatedMessage).toString(),
    };
    const back = () => {
        navigate({ to: listRoute.fullPath, search: { status: "all" } });
    };
    const update = useContractsApiPut();
    const updateLayout = async (layoutToUpdate: Layout) => {
        update<Layout>(`contracts/layouts/${layoutToUpdate.id}/content`, { groups: layoutToUpdate.groups }).then(() => {
            track("Contract layouts: A layout details updated");
            refetch();
            postSnackbar({
                message: <FormattedMessage defaultMessage="Layout updated" />,
            });
            back();
        });
    };

    const columns: DataColumn[] = allContractFields
        ? allContractFields?.customFields.map((item) => ({ id: item.id, name: item.name, refId: item.id }))
        : [];

    // TODO: Remove overflow: visible on card when all pages are migrated to the new layout (data-new-layout)
    return (
        <Card elevation={1} sx={{ overflow: "visible" }}>
            {loading && (
                <CardContent sx={{ pt: 4 }} data-testid="loader">
                    <Skeleton variant="text" sx={{ width: "50%", mb: 2 }} />
                    <Skeleton variant="rectangular" sx={{ width: "100%", height: 100, mb: 2 }} />
                    <Skeleton variant="rectangular" sx={{ width: "100%", height: 100, mb: 2 }} />
                    <Skeleton variant="rectangular" sx={{ width: "100%", height: 100 }} />
                </CardContent>
            )}
            {!loading && (
                <CustomFieldsLayoutSettings
                    onSave={updateLayout}
                    onCancel={back}
                    layout={layout}
                    localisation={localisation}
                    columns={columns}
                />
            )}
        </Card>
    );
};
