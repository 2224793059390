import { Stack } from "@mui/material";
import { TotalOverview } from "./TotalOverview";

export const TotalOverviewPage = () => {
    return (
        <Stack padding={2}>
            <TotalOverview />
        </Stack>
    );
};
