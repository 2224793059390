import { useMemo } from "react";
import { GridSortDirection } from "@mui/x-data-grid-pro";
import { useIntl } from "react-intl";
import { useFeatureToggle } from "@ignite-analytics/feature-toggle";
import { useCompanyCurrency } from "@/contexts/CompanyCurrencyContext";

import { readColumnsOrder, readColumnWidths, saveColumnsOrder, saveColumnWidths } from "../proTableManagmentDao";

import { fieldExists, translateColumnIdToSortId, translateSpecificFieldToDmsId } from "../loaders";

import { overrideColumnWidth } from "./overrideColumnWidth";
import { mapToHeaders } from "./mapToHeaders";
import { listRoute } from "@/routes/list";
import { ContractListSearchInput } from "@/routes/list/schema";

export function useColumnProps({ setLoading }: { setLoading: (loading: boolean) => void }) {
    const tenant = localStorage.getItem("tenant") ?? "";
    const { code: currencyCode } = useCompanyCurrency();
    const { formatMessage } = useIntl();
    const columnWidths = useMemo(() => readColumnWidths(tenant) ?? [], [tenant]);
    const { columns } = listRoute.useLoaderData();
    const { sortBy, sortOrder } = listRoute.useSearch();
    const navigate = listRoute.useNavigate();

    const pinnedColumns = useMemo(
        () => (columns?.specificFields.title.id ? [columns.specificFields.title.id] : []),
        [columns]
    );

    const contractStatusToggle = useFeatureToggle("contract-status-column");

    const columnHeaders = useMemo(() => {
        if (!columns) return [];
        return mapToHeaders(columns, columnWidths, currencyCode, formatMessage, contractStatusToggle);
    }, [columns, columnWidths, currencyCode, formatMessage, contractStatusToggle]);

    const handleSaveColumnWidth = (id: string, width: number) => {
        saveColumnWidths(tenant, overrideColumnWidth(columnWidths, { id, width }));
    };

    const orderedColumnIds = useMemo(
        () => readColumnsOrder(tenant) ?? columnHeaders.map((h) => h.id),
        [tenant, columnHeaders]
    );

    const hasRenewalDate = useMemo(() => {
        return columns?.specificFields.renewalDate !== undefined;
    }, [columns]);

    const handleColumnsOrderChange = (columnIds: string[]) => {
        saveColumnsOrder(tenant, columnIds);
    };

    const sortByColumn = useMemo(() => {
        if (!sortBy || !fieldExists(sortBy, columns)) {
            return undefined;
        }
        return translateSpecificFieldToDmsId(sortBy, columns);
    }, [sortBy, columns]);

    const handleSortChange = (newSortBy: string | undefined, newSortOrder: GridSortDirection) => {
        setLoading(true);
        // Translate sortBy=11 to sortBy=endDate if possible
        const sortByColumn = newSortBy ? translateColumnIdToSortId(newSortBy, columns) : undefined;
        if (sortByColumn === sortBy && newSortOrder === sortOrder) return;
        navigate({
            search: (prev: ContractListSearchInput) => ({ ...prev, sortBy: sortByColumn, sortOrder: newSortOrder }),
            replace: true,
        }).finally(() => {
            setLoading(false);
        });
    };

    return {
        columnHeaders,
        pinnedColumns,
        orderedColumnIds,
        hasRenewalDate,
        sortByColumn,
        handleSaveColumnWidth,
        handleColumnsOrderChange,
        handleSortChange,
    };
}
