import { Avatar, AvatarGroup, Stack, Tooltip, Typography } from "@mui/material";
import { FormattedMessage } from "react-intl";

import { useMemo } from "react";
import { UserItem } from "./UserItem";
import { UserSurplus } from "./UserSurplus";
import { Contact } from "@/entities/users";

export interface UserAvatarsProps {
    users: Pick<Contact, "id" | "firstName" | "lastName">[];
    maxAvatars?: number;
    avatarSize?: number;
}

export const UserAvatars: React.FC<UserAvatarsProps> = ({ users, maxAvatars = 3, avatarSize = 24 }) => {
    const sortedUsers = useMemo(() => {
        return users.sort((a, b) => {
            return a.firstName?.localeCompare(b.firstName ?? "") ?? 0;
        });
    }, [users]);

    if (users.length === 0) {
        return (
            <Typography variant="textSm" color="textTextHelper">
                <FormattedMessage defaultMessage="Not assigned" />
            </Typography>
        );
    }

    if (users.length === 1) {
        return (
            <Stack justifyContent="center" height="100%">
                <UserItem user={users[0]} />
            </Stack>
        );
    }

    return (
        <Stack justifyContent="center" alignItems="flex-start" height="100%">
            <AvatarGroup
                max={maxAvatars}
                renderSurplus={() => <UserSurplus textVariant="textXs" users={users.slice(maxAvatars - 1)} />}
                slotProps={{ surplus: { sx: { width: avatarSize, height: avatarSize } } }}
            >
                {sortedUsers.map((user) => (
                    <Tooltip key={user.id} title={<UserItem user={user} />}>
                        <Avatar src="" sx={{ width: avatarSize, height: avatarSize }}>
                            <Typography variant="textXs">{`${user?.firstName?.[0] ?? ""}${user?.lastName?.[0] ?? ""}`}</Typography>
                        </Avatar>
                    </Tooltip>
                ))}
            </AvatarGroup>
        </Stack>
    );
};
