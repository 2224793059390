import { Button, Popover, Stack } from "@mui/material";
import { useState, useRef, useMemo } from "react";
import { FormattedMessage } from "react-intl";
import { UserItem } from "@/components/UserAvatars";

import { UserRoleChip } from "./UserRoleChip";
import { ContractPermissions } from "@/hooks/useContractPermissions/types";

interface ViewAllUsersPopoverProps {
    permissions: ContractPermissions[];
}

export const ViewAllUsersPopover: React.FC<ViewAllUsersPopoverProps> = ({ permissions }) => {
    const [viewAllOpen, setViewAllOpen] = useState(false);
    const buttonRef = useRef<HTMLButtonElement>(null);

    const sortedPermissions = useMemo(() => {
        return permissions.sort((a, b) => {
            return a.user.firstName.localeCompare(b.user.firstName);
        });
    }, [permissions]);

    if (permissions.length === 0) return null;

    return (
        <Stack>
            <Button color="ghostGray" size="small" onClick={() => setViewAllOpen(true)} ref={buttonRef}>
                <FormattedMessage defaultMessage="View all" />
            </Button>

            <Popover
                open={viewAllOpen}
                onClose={() => setViewAllOpen(false)}
                anchorEl={buttonRef.current}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "center",
                }}
            >
                <Stack spacing={1} p={2} maxHeight={300} overflow="auto">
                    {sortedPermissions.map((permission) => (
                        <Stack
                            key={permission.user.id}
                            direction="row"
                            alignItems="center"
                            gap={3}
                            justifyContent="space-between"
                        >
                            <UserItem key={permission.user.id} user={permission.user} />
                            <UserRoleChip role={permission.roleID} />
                        </Stack>
                    ))}
                </Stack>
            </Popover>
        </Stack>
    );
};
