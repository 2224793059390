import { Card, Stack, Typography } from "@mui/material";
import { FormattedMessage } from "react-intl";
import { RenderMonetaryAmountCell } from "@/components/ContractsTablePro/renderCustomField";

export interface InfoCardProps {
    title: React.ReactNode;
    spend: number;
    numberOfContracts: number;
    icon: React.ReactNode;
}

export const InfoCard: React.FC<InfoCardProps> = ({ title, spend, numberOfContracts, icon }) => {
    return (
        <Card>
            <Stack spacing={2} p={2} minWidth={300}>
                <Stack direction="row" justifyContent="space-between" alignItems="center">
                    <Typography fontSize="textSm" color="textTextSecondary">
                        {title}
                    </Typography>
                    {icon}
                </Stack>
                <Stack justifyContent="bottom">
                    <Stack direction="row" alignItems="end" justifyContent="space-between">
                        <Stack>
                            <Typography variant="displaySm" fontWeight={500}>
                                {numberOfContracts}
                            </Typography>
                            <Typography variant="textXs" color="textTextHelper" alignSelf="flex-end">
                                <FormattedMessage defaultMessage="contracts" />
                            </Typography>
                        </Stack>
                        <Stack alignItems="bottom">
                            <Typography variant="textXl" color="textTextPrimary" fontWeight={500}>
                                <RenderMonetaryAmountCell amount={spend} />
                            </Typography>
                        </Stack>
                    </Stack>
                </Stack>
            </Stack>
        </Card>
    );
};
