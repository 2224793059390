import { AxiosResponseHeaders } from "axios";
import { makeContractsApiHttp as http } from "src/http";
import Stack from "@mui/material/Stack";
import { useFilterStateAction, useFilters } from "@ignite-analytics/filters";
import { track } from "@ignite-analytics/track";
import React, { useMemo, useState, useEffect } from "react";
import { Link, useNavigate, useRouter } from "@tanstack/react-router";
import { NoContracts } from "src/components/NoContracts/noContracts";
import { NoContractsInOrg } from "src/components/NoContracts/noContractsInOrg";
import { TablePro, makeMessages } from "src/components/ContractsTablePro";
import { ContractFields } from "src/types";
import { Link as MuiLink, Typography } from "@mui/material";
import { makeMessages as makeNoContractsMessages } from "src/components/NoContracts/messages";
import { DataGridPro, GridDensity, useGridApiRef, GridSortDirection } from "@mui/x-data-grid-pro";
import { contractsHelpCenterUrl } from "src/links";
import { usePermissionHandler } from "src/contexts/PermissionsContext";
import { ColumnHeader } from "src/components/ContractsTablePro/column";
import { FormattedMessage, useIntl } from "react-intl";
import { useQuery } from "@tanstack/react-query";
import {
    readColumnVisibility,
    readPinnedFiltersFieldsIds,
    readDensity,
    readColumnsOrder,
    saveFilters,
    saveFiltersToSession,
    savePinnedFieldsIds,
    saveColumnsOrder,
    saveDensity,
    readColumnWidths,
    saveColumnWidths,
    saveColumnVisibility,
} from "./proTableManagmentDao";
import { useCompanyCurrency } from "@/contexts/CompanyCurrencyContext";
import { contractDetailRoute, contractListRoute } from "@/Router";
import { NewContractModal } from "@/components/NewContractModal";
import { fieldExists, translateDmsIdToSpecificField, translateSpecificFieldToDmsId } from "./loaders";
import {
    RenderDateCell,
    RenderMonetaryAmountCell,
    RenderSupplierChip,
    RenderUsersChip,
} from "@/components/ContractsTablePro/renderCustomField";
import { usePermissionCheck } from "@/hooks/usePermissionCheck";
import { queryClient } from "@/contexts";
import { contractListQueryKeyPredicate, defaultLayoutQueryKey } from "@/querykeys";
import { createLayout, getLayouts, removeLayout, renameLayout, setAsDefaultLayout } from "@/hooks/useContractLayouts";
import { LayoutsPopup } from "@/components/LayoutsPopup";
import { CustomToolbar } from "@/components/ContractsTablePro/Toolbar";
import { AppLayout } from "@/components/Layout/AppLayout";

const overrideColumnWidth = (
    columnWidths: { id: string; width: number }[],
    columnWidth: { id: string; width: number }
): { id: string; width: number }[] => {
    const widthToReplace = columnWidths.find((col) => col.id === columnWidth.id);
    if (widthToReplace) {
        widthToReplace.width = columnWidth.width;
        return columnWidths;
    }
    return [...columnWidths, columnWidth];
};

const mapToHeaders = (
    metadata: ContractFields,
    columnWidths: { id: string; width: number }[],
    currency: string | undefined,
    formatMessage: (message: { defaultMessage: string }) => string
): ColumnHeader[] => {
    let headers = [
        {
            id: metadata.specificFields.title.id,
            fieldType: "title",
            label: formatMessage({ defaultMessage: "Contract title" }).toString(),
            width: columnWidths.find((c) => c.id === metadata.specificFields.title.id)?.width ?? 200,
        },
        {
            id: metadata.specificFields.supplier.id,
            fieldType: "supplier",
            label: metadata.specificFields.supplier.name,
            width: columnWidths.find((c) => c.id === metadata.specificFields.supplier.id)?.width ?? 200,
        },
        ...(metadata.specificFields.totalSpend !== undefined
            ? [
                  {
                      id: metadata.specificFields.totalSpend.id,
                      fieldType: "totalSpend",
                      label: (currency ? ` (${currency})` : "") + metadata.specificFields.totalSpend.name,
                      align: "right",
                      width: columnWidths.find((c) => c.id === metadata.specificFields.totalSpend?.id)?.width ?? 165,
                  } as const,
              ]
            : []),
        {
            id: metadata.specificFields.description.id,
            fieldType: "description",
            label: metadata.specificFields.description.name,
            width: columnWidths.find((c) => c.id === metadata.specificFields.description.id)?.width ?? 200,
        },
        {
            id: metadata.specificFields.startDate.id,
            fieldType: "startDate",
            label: metadata.specificFields.startDate.name,
            width: columnWidths.find((c) => c.id === metadata.specificFields.startDate.id)?.width ?? 120,
        },
        {
            id: metadata.specificFields.endDate.id,
            fieldType: "endDate",
            label: metadata.specificFields.endDate.name,
            width: columnWidths.find((c) => c.id === metadata.specificFields.endDate.id)?.width ?? 120,
        },
        ...(metadata.specificFields.renewalDate !== undefined
            ? [
                  {
                      id: metadata.specificFields.renewalDate.id,
                      fieldType: "renewalDate",
                      label: metadata.specificFields.renewalDate.name,
                      align: "right",
                      width: columnWidths.find((c) => c.id === metadata.specificFields.renewalDate?.id)?.width ?? 120,
                  } as const,
              ]
            : []),
        {
            id: metadata.specificFields.contractResponsible.id,
            fieldType: "contractResponsible",
            label: metadata.specificFields.contractResponsible.name,
            width: columnWidths.find((c) => c.id === metadata.specificFields.contractResponsible.id)?.width ?? 200,
        },
    ].concat(
        metadata.customFields.map((cf) => ({
            id: cf.id,
            fieldType: "custom",
            label: cf.name,
            width: columnWidths.find((c) => c.id === cf.id)?.width ?? 200,
        }))
    );

    headers = headers.concat({
        id: "isprivate",
        fieldType: "isprivate",
        label: formatMessage({ defaultMessage: "Access" }).toString(),
        width: columnWidths.find((c) => c.id === "isprivate")?.width ?? 120,
    });

    return headers;
};

const ContractList: React.FC = () => {
    const tenant = localStorage.getItem("tenant") ?? "";
    const {
        newContract: newContractModal,
        searchTerm,
        page,
        status,
        perPage,
        dmsFilter,
        sortOrder,
        sortBy,
    } = contractListRoute.useSearch();
    const [density, setDensity] = useState<GridDensity>(readDensity(tenant));
    const columnWidths = useMemo(() => readColumnWidths(tenant) ?? [], [tenant]);
    const [openLayoutPopup, setOpenLayoutPopup] = useState(false);

    const { formatMessage } = useIntl();
    const router = useRouter();
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const { contracts, columns, v2 } = contractListRoute.useLoaderData();
    const { checkPermissions } = usePermissionHandler();
    const { code } = useCompanyCurrency();
    const [createContractModalOpen, setCreateContractModalOpen] = useState(newContractModal?.open ?? false);

    const writeAccess = usePermissionCheck("contracts", "general", "write").data ?? false;
    const canEditLayouts = checkPermissions("contracts-layouts-config", "write");
    const apiRef = useGridApiRef();

    // ---- Filter related stuff ----
    const filters = useFilters();
    const sa = useFilterStateAction();
    const [filtersInititalized, setFilterInitialized] = useState(false);
    useEffect(() => {
        if (filtersInititalized) {
            // Only initialize filters from url on first render to prevent loop
            return;
        }
        if (!sa) {
            return;
        }
        if (dmsFilter) {
            sa({ type: "ADD_FILTERS", filters: dmsFilter });
        }
        setFilterInitialized(true);
    }, [sa, dmsFilter, filtersInititalized]);
    // update the URL whenever the filter changes
    // Except in the we just loaded this page, then the filter state is empty
    useEffect(() => {
        if (!filtersInititalized) {
            // useFilters() is not ready yet.
            return;
        }
        navigate({
            search: (prev) => ({
                ...prev,
                dmsFilter: filters && filters.length > 0 ? filters : undefined,
            }),
            replace: true,
        }).finally(() => {
            setLoading(false);
        });
    }, [filters, filtersInititalized, navigate]);
    // ---- Filter end ----

    useEffect(() => {
        track("Contracts List: page viewed");
    }, []);

    const navigateToContractDetails = (id: string) => {
        setCreateContractModalOpen(false);
        router.invalidate(); // created contract -> invalidate list cache
        queryClient.removeQueries({ predicate: contractListQueryKeyPredicate });
        navigate({
            from: contractListRoute.fullPath,
            to: contractDetailRoute.fullPath,
            params: { id },
        });
        track("Contract Details: Contract created", { contractId: id });
    };

    useEffect(() => {
        const fieldIds = readPinnedFiltersFieldsIds(tenant);
        filters && saveFiltersToSession(tenant, filters);
        const filtersToSave = filters?.filter((f) => fieldIds.includes(f.fieldId ?? "_")) ?? [];
        if (filtersToSave.length) {
            saveFilters(tenant, filtersToSave);
        }
    }, [filters, tenant]);

    const onPinnedFilter = (fieldId: string) => {
        const fieldIds = readPinnedFiltersFieldsIds(tenant);
        const pinnedFieldsIds = fieldIds.includes(fieldId)
            ? fieldIds.filter((id) => id !== fieldId)
            : fieldIds.concat(fieldId);
        const filtersToSave = filters?.filter((f) => pinnedFieldsIds.includes(f.fieldId ?? "_")) ?? [];
        savePinnedFieldsIds(tenant, pinnedFieldsIds);
        saveFilters(tenant, filtersToSave);
    };

    const headers = useMemo(
        () => columns && mapToHeaders(columns, columnWidths, code, formatMessage),
        [columns, columnWidths, code, formatMessage]
    );

    const pinnedColumns = useMemo(
        () => (columns?.specificFields.title.id ? [columns.specificFields.title.id] : []),
        [columns]
    );

    const isContractListEmpty = useMemo(
        () => !searchTerm && !filters?.length && status === "all" && contracts?.total === 0,
        [searchTerm, filters, status, contracts?.total]
    );

    const handleSortChange = (newSortBy: string | undefined, newSortOrder: GridSortDirection) => {
        setLoading(true);
        // Translate sortBy=11 to sortBy=endDate if possible
        const translatedSortBy = newSortBy ? translateDmsIdToSpecificField(newSortBy, columns) : undefined;
        if (translatedSortBy === sortBy && newSortOrder === sortOrder) return;
        navigate({
            search: (prev) => ({ ...prev, sortBy: translatedSortBy, sortOrder: newSortOrder }),
            replace: true,
        }).finally(() => {
            setLoading(false);
        });
    };
    const noContractsMessages = makeNoContractsMessages();

    const layouts = useQuery({
        enabled: true,
        queryKey: [tenant, "layouts", "list"],
        queryFn: getLayouts,
        staleTime: 1000 * 1 * 15,
    });

    const onDensityChange = (newDensity: GridDensity) => {
        saveDensity(tenant, newDensity);
        setDensity(newDensity);
    };

    return (
        <AppLayout>
            <Stack>
                <Typography variant="displayXs" component="h1" fontWeight="bold">
                    <FormattedMessage defaultMessage="Contract list" />
                </Typography>
            </Stack>
            <CustomToolbar
                tenant={tenant}
                writeAccess={writeAccess}
                density={density}
                onDensityChange={onDensityChange}
                apiRef={apiRef}
                openCreateContractModal={() => setCreateContractModalOpen(true)}
                searchTerm={searchTerm}
                onSearch={(value) => {
                    if (value) track("Contracts List: search");
                    setLoading(true);
                    navigate({ search: (prev) => ({ ...prev, searchTerm: value }), replace: true }).finally(() => {
                        setLoading(false);
                    });
                }}
                setOpenLayoutPopup={() => {
                    layouts.refetch();
                    setOpenLayoutPopup(true);
                }}
                canEditLayouts={canEditLayouts}
                hasRenewalDate={columns?.specificFields.renewalDate !== undefined}
                onPinnedFilter={onPinnedFilter}
            />

            {v2 === null && !loading && !isContractListEmpty && !contracts?.total && (
                <NoContracts messages={makeNoContractsMessages()} />
            )}
            {v2 === null && !loading && isContractListEmpty && (
                <NoContractsInOrg
                    helpCenterUrl={contractsHelpCenterUrl}
                    messages={noContractsMessages}
                    onCreateContract={() => setCreateContractModalOpen(true)}
                />
            )}
            {v2 != null && (
                <DataGridPro
                    columns={[
                        {
                            field: "title",
                            headerName: "Title",
                            width: 200,
                            renderCell: ({ row }) => (
                                <MuiLink
                                    component={Link}
                                    from={contractListRoute.fullPath}
                                    to={contractDetailRoute.fullPath}
                                    params={{ id: row.id }}
                                    noWrap
                                >
                                    {row.title ?? formatMessage({ defaultMessage: "Missing title" })}
                                </MuiLink>
                            ),
                        },
                        {
                            field: "supplier",
                            headerName: "Supplier",
                            width: 200,
                            renderCell: ({ row: { supplier } }) => (
                                <RenderSupplierChip id={supplier?.id} name={supplier?.name} />
                            ),
                        },
                        {
                            field: "totalSpend",
                            headerName: "Total Spend",
                            width: 165,
                            renderCell: ({ row }) => <RenderMonetaryAmountCell amount={row.totalSpend} />,
                        },
                        { field: "description", headerName: "Description", width: 200 },
                        {
                            field: "startDate",
                            headerName: "Start Date",
                            width: 120,
                            renderCell: ({ row }) => <RenderDateCell date={row.startDate} />,
                        },
                        {
                            field: "endDate",
                            headerName: "End Date",
                            width: 120,
                            renderCell: ({ row }) => <RenderDateCell date={row.endDate} />,
                        },
                        {
                            field: "renewalDate",
                            headerName: "Renewal Date",
                            width: 120,
                            renderCell: ({ row }) => <RenderDateCell date={row.endDate} />,
                        },
                        {
                            field: "contractResponsible",
                            headerName: "Contract Responsible",
                            width: 200,
                            renderCell: ({ row }) => (
                                <RenderUsersChip
                                    users={row.responsibles.map((r) => ({
                                        firstName: r?.firstName ?? "N/A",
                                        lastName: r?.lastName ?? "N/A",
                                    }))}
                                />
                            ),
                        },
                        { field: "isprivate", headerName: "Access", width: 120 },
                        ...columns.customFields.map((cf) => ({ field: cf.id, headerName: cf.name, width: 200 })),
                    ]}
                    rows={v2.data}
                    apiRef={apiRef}
                    slotProps={{
                        pagination: {
                            page: page - 1,
                            rowsPerPage: perPage,
                            rowsPerPageOptions: [25, 50, 100],
                            count: v2.data.length,
                            onPageChange: (_, page) => {
                                setLoading(true);
                                navigate({ search: (prev) => ({ ...prev, page: page + 1 }), replace: true }).finally(
                                    () => {
                                        setLoading(false);
                                    }
                                );
                            },
                            onRowsPerPageChange: (e) => {
                                const perPage = parseInt(e.target.value, 10);
                                setLoading(true);
                                navigate({ search: (prev) => ({ ...prev, perPage, page: 1 }), replace: true }).finally(
                                    () => {
                                        setLoading(false);
                                    }
                                );
                            },
                        },
                    }}
                    showColumnVerticalBorder
                    disableColumnFilter
                    disableRowSelectionOnClick
                    checkboxSelection={false}
                    density={density}
                    showCellVerticalBorder
                    pagination
                    initialState={{
                        pinnedColumns: { left: ["title"] },
                        pagination: {
                            paginationModel: { page, pageSize: perPage },
                        },
                    }}
                    paginationMode="server"
                    sortingMode="server"
                />
            )}
            {v2 === null && contracts.total > 0 && (
                <TablePro
                    sortDirection={sortOrder}
                    // NOTE: MUI gets very mad if you pass in an invalid column, and will issue onSortChange like crazy
                    //       This is somehow not reflected in the url, and we just get an infinite render loop
                    sortBy={
                        sortBy && fieldExists(sortBy, columns)
                            ? translateSpecificFieldToDmsId(sortBy, columns)
                            : undefined
                    }
                    headers={headers}
                    rows={contracts.data}
                    loading={loading}
                    checkboxSelection={false}
                    total={contracts.total}
                    density={density}
                    messages={makeMessages()}
                    orderedColumnsIds={readColumnsOrder(tenant) ?? headers.map((h) => h.id)}
                    onColumnsOrderChanged={(columnIds) => saveColumnsOrder(tenant, columnIds)}
                    onColumnWidthChanged={(id, width) =>
                        saveColumnWidths(tenant, overrideColumnWidth(columnWidths, { id, width }))
                    }
                    onColumnVisibilityModelChange={(model) => saveColumnVisibility(tenant, model)}
                    columnVisibilityModel={readColumnVisibility(tenant)}
                    onSortChange={handleSortChange}
                    currentPage={page - 1}
                    pageSize={perPage}
                    onPaginationChange={({ page: newPage, pageSize }) => {
                        setLoading(true);
                        navigate({ search: (prev) => ({ ...prev, page: newPage + 1, perPage: pageSize }) }).finally(
                            () => {
                                setLoading(false);
                            }
                        );
                    }}
                    apiRef={apiRef}
                    pinnedColumns={pinnedColumns}
                />
            )}
            <LayoutsPopup
                loading={layouts.isLoading}
                layouts={layouts.data?.layouts ?? []}
                defaultId={layouts.data?.defaultLayoutId}
                onCreate={async (name) => {
                    createLayout(name).then(() => layouts.refetch());
                }}
                onRename={async (id, name) => {
                    renameLayout(id, name).then(() => layouts.refetch());
                }}
                open={openLayoutPopup}
                onClose={() => setOpenLayoutPopup(false)}
                onSetDefault={async (id: string) => {
                    await setAsDefaultLayout(id);
                    layouts.refetch();
                    queryClient.removeQueries({ queryKey: defaultLayoutQueryKey(tenant) });
                }}
                onDelete={async (id) => {
                    removeLayout(id).then(() => layouts.refetch());
                }}
            />
            <NewContractModal
                prefilledSupplier={newContractModal?.supplier}
                open={createContractModalOpen}
                onClose={() => {
                    setCreateContractModalOpen(false);
                }}
                onSave={async (contract) => {
                    try {
                        const res = await http().post("contracts", contract);
                        const readContractIdFromHeaders = (headers: AxiosResponseHeaders) =>
                            headers.location.replace("/contracts/", "");
                        if (res.status === 201) {
                            const id = readContractIdFromHeaders(res.headers);
                            navigateToContractDetails(id);
                        }
                    } finally {
                        setCreateContractModalOpen(false);
                    }
                }}
            />
        </AppLayout>
    );
};

export default ContractList;
