import { useEffect, useState } from "react";
import { track } from "@ignite-analytics/track";
import { useFilterStateAction, useFilters as useAnalyticsFilters } from "@ignite-analytics/filters";

import { readPinnedFiltersFieldsIds, saveFilters, saveFiltersToSession } from "../proTableManagmentDao";
import { listRoute } from "@/routes/list";
import { ContractListSearchInput } from "@/routes/list/schema";

export function useFilters({ setLoading }: { setLoading: (loading: boolean) => void }) {
    const tenant = localStorage.getItem("tenant") ?? "";
    const filters = useAnalyticsFilters();
    const navigate = listRoute.useNavigate();
    const filterStateAction = useFilterStateAction();
    const { dmsFilter } = listRoute.useSearch();
    const [filtersInitialized, setFiltersInitialized] = useState(false);

    const handleSearch = (value: string) => {
        if (value) {
            track("Contracts List: search");
        }
        setLoading(true);
        navigate({
            search: (prev: ContractListSearchInput) => ({ ...prev, searchTerm: value }),
            replace: true,
        }).finally(() => {
            setLoading(false);
        });
    };

    useEffect(() => {
        if (filtersInitialized) {
            // Only initialize filters from url on first render to prevent loop
            return;
        }
        if (!filterStateAction) {
            return;
        }
        if (dmsFilter) {
            filterStateAction({ type: "ADD_FILTERS", filters: dmsFilter });
        }
        setFiltersInitialized(true);
    }, [filterStateAction, dmsFilter, filtersInitialized]);
    // update the URL whenever the filter changes
    // Except in the we just loaded this page, then the filter state is empty
    useEffect(() => {
        if (!filtersInitialized) {
            // useFilters() is not ready yet.
            return;
        }
        navigate({
            search: (prev: ContractListSearchInput) => ({
                ...prev,
                dmsFilter: filters && filters.length > 0 ? filters : undefined,
            }),
            replace: true,
        }).finally(() => {
            setLoading(false);
        });
    }, [filters, filtersInitialized, navigate, setLoading]);

    useEffect(() => {
        const fieldIds = readPinnedFiltersFieldsIds(tenant);
        filters && saveFiltersToSession(tenant, filters);
        const filtersToSave = filters?.filter((f) => fieldIds.includes(f.fieldId ?? "_")) ?? [];
        if (filtersToSave.length) {
            saveFilters(tenant, filtersToSave);
        }
    }, [filters, tenant]);

    return {
        filters,
        onSearch: handleSearch,
    };
}
