import { useEffect, useState } from "react";
import { useQuery } from "@apollo/client";
import { captureMessage } from "@/errorHandling/errors";
import { graphql } from "@/generated";
import { apolloClient } from "@/contexts";

const getGroupQuery = graphql(`
    query getGroup($input: GetGroupInput!) {
        getGroup(input: $input) {
            group {
                id
                name
            }
        }
    }
`);

export const getGroupsByGroupStructureQuery = graphql(`
    query getGroupsByGroupStructure($input: GetGroupsByGroupStructureInput!) {
        getGroupsByGroupStructure(input: $input) {
            groups {
                id
                name
                parentId
                groupStructureId
                level
                hasChildren
            }
        }
    }
`);

const getGroupPathQuery = graphql(`
    query getGroupPath($input: GetParentsByChildIdInput!) {
        getParentsByChildId(input: $input) {
            groups {
                id
                name
            }
        }
    }
`);

// finds all ancestor until a given group. Does not include itself, and the
// first element is the root
async function getParentChain(groupID: string): Promise<{ name: string; id: string }[]> {
    const { data, error } = await apolloClient.query({
        query: getGroupPathQuery,
        variables: { input: { childId: groupID } },
    });
    if (error) {
        captureMessage("Failed to get ancestors", { extra: { error } });
    }
    const got = data.getParentsByChildId.groups.map((g) => ({
        name: g.name,
        id: g.id,
    }));
    return got;
}

type Group = { name: string; id: string };
export type GroupAncestors = { elem: { name: string; id: string | null }; parents: Group[]; loading?: boolean };

export function getGroupAncestors(ids: string[]): Promise<GroupAncestors[]> {
    const promises = ids.map(async (id) => {
        const res = await apolloClient.query({ query: getGroupQuery, variables: { input: { id } } });
        const chain = await getParentChain(id);
        if (res.error) {
            captureMessage("Failed to get group", { extra: { error: res.error } });
        }
        return { elem: { name: res.data.getGroup.group.name, id }, parents: chain };
    });
    return Promise.all(promises);
}

// find the name of the element, along with path of its parent.
export function useGroupAncestors(id: string | null): GroupAncestors {
    const [name, setName] = useState<string>("");
    const [parents, setParents] = useState<{ name: string; id: string }[]>([]);
    const [loadingParents, setLoadingParents] = useState(true);

    const { loading: loadingElem } = useQuery(getGroupQuery, {
        skip: !id,
        variables: { input: { id: id! } },
        onCompleted: (data) => {
            setName(data.getGroup.group.name);
        },
    });

    useEffect(() => {
        if (!id) return;
        setLoadingParents(true);
        getParentChain(id)
            .then((res) => {
                setParents(res);
            })
            .finally(() => {
                setLoadingParents(false);
            });
    }, [id]);
    return { parents, elem: { name, id }, loading: loadingElem || loadingParents };
}
