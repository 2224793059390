import { Check, ChevronRight } from "@ignite-analytics/icons";
import { Stack, Autocomplete, TextField, ListItemText, MenuItem, ListItemIcon } from "@mui/material";
import { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { getGroupsByGroupStructureQuery, useGroupAncestors } from "@/hooks/useGroupsV2";
import { apolloClient } from "@/contexts";

type MenuCommonProps = {
    name: string;
    id: string;
    level: number;
    keystring: string;
};

type SimpleMenuItem = {
    type: "simple";
};

type NestedMenuItem = {
    type: "nested";
    onLoad?: () => Promise<void>;
    children: MenuItemProps[];
};
type MenuItem = SimpleMenuItem | NestedMenuItem;

type MenuItemProps = MenuCommonProps & (SimpleMenuItem | NestedMenuItem);

const ExpandIcon = ({ onClick, expanded }: { onClick?: (e: React.MouseEvent) => void; expanded: boolean }) => (
    <ListItemIcon onClick={(e) => onClick && onClick(e)}>
        <ChevronRight
            sx={{
                transform: expanded ? "rotate(90deg)" : "rotate(0deg)",
                transition: "transform .2s",
                visibility: "visible",
            }}
        />
    </ListItemIcon>
);

type Props = {
    groupStructureId: string;
    multiple?: boolean;
    value: string | null;
    onChange: (value: string[]) => void;
};

async function getChildMenuItems(
    groupId: string,
    id: string | undefined,
    level: number,
    keyString: string
): Promise<MenuItemProps[]> {
    const { data, error } = await apolloClient.query({
        query: getGroupsByGroupStructureQuery,
        variables: { input: { groupStructureId: groupId, parentId: id } },
    });
    if (error) {
        throw error;
    }
    const children: MenuItemProps[] = data.getGroupsByGroupStructure.groups.map((g) => {
        let item: MenuItem;
        if (g.hasChildren) {
            item = { type: "nested", children: [] };
        } else {
            item = { type: "simple" };
        }
        // keystring is used for sorting entries. All children of a parent will have the same prefix.
        // aaaa
        //   aaaa.bbbb
        //   aaaa.cccc
        //      aaaa.cccc.dddd
        const keystring = id === undefined ? g.id : `${keyString}-${g.id}`;
        return { ...item, name: g.name, id: g.id, level, keystring };
    });
    return children;
}
function useGroup(groupId: string) {
    const [items, setItems] = useState<MenuItemProps[]>([]);
    // on load, get the root
    useEffect(() => {
        getChildMenuItems(groupId, undefined, 0, "").then((res) => {
            setItems(res);
        });
    }, [groupId]);
    return {
        items,
        grow: (entries: MenuItemProps[]) => {
            // sort them before insert
            const tmp = [...items, ...entries].sort((a, b) => a.keystring.localeCompare(b.keystring));
            setItems(tmp);
        },
    };
}

export const NestedAutoComplete: React.FC<Props> = ({ groupStructureId, value, onChange }) => {
    const { items, grow } = useGroup(groupStructureId);
    const [loaded, setLoaded] = useState<string[]>([]);
    const [expanded, setExpanded] = useState<string[]>([]);
    const ancestors = useGroupAncestors(value);

    function toggleExpanded(id: string) {
        setExpanded((prev) => {
            if (prev.includes(id)) {
                return prev.filter((e) => e !== id);
            }
            return [...prev, id];
        });
    }

    return (
        <Autocomplete
            size="small"
            options={items}
            filterOptions={(all) => {
                return all.filter((opt) => {
                    if (opt.level === 0) {
                        return true;
                    }
                    const parents = opt.keystring.split("-");
                    parents.pop(); // remove the current elem
                    return parents.every((id) => expanded.includes(id));
                });
            }}
            fullWidth
            getOptionLabel={(option) => option.name}
            getOptionKey={(option) => option.id}
            renderOption={(_, option) => {
                return (
                    <MenuItem
                        component="li"
                        sx={{
                            textOverflow: "ellipsis",
                            pl: 1 + option.level * 3,
                        }}
                        value={option.name}
                        onClick={async () => {
                            if (option.type === "simple") {
                                onChange([option.id]);
                            }

                            // otherwise we'll expand; load if necessary
                            toggleExpanded(option.id);
                            if (loaded.includes(option.id)) {
                                return;
                            }
                            setLoaded((prev) => [...prev, option.id]);
                            const got = await getChildMenuItems(
                                groupStructureId,
                                option.id,
                                option.level + 1,
                                option.keystring
                            );
                            grow(got);
                        }}
                    >
                        <Stack direction="row" alignItems="center" gap={1}>
                            {option.type === "nested" && (
                                <ListItemIcon>
                                    <ExpandIcon expanded={expanded.includes(option.id)} />
                                </ListItemIcon>
                            )}
                            <ListItemText
                                onClick={(e) => {
                                    e.stopPropagation();
                                    onChange([option.id]);
                                }}
                            >
                                {option.name}
                            </ListItemText>
                            {value === option.id && (
                                <ListItemIcon>
                                    <Check />
                                </ListItemIcon>
                            )}
                        </Stack>
                    </MenuItem>
                );
            }}
            renderInput={(params) => <TextField {...params} placeholder={ancestors.elem.name} />}
        />
    );
};

type MultiProps = {
    groupStructureId: string;
    value: string[];
    onChange: (value: string[]) => void;
};

export const NestedAutoCompleteMulti: React.FC<MultiProps> = ({ groupStructureId, value, onChange }) => {
    const { items, grow } = useGroup(groupStructureId);
    const [loaded, setLoaded] = useState<string[]>([]);
    const [expanded, setExpanded] = useState<string[]>([]);
    const { formatMessage } = useIntl();

    const [values, setValues] = useState<string[]>(value);

    function toggleExpanded(id: string) {
        setExpanded((prev) => {
            if (prev.includes(id)) {
                return prev.filter((e) => e !== id);
            }
            return [...prev, id];
        });
    }
    function toggleValue(id: string) {
        let updated = [...values];
        if (updated.includes(id)) {
            updated = updated.filter((e) => e !== id);
        } else {
            updated.push(id);
        }
        setValues(updated);
        return updated;
    }

    return (
        <Autocomplete
            multiple
            size="small"
            options={items}
            filterOptions={(all) => {
                return all.filter((opt) => {
                    if (opt.level === 0) {
                        return true;
                    }
                    const parents = opt.keystring.split("-");
                    parents.pop(); // remove the current elem
                    return parents.every((id) => expanded.includes(id));
                });
            }}
            fullWidth
            getOptionLabel={(option) => option.name}
            getOptionKey={(option) => option.id}
            renderOption={(_, option) => {
                return (
                    <MenuItem
                        component="li"
                        sx={{
                            pl: 1 + option.level * 3,
                        }}
                        value={option.name}
                        onClick={async () => {
                            if (option.type === "simple") {
                                onChange(toggleValue(option.id));
                            }

                            // otherwise we'll expand; load if necessary
                            toggleExpanded(option.id);
                            if (loaded.includes(option.id)) {
                                console.log("already loaded");
                                return;
                            }
                            setLoaded((prev) => [...prev, option.id]);
                            const got = await getChildMenuItems(
                                groupStructureId,
                                option.id,
                                option.level + 1,
                                option.keystring
                            );
                            grow(got);
                        }}
                    >
                        {option.type === "nested" && (
                            <ListItemIcon>
                                <ExpandIcon expanded={expanded.includes(option.id)} />
                            </ListItemIcon>
                        )}
                        <ListItemText
                            onClick={(e) => {
                                e.stopPropagation();
                                onChange(toggleValue(option.id));
                            }}
                        >
                            {option.name}
                        </ListItemText>
                        {values.includes(option.id) && (
                            <ListItemIcon>
                                <Check />
                            </ListItemIcon>
                        )}
                    </MenuItem>
                );
            }}
            renderInput={(params) => (
                <TextField
                    {...params}
                    placeholder={formatMessage({ defaultMessage: "{count} selected" }, { count: values.length })}
                />
            )}
        />
    );
};
