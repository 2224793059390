import { createRouter, stringifySearchWith, createRootRouteWithContext } from "@tanstack/react-router";
import { Session } from "@/types";
import { Root } from "@/routes/__root";

import { listRoute } from "./list";

import { libraryRoute } from "./library";
import { layoutsRoute } from "./layouts/$id";
import { detailRoute } from "./detail/$id";
import { askDocumentExperimentRoute } from "./ask-document-experiment";
import { indexRoute } from "./index";
import { totalOverviewRoute } from "./total-overview";
// Root route
export const rootRoute = createRootRouteWithContext<{ session: Session }>()({
    component: Root,
});

// Combine all routes
export const routeTree = rootRoute.addChildren([
    indexRoute,
    listRoute,
    libraryRoute,
    layoutsRoute,
    detailRoute,
    askDocumentExperimentRoute,
    totalOverviewRoute,
]);

export const router = createRouter({
    context: { session: { id: "", email: "", tenant: "" } }, // placeholder - will be populated by RouterProvider
    defaultPreload: "intent",
    routeTree,
    defaultNotFoundComponent: () => "404 Not Found",
    stringifySearch: (search) => {
        // Shallow copy that we can mutate
        const stringifiedSearch = { ...search };

        if (stringifiedSearch.searchTerm === "") {
            // special-case: to avoid ?searchTerm= in the URL, we can just omit it in case it's empty
            // eslint-disable-next-line no-param-reassign
            delete stringifiedSearch.searchTerm;
        }
        if (stringifiedSearch.page === 1) {
            // special-case: to avoid ?page=1 in the URL, we can just omit it in case it's 0
            // eslint-disable-next-line no-param-reassign
            delete stringifiedSearch.page;
        }
        if (stringifiedSearch.perPage === 25) {
            // omit ?perPage=25 in the URL
            // eslint-disable-next-line no-param-reassign
            delete stringifiedSearch.perPage;
        }
        if (stringifiedSearch.sortOrder === false) {
            // omit ?sortOrder=false in the URL
            // eslint-disable-next-line no-param-reassign
            delete stringifiedSearch.sortOrder;
        }
        if (stringifiedSearch.group === "all") {
            delete stringifiedSearch.group;
        }
        return stringifySearchWith(JSON.stringify)(stringifiedSearch);
    },
});
