import { useState, useCallback } from "react";
import { ContractPermissions } from "@/hooks/useContractPermissions/types";

interface AccessChanges {
    reads: ContractPermissions[];
    writes: ContractPermissions[];
    removed: ContractPermissions[];
}

export const useAccessChanges = (contractIds: string[]) => {
    const [changes, setChanges] = useState<AccessChanges>({ reads: [], writes: [], removed: [] });
    const [usersPendingToAdd, setUsersPendingToAdd] = useState<ContractPermissions[]>([]);

    const undoChange = useCallback((permission: ContractPermissions) => {
        setChanges((prev) => ({
            reads: prev.reads.filter((p) => p.user.id !== permission.user?.id),
            writes: prev.writes.filter((p) => p.user.id !== permission.user?.id),
            removed: prev.removed.filter((p) => p.user.id !== permission.user?.id),
        }));
    }, []);

    const addReadPermission = useCallback(
        (permission: ContractPermissions) => {
            const hasReadAccessToAllContracts = contractIds.every((contractId) =>
                permission.reads.some((p) => p.contractId === contractId)
            );

            // If user already has read acces to all contract ids, undo changes
            if (hasReadAccessToAllContracts) {
                undoChange(permission);
                return;
            }

            // If user is already in the list, do not add again
            if (changes.reads.some((p) => p.user.id === permission.user.id)) {
                return;
            }

            setChanges((prev) => ({
                reads: [...prev.reads, permission],
                writes: prev.writes.filter((p) => p.user.id !== permission.user.id),
                removed: prev.removed.filter((u) => u.user.id !== permission.user.id),
            }));
        },
        [changes, undoChange, contractIds]
    );

    const addWritePermission = useCallback(
        (permission: ContractPermissions) => {
            // If a user already has write access, undo changees
            if (permission.writes.length > 0) {
                undoChange(permission);
                return;
            }

            // If user is already in the list, do not add again
            if (changes.writes.some((p) => p.user.id === permission.user.id)) {
                return;
            }

            setChanges((prev) => ({
                reads: prev.reads.filter((p) => p.user.id !== permission.user.id),
                writes: [...prev.writes, permission],
                removed: prev.removed.filter((u) => u.user.id !== permission.user.id),
            }));
        },
        [changes, undoChange]
    );

    const addUsersToPendingChanges = useCallback(() => {
        const addUsers = usersPendingToAdd.filter(
            (user) => !changes.reads.some((pendingUser) => pendingUser.user.id === user.user.id)
        );
        setChanges((prev) => ({
            reads: [...prev.reads, ...addUsers],
            writes: prev.writes.filter((u) => !addUsers.some((a) => a.user.id === u.user.id)),
            removed: prev.removed.filter((u) => !addUsers.some((a) => a.user.id === u.user.id)),
        }));
        setUsersPendingToAdd([]);
    }, [changes, usersPendingToAdd]);

    const removePermission = useCallback(
        (permission: ContractPermissions) => {
            // If user did not have any access before, undo change
            if (permission.reads.length === 0 && permission.writes.length === 0) {
                undoChange(permission);
                return;
            }

            setChanges((prev) => ({
                reads: prev.reads.filter((u) => u.user.id !== permission.user.id),
                writes: prev.writes.filter((u) => u.user.id !== permission.user.id),
                removed: [...prev.removed, permission],
            }));
        },
        [undoChange]
    );

    const handleToggleAddUser = useCallback(
        (permission: ContractPermissions) => {
            if (usersPendingToAdd.some((u) => u.user.id === permission.user.id)) {
                setUsersPendingToAdd(() => [...usersPendingToAdd.filter((u) => u.user.id !== permission.user.id)]);
            } else {
                setUsersPendingToAdd(() => [...usersPendingToAdd, permission]);
            }
        },
        [usersPendingToAdd]
    );

    const hasChanges = changes.reads.length > 0 || changes.removed.length > 0;

    const hasSelectedUsersToAdd = usersPendingToAdd.length > 0;

    return {
        changes,
        addReadPermission,
        addWritePermission,
        removePermission,
        undoChange,
        hasChanges,
        addUsersToPendingChanges,
        handleToggleAddUser,
        hasSelectedUsersToAdd,
        usersPendingToAdd,
    };
};
