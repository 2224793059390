import React from "react";
import { Stack, Typography, Link as MuiLink } from "@mui/material";
import { Link } from "@tanstack/react-router";

import { detailRoute } from "@/routes/detail/$id";
import { listRoute } from "@/routes/list";

type Props = {
    contractId: string;
    title: string;
    from?: string;
};

export const TitleCustomField: React.FC<Props> = ({ contractId, title, from = listRoute.fullPath }) => {
    return (
        <Stack justifyContent="center" alignItems="start" height="100%">
            <MuiLink
                component={Link}
                from={from}
                to={detailRoute.to}
                params={{ id: contractId }}
                noWrap
                sx={{
                    width: "100%",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    "&:hover": {
                        textDecorationColor: (theme) => theme.palette.text.primary,
                    },
                }}
            >
                <Typography color="textPrimary" fontWeight="600" variant="textSm">
                    {title}
                </Typography>
            </MuiLink>
        </Stack>
    );
};
