import { User } from "@/entities/users";

export type EntitledUser = {
    subjectId: string;
    permission: string;
};

export type ContractPermissions = {
    objectId: string;
    entitleds: EntitledUser[];
};

export enum AccessLevels {
    RESTRICTED = "restricted",
    PUBLIC = "public",
}

export type AccessLevel = AccessLevels.RESTRICTED | AccessLevels.PUBLIC | undefined;

export const ROLES = {
    GLOBAL_ADMIN: "global.admin",
    GLOBAL_EDITOR: "global.editor",
    GLOBAL_CONTRACT_EDITOR: "global.contract-editor",
    GLOBAL_VIEWER: "global.viewer",
    GLOBAL_CONTRACT_VIEWER: "global.contract-viewer",
} as const;

export type UserRole = (typeof ROLES)[keyof typeof ROLES];

export type UserWithPermissions = User & {
    role: UserRole;
    contractResponsible: string[];
    objectRead: string[];
};
