import { createRoute } from "@tanstack/react-router";

import { rootRoute } from "@/routes/routes";
import { TotalOverviewPage } from "@/pages/totalOverview";

export const totalOverviewRoute = createRoute({
    getParentRoute: () => rootRoute,
    path: "/total-overview",
    component: TotalOverviewPage,
});
