import { createRoute } from "@tanstack/react-router";

import { rootRoute } from "@/routes/routes";
import { LayoutsPage } from "@/pages/layouts";

export const layoutsRoute = createRoute({
    getParentRoute: () => rootRoute,
    path: "/layouts/$id",
    component: LayoutsPage,
});
