import { Divider, Stack, Typography } from "@mui/material";
import { FormattedMessage } from "react-intl";
import { InformationTooltip } from "../InformationTooltip";

export const EditAccessHeader: React.FC = () => {
    return (
        <Stack paddingY={1} paddingX={2} sx={{ position: "sticky", top: 0, bgcolor: "background.paper", zIndex: 1 }}>
            <Stack direction="row" alignItems="center" justifyContent="space-between" width="100%" padding={0.5}>
                <Typography variant="textXs">
                    <FormattedMessage defaultMessage="Has access to the contract" />
                </Typography>
                <Typography variant="textXs" color="textTextPlaceholder">
                    <InformationTooltip
                        content={
                            <Stack spacing={2}>
                                <Stack>
                                    <Typography variant="textXs" fontWeight={600}>
                                        <FormattedMessage defaultMessage="Viewer role" />
                                    </Typography>
                                    <Typography variant="textXs">
                                        <FormattedMessage defaultMessage="Users with viewer role can view public contracts, and can be granted read access to restricted contracts." />
                                    </Typography>
                                </Stack>
                                <Stack>
                                    <Typography variant="textXs" fontWeight={600}>
                                        <FormattedMessage defaultMessage="Editor role" />
                                    </Typography>
                                    <Typography variant="textXs">
                                        <FormattedMessage defaultMessage="Users with editor role can view all public contracts, and can be granted read acceess to restricted contracts and can get edit access to contracts they are responsible for." />
                                    </Typography>
                                </Stack>
                                <Stack>
                                    <Typography variant="textXs" fontWeight={600}>
                                        <FormattedMessage defaultMessage="Admin role" />
                                    </Typography>
                                    <Typography variant="textXs">
                                        <FormattedMessage defaultMessage="Users with admin role can view, edit and delete any contract." />
                                    </Typography>
                                </Stack>

                                <Stack>
                                    <Typography variant="textXs" fontWeight={600}>
                                        <FormattedMessage defaultMessage="Contract responsible" />
                                    </Typography>
                                    <Typography variant="textXs">
                                        <FormattedMessage defaultMessage="Editors that are contract responsibles can edit or delete the contracts they are responsible for. They are also the ones who will receive alerts set on the contract." />
                                    </Typography>
                                </Stack>
                            </Stack>
                        }
                    />
                </Typography>
            </Stack>
            <Divider />
        </Stack>
    );
};
