import { z } from "zod";

const envSchema = z.object({
    VITE_BASE_URL: z.string().url(), // Not used in the app, but required for the build
    REACT_APP_CONTRACTS_BASE_URL: z.string().url(),
    REACT_APP_DEEP_STREAM_URL: z.string().url(),
    REACT_APP_PILOT_DEEP_STREAM_URL: z.string().url(),
    REACT_APP_AUTH_SIDECAR_API_URL: z.string().url(),
    REACT_APP_GRAPHQL_ROUTER_URL: z.string().url(),
    REACT_APP_ORY_API_URL: z.string().url(),
    REACT_APP_FILES_SERVICE_BASE_URL: z.string().url(),
    REACT_APP_SUBSCRIPTIONS_URL: z.string().url(),
    REACT_APP_PUBLIC_URL: z.string().url(),
    REACT_APP_CUSTOMERS_URL: z.string().url(),
    REACT_APP_MODULE_INTEREST_SLACK_WEBHOOK: z.string().url(),
    REACT_APP_MUI_TOKEN: z.string(),
    REACT_APP_ENV: z.union([z.literal("test"), z.literal("local"), z.literal("proxy"), z.literal("production")]),
    REACT_APP_PROXY_WEB_TO_PRODUCTION: z.union([z.literal("true"), z.literal("false")]).default("false"),
    REACT_APP_DEFAULT_USER: z.string().optional(),
    REACT_APP_DEFAULT_PASSWORD: z.string().optional(),
    REACT_APP_DEFAULT_TENANT: z.string().optional(),
});

const parse = (source: unknown) => {
    const env = envSchema.safeParse(source);
    if (!env.success) {
        // For better error messages when developing
        throw new Error(`Failed to parse environment ${env.error.toString()}`);
    }
    return env.data;
};

export const env = parse(process.env);
