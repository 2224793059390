import { styled } from "@mui/material";
import { Outlet, ScrollRestoration } from "@tanstack/react-router";

/**
 * @deprecated delete when all pages are migrated to the new layout
 *
 * Adds a scrolling container by adding max-height and overflow-y: scroll to the container. This is the old setup
 * which adds a ton of scroll bars, but we need this to avoid migrating all pages at once.
 */
const DeprecatedRoot = styled("div")({
    // If the data-new-layout attribute is set to true, we don't want to apply the styles
    "*:where(html[data-new-layout]) &": {
        flex: "inherit",
        display: "inherit",
        flexDirection: "inherit",
    },
    // If the data-new-layout attribute is not set to true, we want to apply the styles
    "*:where(html:not([data-new-layout])) &": {
        display: "flex",
        flexDirection: "column",
        position: "relative",
        height: "100vh",
        maxHeight: "calc(100vh - 64px)",
        overflow: "auto",
    },
});

export const Root = () => {
    return (
        <DeprecatedRoot>
            <ScrollRestoration />
            <Outlet />
        </DeprecatedRoot>
    );
};
