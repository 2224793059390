import { getLocale } from "@ignite-analytics/locale";
import React, { useEffect, useMemo } from "react";
import { createIntl, FormattedMessage, IntlProvider, MessageDescriptor, MessageFormatElement } from "react-intl";
import { getMessages } from "src/languages";
import { getMessages as getFilterMessages } from "@ignite-analytics/filters";
import { parse } from "@formatjs/icu-messageformat-parser";
import dayjs from "dayjs";
import "dayjs/locale/nn";
import "dayjs/locale/de";

let intl = createIntl({
    locale: getLocale(),
    messages: getMessages(getLocale()),
    onError: () => {
        /* Do nothing */
    },
});

interface Props {
    locale: string;
    children: React.ReactNode;
}

type PrimitiveType = string | number | boolean | null | undefined | Date;

const parseMessages = (messages: Record<string, string>) => {
    return Object.entries(messages).reduce(
        (acc, [key, messageString]) => {
            acc[key] = parse(messageString);
            return acc;
        },
        {} as Record<string, MessageFormatElement[]>
    );
};

export const IntlContext: React.FC<Props> = ({ locale, children }) => {
    const messages = useMemo(() => {
        // Need to make sure all these messages are parsed, as react-intl doesn't like mixed compiled and raw string messages
        return {
            ...parseMessages(getFilterMessages(locale)),
            ...getMessages(locale),
        };
    }, [locale]);
    useEffect(() => {
        intl = createIntl({ locale, messages });
        switch (locale) {
            case "nb-NO":
                dayjs.locale("nb");
                break;
            case "de-DE":
                dayjs.locale("de");
                break;
            default:
                dayjs.locale("en");
                break;
        }
    }, [locale, messages]);

    return (
        <IntlProvider locale={locale} messages={messages} defaultLocale="en-US">
            {children}
        </IntlProvider>
    );
};

export const fm = (descriptor: MessageDescriptor, values?: Record<string, PrimitiveType>) => {
    const element = <FormattedMessage values={values} {...descriptor} />;
    return { ...element, toString: () => intl.formatMessage(descriptor, values) };
};

export const currentIntl = () => intl;
