import { styled } from "@mui/material";

export const UploadInput = styled("input")(({ disabled }) => ({
    position: "absolute",
    left: 0,
    top: 0,
    opacity: 0,
    width: "100%",
    height: "100%",
    cursor: disabled ? "not-allowed" : "pointer",
}));

export const UploadWrapper = styled("div")(() => ({
    position: "relative",
    overflow: "hidden",
    display: "inline-flex",
    width: "100%",
}));
