import { useQuery } from "@apollo/client";
import { GridSortModel } from "@mui/x-data-grid-pro";
import { useMemo, useState } from "react";
import { getFragmentData } from "@/generated/fragment-masking";
import { graphql } from "@/generated/gql";
import { ContractSort, ContractSortKey } from "@/generated/graphql";
import { ContractStatus, Responsible, responsibleSchema, Row, Supplier, supplierSchema } from "./types";
import { useSessionContext } from "@/contexts/SessionContext";

const TotalOverview_ContractFragment = graphql(/* GraphQL */ `
    fragment TotalOverview_Contract on Contract {
        id
        title
        startDate
        endDate
        supplier {
            id
            name
        }
        responsibles {
            id
            firstName
            lastName
        }
    }
`);

const TotalOverview_GetContracts = graphql(/* GraphQL */ `
    query TotalOverview_GetContracts($input: GetContractsInput) {
        getContracts(input: $input) {
            data {
                ...TotalOverview_Contract
                ...TotalOverviewCards_Contract
            }
            total
        }
    }
`);

const threeMonthsFromNow = new Date();
threeMonthsFromNow.setMonth(threeMonthsFromNow.getMonth() + 3);

const today = new Date();

const getStatusFilter = (statusFilter: ContractStatus | undefined) => {
    if (statusFilter === "active") {
        return {
            endDate: { gt: threeMonthsFromNow.toISOString().split("T")[0] },
        };
    }
    if (statusFilter === "expiring") {
        return {
            endDate: {
                gt: today.toISOString().split("T")[0],
                lt: threeMonthsFromNow.toISOString().split("T")[0],
            },
        };
    }
    if (statusFilter === "expired") {
        return {
            endDate: { lt: today.toISOString().split("T")[0] },
        };
    }
    return {};
};

const getSortModel = (sortModel: GridSortModel): ContractSort[] => {
    if (sortModel.length === 0) return [];
    let sortKey: ContractSortKey | undefined;

    switch (sortModel[0].field) {
        case "title":
            sortKey = "TITLE";
            break;
        case "startDate":
            sortKey = "START_DATE";
            break;
        case "endDate":
            sortKey = "END_DATE";
            break;
        default:
            sortKey = undefined;
    }

    return sortKey ? [{ key: sortKey, direction: sortModel[0].sort === "asc" ? "ASC" : "DESC" }] : [];
};

export function useTotalOverviewRows(searchTerm: string) {
    const [showMyContracts, setShowMyContracts] = useState(false);
    const [sortModel, setSortModel] = useState<GridSortModel>([]);
    const [statusFilter, setStatusFilter] = useState<ContractStatus | undefined>(undefined);

    const { id: currentUserId } = useSessionContext();

    const { data, loading } = useQuery(TotalOverview_GetContracts, {
        variables: {
            input: {
                where: {
                    ...getStatusFilter(statusFilter),
                },
                search: searchTerm,
                responsibles: showMyContracts ? [currentUserId] : undefined,
                sort: getSortModel(sortModel),
            },
        },
        errorPolicy: "all",
    });

    const { data: unfilteredContracts } = useQuery(TotalOverview_GetContracts, {
        errorPolicy: "all",
    });

    const contracts = getFragmentData(TotalOverview_ContractFragment, data?.getContracts.data ?? []);

    const handleToggleShowMyContracts = () => {
        setShowMyContracts(!showMyContracts);
    };

    const handleResetQueryFilters = () => {
        setShowMyContracts(false);
        setSortModel([]);
        setStatusFilter(undefined);
    };

    const rows: Row[] = useMemo(() => {
        return contracts.map((contract) => {
            let validatedSupplier: Supplier | undefined;
            try {
                validatedSupplier = supplierSchema.parse(contract.supplier);
            } catch {
                validatedSupplier = undefined;
            }

            const validatedResponsibles =
                contract.responsibles
                    ?.map((resp) => {
                        try {
                            return responsibleSchema.parse(resp);
                        } catch {
                            return null;
                        }
                    })
                    .filter((resp): resp is Responsible => resp !== null) ?? [];

            return {
                id: contract.id,
                title: contract.title,
                startDate: contract.startDate,
                endDate: contract.endDate,
                supplier: validatedSupplier,
                responsible: validatedResponsibles,
            };
        });
    }, [contracts]);

    return {
        rows,
        onSortContracts: setSortModel,
        unfilteredContracts: unfilteredContracts?.getContracts.data ?? [],
        showMyContracts,
        onToggleShowMyContracts: handleToggleShowMyContracts,
        loading,
        onResetQueryFilters: handleResetQueryFilters,
        onSetStatusFilter: setStatusFilter,
        statusFilter,
        activeFilters: showMyContracts || !!statusFilter,
    };
}
