import { Checkbox, Divider, MenuItem, Stack, Typography } from "@mui/material";
import { FormattedMessage } from "react-intl";

import { ShapeIcon } from "@ignite-analytics/components";
import { UserCheck } from "@ignite-analytics/icons";
import { UserItem } from "../UserAvatars";
import { ContractPermissions } from "@/hooks/useContractPermissions/types";

export interface AddUsersSectionProps {
    noPermissions: ContractPermissions[];
    selectedUsers: ContractPermissions[];
    onToggleUser: (user: ContractPermissions) => void;
}

export const AddUsersSection: React.FC<AddUsersSectionProps> = ({ noPermissions, selectedUsers, onToggleUser }) => {
    const isSelected = (userId: string) => selectedUsers.some((u) => u.user.id === userId);

    if (noPermissions.length === 0) {
        return (
            <Stack height={300} width="100%" alignItems="center" justifyContent="center" spacing={2}>
                <ShapeIcon size="large">
                    <UserCheck />
                </ShapeIcon>
                <Typography variant="textSm" color="textTextBody">
                    <FormattedMessage defaultMessage="All users have already been added — no more left to add!" />
                </Typography>
            </Stack>
        );
    }

    return (
        <Stack>
            <Stack
                paddingY={1}
                paddingX={2}
                sx={{ position: "sticky", top: 0, bgcolor: "background.paper", zIndex: 1 }}
            >
                <Typography variant="textXs">
                    <FormattedMessage defaultMessage="Not added" />
                </Typography>
                <Divider />
            </Stack>
            {noPermissions.map((user) => (
                <MenuItem key={user.user.id} onClick={() => onToggleUser(user)}>
                    <Stack direction="row" alignItems="center" justifyContent="space-between" width="100%">
                        <UserItem user={user.user} />
                        <Checkbox checked={isSelected(user.user.id)} />
                    </Stack>
                </MenuItem>
            ))}
        </Stack>
    );
};
