import { Avatar, AvatarGroup, Stack, Tooltip, Typography } from "@mui/material";
import { FormattedMessage } from "react-intl";

import { useMemo } from "react";
import { UserItem } from "./UserItem";
import { UserSurplus } from "./UserSurplus";
import { UserRoleChip } from "@/pages/detail/components/ContractSidebar/AccessCard/UserRoleChip";
import { ContractPermissions } from "@/hooks/useContractPermissions/types";

export interface UserAvatarWithRoleProps {
    permissions: ContractPermissions[];
    maxAvatars?: number;
    avatarSize?: number;
    showAllInSurplus?: boolean;
}

export const UserAvatarWithRole: React.FC<UserAvatarWithRoleProps> = ({
    permissions,
    maxAvatars = 3,
    avatarSize = 24,
    showAllInSurplus = false,
}) => {
    const sortedPermissions = useMemo(() => {
        return permissions.sort((a, b) => {
            return a.user.firstName.localeCompare(b.user.firstName);
        });
    }, [permissions]);

    if (permissions.length === 0) {
        return (
            <Typography variant="textSm" color="textTextHelper">
                <FormattedMessage defaultMessage="Not assigned" />
            </Typography>
        );
    }

    if (permissions.length === 1) {
        return (
            <Stack justifyContent="center" height="100%">
                <UserItem user={sortedPermissions[0].user} />
            </Stack>
        );
    }
    const users = sortedPermissions.map((p) => p.user);
    const usersInSurplus = showAllInSurplus ? users : users.slice(maxAvatars - 1);

    return (
        <Stack justifyContent="center" alignItems="flex-start" height="100%">
            <AvatarGroup
                max={maxAvatars}
                renderSurplus={() => <UserSurplus textVariant="textXs" users={usersInSurplus} />}
                slotProps={{ surplus: { sx: { width: avatarSize, height: avatarSize } } }}
            >
                {permissions.map((permission) => (
                    <Tooltip
                        key={permission.user.id}
                        title={
                            <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={2}>
                                <UserItem user={permission.user} />
                                <UserRoleChip role={permission.roleID} />
                            </Stack>
                        }
                    >
                        <Avatar src="" sx={{ width: avatarSize, height: avatarSize }}>
                            <Typography variant="textXs">{`${permission.user.firstName?.[0] ?? ""}${permission.user.lastName?.[0] ?? ""}`}</Typography>
                        </Avatar>
                    </Tooltip>
                ))}
            </AvatarGroup>
        </Stack>
    );
};
