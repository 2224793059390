import { env } from "./env";

const BASE_URL = env.REACT_APP_PUBLIC_URL;

interface WhoAmIResponse {
    error?: {
        id: string;
    };
    identity?: {
        id: string;
        traits: {
            email: string;
        };
        // eslint-disable-next-line camelcase
        metadata_public?: {
            tenant: string;
        };
    };
}

async function getSessionOrThrow() {
    const sessionURL = `/ory/sessions/whoami`;
    const sessionResponse = await fetch(sessionURL, { headers: { accept: "application/json" }, method: "GET" });
    const sessionData: WhoAmIResponse = await sessionResponse.json();
    const id: string | undefined = sessionData.identity?.id;
    const tenant: string | undefined = sessionData.identity?.metadata_public?.tenant;
    const email: string | undefined = sessionData.identity?.traits?.email;
    if (!tenant || !id || !email) {
        throw new Error("Could not get session");
    }
    localStorage.setItem("tenant", tenant);
    return {
        id,
        email,
        tenant,
    };
}

async function setupPeriodicJWTFetch(intervalSeconds: number, tenant: string) {
    const jwtGenerateURL = `${BASE_URL}/tokxchange/api/v2/generate`;
    async function fetchToken() {
        const res = await fetch(jwtGenerateURL, {
            method: "POST",
            headers: {
                "x-tenant-id": tenant,
            },
        });
        if (res.ok) {
            const token = await res.json();
            localStorage.setItem("token", token);
        }
        return res;
    }

    const timeout = setInterval(fetchToken, intervalSeconds * 1000);
    await fetchToken();
    return () => clearInterval(timeout);
}

export async function setupProxyAuth() {
    const setupURL = "/ory/self-service/login/browser";
    const setupResponse = await fetch(setupURL, { headers: { accept: "application/json" }, method: "GET" });
    const setupData = await setupResponse.json();

    if (setupData.error?.id !== "session_already_available") {
        const username = env.REACT_APP_DEFAULT_USER ?? prompt("Please enter your username");
        const password = env.REACT_APP_DEFAULT_PASSWORD ?? prompt("Please enter your password");

        const csrfNode = setupData.ui.nodes.find((node: any) => node.attributes.name === "csrf_token");
        const csrfToken = csrfNode.attributes.value;
        const { action } = setupData.ui;
        const flowId = action.split("login?flow=").pop();

        const loginURL = `${BASE_URL}/ory/self-service/login?flow=${flowId}`;

        await fetch(loginURL, {
            method: "POST",
            credentials: "include",
            headers: { accept: "application/json", "Content-Type": "application/json" },
            body: JSON.stringify({
                csrf_token: csrfToken,
                method: "password",
                identifier: username,
                password,
                password_identifier: username,
            }),
        });
    }
    const session = await getSessionOrThrow();
    await setupPeriodicJWTFetch(60, session.tenant);

    localStorage.setItem("tenant", session.tenant);

    return session;
}
