import { DotsVertical, ChevronUp, ChevronDown } from "@ignite-analytics/icons";
import { Collapse, IconButton, Menu, MenuItem, Stack, Typography } from "@mui/material";
import { GridApiPro, GridDensity, GridPreferencePanelsValue } from "@mui/x-data-grid-pro";
import { MutableRefObject, useState } from "react";

import { fm } from "src/contexts/IntlContext";
import messages from "./messages";

interface TableActionBarProps {
    apiRef: MutableRefObject<GridApiPro> | undefined;
    density: GridDensity;
    onDensityChange: (density: GridDensity) => void;
    setOpenLayoutPopup: (open: boolean) => void;
    canEditLayouts: boolean;
}

export const TableActionBar: React.FC<TableActionBarProps> = ({
    apiRef,
    density,
    onDensityChange,
    setOpenLayoutPopup,
    canEditLayouts,
}) => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const [openTableDensity, setOpenTableDensity] = useState(false);
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    return (
        <Stack direction="row" justifyContent="center" alignItems="center">
            <IconButton size="small" onClick={handleClick} color="ghostGray">
                <DotsVertical />
            </IconButton>
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    "aria-labelledby": "basic-button",
                }}
                slotProps={{
                    paper: {
                        sx: {
                            width: 240,
                        },
                    },
                }}
            >
                <MenuItem
                    onClick={() => {
                        apiRef?.current?.showPreferences(GridPreferencePanelsValue.columns);
                        handleClose();
                    }}
                >
                    <Typography>{fm(messages.hideShowColumnsButton)}</Typography>
                </MenuItem>
                <MenuItem
                    disabled={!canEditLayouts}
                    onClick={() => {
                        setOpenLayoutPopup(true);
                        handleClose();
                    }}
                >
                    <Typography>{fm(messages.layoutsButton)}</Typography>
                </MenuItem>
                <MenuItem onClick={() => setOpenTableDensity(!openTableDensity)}>
                    <Stack direction="row" justifyContent="space-between" width="100%">
                        <Typography>{fm(messages.setDensity)}</Typography>
                        {openTableDensity ? <ChevronUp /> : <ChevronDown />}
                    </Stack>
                </MenuItem>
                <Collapse in={openTableDensity}>
                    <MenuItem selected={density === "compact"} onClick={() => onDensityChange("compact")}>
                        <Typography variant="body2">{fm(messages.compactDensity)}</Typography>
                    </MenuItem>
                    <MenuItem selected={density === "standard"} onClick={() => onDensityChange("standard")}>
                        <Typography variant="body2">{fm(messages.standardDensity)}</Typography>
                    </MenuItem>
                    <MenuItem selected={density === "comfortable"} onClick={() => onDensityChange("comfortable")}>
                        <Typography variant="body2">{fm(messages.comfortableDensity)}</Typography>
                    </MenuItem>
                </Collapse>
            </Menu>
        </Stack>
    );
};
