import { Stack, Tooltip, Typography } from "@mui/material";
import { FormattedDate, FormattedMessage } from "react-intl";
import { getStatusChipContent } from "@/components/ContractsTablePro/renderCustomField/ContractStatusCell/getStatusChipContent";

interface StatusCellProps {
    startDate: string | null;
    endDate: string | null;
}

export const StatusCell: React.FC<StatusCellProps> = ({ startDate, endDate }) => {
    const { chip, tooltipTitle } = getStatusChipContent(
        endDate ? new Date(endDate) : null,
        startDate ? new Date(startDate) : null
    );

    return (
        <Tooltip
            title={
                <Stack>
                    <Typography variant="textSm">
                        {endDate && (
                            <Stack>
                                <FormattedMessage
                                    defaultMessage="End date: {endDate}"
                                    values={{
                                        endDate: (
                                            <FormattedDate
                                                value={endDate ?? undefined}
                                                day="2-digit"
                                                month="short"
                                                year="numeric"
                                            />
                                        ),
                                    }}
                                />
                            </Stack>
                        )}
                        <Typography variant="textSm">{tooltipTitle}</Typography>
                    </Typography>
                </Stack>
            }
        >
            {/* Need a div here to make the tooltip work */}
            <div>{chip}</div>
        </Tooltip>
    );
};
