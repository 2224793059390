import React from "react";
import { Stack, Typography } from "@mui/material";

type Props = {
    entries: string[];
};

const Indent: React.FC<{ level: number }> = ({ level }) => {
    if (level === 0) {
        return null;
    }
    return (
        <Stack direction="row" alignItems="center">
            <Stack width={level * 16} />
            <Stack
                alignSelf="start"
                sx={{
                    height: "8px",
                    borderLeft: "1px dashed",
                    // only top half is shown border -- use a gradient
                    borderColor: "rgba(0, 0, 0, 0.3)",
                }}
            />
            <Stack
                sx={{
                    height: "1px",
                    width: "24px",
                    borderBottom: "1px dashed",
                    borderColor: "rgba(0, 0, 0, 0.3)",
                }}
            />
        </Stack>
    );
};

export const ViewGroupStructure: React.FC<Props> = ({ entries }) => {
    return (
        <Stack>
            {entries.map((entry, level) => {
                return (
                    <Stack key={entry} direction="row" gap={1}>
                        <Indent level={level} />
                        <Typography noWrap variant="body2">
                            {entry}
                        </Typography>
                    </Stack>
                );
            })}
        </Stack>
    );
};
