import { Check } from "@ignite-analytics/icons";
import { ShapeIcon } from "@ignite-analytics/components";
import { DialogContent, Stack, Typography, TextField, Button, Link, Alert } from "@mui/material";
import { useState } from "react";
import { FormattedMessage } from "react-intl";
import { useNavigate } from "@tanstack/react-router";
import { env } from "@/env";

const LearnMoreContent = () => {
    const navigate = useNavigate();
    const [contactMessage, setContactMessage] = useState("");
    const [error, setError] = useState(false);
    const [messageSent, setMessageSent] = useState(false);

    const handleContactMe = () => {
        fetch(env.REACT_APP_MODULE_INTEREST_SLACK_WEBHOOK, {
            method: "POST",
            credentials: "include",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                module: "contracts",
                message: contactMessage,
            }),
        })
            .then((response) => {
                if (!response.ok) {
                    setError(true);
                    return;
                }
                setMessageSent(true);
            })
            .catch(() => {
                setError(true);
            });
    };

    return (
        <DialogContent sx={{ overflow: "visible" }}>
            <Stack justifyContent="center" alignItems="center" gap={2}>
                {!messageSent ? (
                    <>
                        <Stack alignItems="center" gap={1}>
                            <Typography variant="displayXs" fontWeight={500}>
                                <FormattedMessage defaultMessage="Learn more" />
                            </Typography>
                            <Typography textAlign="center" variant="textSm" color="text.text-helper">
                                <FormattedMessage defaultMessage="Schedule a meeting with your Customer Success Manager and see what Contract management can do for you" />
                            </Typography>
                        </Stack>
                        <TextField
                            multiline
                            rows={5}
                            placeholder="Want to add anything?"
                            onChange={(e) => setContactMessage(e.target.value)}
                            sx={{ width: "100%" }}
                        />
                        <Button fullWidth onClick={handleContactMe}>
                            <FormattedMessage defaultMessage="Contact me" />
                        </Button>
                        {error && (
                            <Alert severity="error">
                                <FormattedMessage defaultMessage="We were not able to send the message, please try again or contact us by clicking" />
                                <Link href="mailto:cs@ignite.no" target="_blank" rel="noopener noreferrer">
                                    <FormattedMessage defaultMessage="contact us" />
                                </Link>
                            </Alert>
                        )}
                    </>
                ) : (
                    <Stack alignItems="center" gap={2} width="100%">
                        <ShapeIcon color="success">
                            <Check fontSize="inherit" />
                        </ShapeIcon>
                        <Stack gap={0.5} alignItems="center">
                            <Typography variant="textLg" fontWeight={500}>
                                <FormattedMessage defaultMessage="Thank you!" />
                            </Typography>
                            <Typography variant="textSm" color="text.text-helper">
                                <FormattedMessage defaultMessage="We'll be in touch shortly." />
                            </Typography>
                        </Stack>
                        <Button
                            fullWidth
                            onClick={() => {
                                navigate({ search: { show: undefined } });
                                setMessageSent(false);
                            }}
                        >
                            <FormattedMessage defaultMessage="Close" />
                        </Button>
                    </Stack>
                )}
            </Stack>
        </DialogContent>
    );
};

export default LearnMoreContent;
