import { createRoute, Navigate } from "@tanstack/react-router";

import { listRoute } from "../list";
import { rootRoute } from "../routes";

// Library route
export const libraryRoute = createRoute({
    getParentRoute: () => rootRoute,
    path: "/library",
    component: () => <Navigate to={listRoute.to} search={{ status: "active" }} />,
});
