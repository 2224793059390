import { track } from "@ignite-analytics/track";

import { Box, Divider, Grid2 as Grid, Paper, Stack } from "@mui/material";

import { useEffect, useMemo, useState } from "react";
import { usePermissionHandler } from "src/contexts/PermissionsContext";

import { useBreadcrumbs } from "src/hooks/useBreadcrumbs";

import { useSessionContext } from "@/contexts/SessionContext";

import { CustomField } from "@/models/customFields";

import { Contract } from "@/types";
import { ContractBasicDetails } from "./components/ContractBasicDetails";
import { ContractDetailsHeader } from "./components/ContractDetailsHeader";
import { ContractOtherDetails } from "./components/ContractOtherDetails";
import { useDisplayHeaderShadow } from "./hooks/useHeaderShadow";

import { ContractSidebar } from "./components/ContractSidebar";
import { detailRoute } from "@/routes/detail/$id/index";

export const DetailPage = () => {
    const [editMode, setEditMode] = useState(false);

    const { id } = detailRoute.useParams();
    const data = detailRoute.useLoaderData();

    // Represents the contract. Whenever we do a local update to the contract, we'll do
    // setContract({...contract, field: newval}).
    // Therefore, this object contains potentially local changes. The "server" version will
    // awlays be the data.contract object.
    const [contract, setContract] = useState<Contract>(data.contract);

    useEffect(() => {
        setContract(data.contract);
    }, [data.contract]);

    const { updateBreadCrumbs } = useBreadcrumbs();

    const { checkPermissions } = usePermissionHandler();
    const hasGeneralWritePermission = checkPermissions("general", "write");
    const hasAdministratorPermission = checkPermissions("general", "create");
    const { id: userId } = useSessionContext();
    const { displayHeaderShadow, contentRef } = useDisplayHeaderShadow();

    const canEdit = useMemo(() => {
        if (contract.contractResponsibleIds?.includes(userId) && hasGeneralWritePermission) {
            return true;
        }
        return hasAdministratorPermission;
    }, [contract, hasGeneralWritePermission, hasAdministratorPermission, userId]);

    // Check if the tenant has renewal date - default to yes before the data loads to optimize for tenants having it

    useEffect(() => {
        track("Contract Details: Contract page viewed", {
            contractId: id,
            isViewer: !checkPermissions("general", "write"),
        });
    }, [id, checkPermissions]);

    useEffect(() => {
        if (contract) {
            updateBreadCrumbs({ page: "detail", title: contract.title ?? "" });
        }
    }, [contract, updateBreadCrumbs]);

    const handleDiscardChanges = () => {
        setContract(data.contract);
        setEditMode(false);
    };

    const onSaveChangesSuccess = (data: Contract) => {
        setEditMode(false);
        setContract(data);
    };

    const handleActivateEditMode = () => {
        setEditMode(true);
    };

    const handleCustomFieldChange = (customFields: CustomField[]) => {
        setContract({ ...contract, customFields });
    };

    return (
        <Box>
            <ContractDetailsHeader
                referenceContract={data.contract}
                contract={contract}
                editMode={editMode}
                canEdit={canEdit}
                onDiscardChanges={handleDiscardChanges}
                onSaveChangesSuccess={onSaveChangesSuccess}
                onActivateEditMode={handleActivateEditMode}
                displayHeaderShadow={displayHeaderShadow}
            />

            <Stack px={4} gap={3} alignItems="start" maxWidth={2500} justifyContent="center" mx="auto">
                {/* This is used to calculate if the user has scrolled down, and then show the header shadow if true */}
                <Box ref={contentRef} position="absolute" top={0} />

                <Grid container direction="row" spacing={3}>
                    <Grid size={8}>
                        <Stack gap={3} p={2.5} component={Paper} elevation={1}>
                            <ContractBasicDetails editMode={editMode} contract={contract} setContract={setContract} />

                            <Divider />

                            <ContractOtherDetails
                                contract={contract}
                                editMode={editMode}
                                onCustomFieldChange={handleCustomFieldChange}
                            />
                        </Stack>
                    </Grid>
                    <Grid size={4}>
                        <ContractSidebar
                            contract={contract}
                            canEdit={canEdit}
                            editMode={editMode}
                            onContractChange={setContract}
                        />
                    </Grid>
                </Grid>
            </Stack>
        </Box>
    );
};
