import { Search } from "@ignite-analytics/icons";
import { Button, MenuList, Stack, Typography } from "@mui/material";
import { useCallback, useState, useRef } from "react";
import { FormattedMessage } from "react-intl";

import { useMutation } from "@apollo/client";
import { ShapeIcon } from "@ignite-analytics/components";
import { AddUsersSection } from "./AddUsersSection";
import { SearchUsers } from "./SearchUsers";

import { useAccessChanges } from "./useAccessChange";

import { EditPermissionsSection } from "./EditPermissionsSection";
import { UsersWithReadPermission } from "./UsersWithReadPermission";

import { ContractPermissions } from "@/hooks/useContractPermissions/types";
import { useContractPermissions } from "@/hooks/useContractPermissions/index";
import { graphql } from "@/generated/gql";
import { ContractObjectPermission } from "@/generated/graphql";
import { AccessLevel, AccessLevels } from "./types";

import { EditAccessHeader } from "./EditAccessHeader";

const EditRestrictedAccess_UpdateContractPermissions = graphql(/* GraphQL */ `
    mutation EditRestrictedAccess_UpdateContractPermissions($input: UpdateContractPermissionsInput!) {
        updateContractPermissions(input: $input) {
            count
        }
    }
`);

export interface EditRestrictedAccessProps {
    contractIds: string[];
    onCancel: () => void;
    onSave: (contractIds: string[], access: AccessLevel) => void;
    disableSaveButton: boolean;
}

export const EditRestrictedAccess: React.FC<EditRestrictedAccessProps> = ({
    contractIds,
    onSave,
    onCancel,
    disableSaveButton,
}) => {
    const [addUsersSectionOpen, setAddUsersSectionOpen] = useState(false);
    const [search, setSearch] = useState("");

    const searchFieldRef = useRef<HTMLInputElement>(null);
    const scrollRef = useRef<HTMLDivElement>(null);
    const [updateContractPermissions] = useMutation(EditRestrictedAccess_UpdateContractPermissions);

    const {
        changes,
        hasChanges,
        usersPendingToAdd,
        hasSelectedUsersToAdd,
        addReadPermission,
        // addWritePermission,
        removePermission,
        addUsersToPendingChanges,
        undoChange,
        handleToggleAddUser,
    } = useAccessChanges(contractIds);

    const {
        editPermissions,
        readPermissions,
        noPermissions,
        refetch: refetchPermissions,
    } = useContractPermissions(contractIds, search);

    const scrollToTop = useCallback(() => {
        scrollRef.current?.scrollTo({ top: 0 });
    }, []);

    const handleAddUsers = useCallback(() => {
        addUsersToPendingChanges();
        setAddUsersSectionOpen(false);
        setSearch("");
        scrollToTop();
    }, [addUsersToPendingChanges, scrollToTop]);

    const getPermissionLevel = (permission: ContractPermissions) => {
        const numberOfContractsWithAccess = permission.writes.length + permission.reads.length;

        if (permission.roleID === "global.admin") {
            return { accessLevel: "admin", numberOfContractsWithAccess };
        }

        if (changes.removed.some((p) => p.user.id === permission.user.id)) {
            return { accessLevel: "removeAll", numberOfContractsWithAccess };
        }
        if (
            changes.reads.some((p) => p.user.id === permission.user.id) ||
            numberOfContractsWithAccess === contractIds.length
        ) {
            return { accessLevel: "readAll", numberOfContractsWithAccess };
        }
        if (contractIds.length === 1) {
            if (numberOfContractsWithAccess === 1) {
                return { accessLevel: "readAll", numberOfContractsWithAccess };
            }
            return { accessLevel: "removeAll", numberOfContractsWithAccess };
        }

        return { accessLevel: "partialAccess", numberOfContractsWithAccess };
    };

    const showNoUsersFound =
        search !== "" && !noPermissions.length && !editPermissions.length && !readPermissions.length;

    const handleChangePermission = (permission: ContractPermissions, permissionLevel: string) => {
        if (permissionLevel === "removeAll") {
            removePermission(permission);
        } else if (permissionLevel === "readAll") {
            addReadPermission(permission);
        } else if (permissionLevel === "partialAccess") {
            undoChange(permission);
        }
    };

    const handleSave = () => {
        onSave(contractIds, AccessLevels.RESTRICTED);
        updateContractPermissions({
            variables: {
                input: {
                    updates: [
                        ...contractIds.flatMap((contractId) =>
                            changes.reads.map((permission) => ({
                                contractID: contractId,
                                permission: "READ" as ContractObjectPermission,
                                userID: permission.user.id,
                            }))
                        ),
                        ...contractIds.flatMap((contractId) =>
                            changes.writes.map((permission) => ({
                                contractID: contractId,
                                permission: "READ" as ContractObjectPermission,
                                userID: permission.user.id,
                            }))
                        ),
                        ...contractIds.flatMap((contractId) =>
                            changes.removed.map((permission) => ({
                                contractID: contractId,
                                permission: "NONE" as ContractObjectPermission,
                                userID: permission.user.id,
                            }))
                        ),
                    ],
                },
            },
            onCompleted: () => {
                refetchPermissions();
            },
        });
    };

    const handleGoBackFromAddMoreUsers = () => {
        setAddUsersSectionOpen(false);
        setSearch("");
        scrollToTop();
    };

    const usersWithReadPermission = readPermissions.concat(
        changes.reads.filter((change) => !readPermissions.some((permission) => permission.user.id === change.user.id))
    );

    const showAccessHeader =
        !addUsersSectionOpen || (search !== "" && (usersWithReadPermission.length > 0 || editPermissions.length > 0));

    // Filter away users that are already added to changes.added
    const usersThatCanBeAdded = noPermissions.filter(
        (permission) => !changes.reads.some((change) => change.user.id === permission.user.id)
    );

    return (
        <Stack paddingY={1} spacing={2}>
            <SearchUsers
                onSearch={setSearch}
                search={search}
                onAddUsers={handleAddUsers}
                addPeopleButtonVisible={addUsersSectionOpen && hasSelectedUsersToAdd}
                showGoBackButton={addUsersSectionOpen}
                onClose={handleGoBackFromAddMoreUsers}
                onFocus={() => setAddUsersSectionOpen(true)}
                inputRef={searchFieldRef}
            />
            <Stack height={350} sx={{ overflow: "auto" }} ref={scrollRef}>
                <MenuList sx={{ width: "100%" }}>
                    {showAccessHeader && <EditAccessHeader />}
                    {showNoUsersFound && (
                        <Stack height={300} width="100%" alignItems="center" justifyContent="center" spacing={2}>
                            <ShapeIcon size="large">
                                <Search fontSize="inherit" />
                            </ShapeIcon>
                            <Typography variant="textSm" color="textTextBody">
                                <FormattedMessage defaultMessage="Oops! No users found matching your search" />
                            </Typography>
                        </Stack>
                    )}
                    {(!addUsersSectionOpen || search !== "") && (
                        <UsersWithReadPermission
                            permissions={usersWithReadPermission}
                            contractIds={contractIds}
                            onChange={handleChangePermission}
                            getPermissionLevel={getPermissionLevel}
                        />
                    )}
                    {(!addUsersSectionOpen || search !== "") && (
                        <EditPermissionsSection permissions={editPermissions} />
                    )}
                    {addUsersSectionOpen && (
                        <AddUsersSection
                            noPermissions={usersThatCanBeAdded}
                            selectedUsers={usersPendingToAdd}
                            onToggleUser={handleToggleAddUser}
                        />
                    )}
                </MenuList>
            </Stack>
            <Stack direction="row" justifyContent="flex-end" spacing={2}>
                <Button variant="outlined" color="secondary" onClick={onCancel}>
                    <FormattedMessage defaultMessage="Cancel" />
                </Button>
                <Button onClick={() => handleSave()} disabled={!hasChanges && disableSaveButton}>
                    <FormattedMessage defaultMessage="Confirm changes" />
                </Button>
            </Stack>
        </Stack>
    );
};
