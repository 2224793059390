import { Grid2 as Grid } from "@mui/material";
import { FormattedMessage } from "react-intl";
import { ShapeIcon } from "@ignite-analytics/components";
import { CheckCircle, Timer, XCircle } from "@ignite-analytics/icons";
import { InfoCard } from "./InfoCard";
import { graphql } from "@/generated/gql";
import { FragmentType, getFragmentData } from "@/generated/fragment-masking";
import { ContractStatus } from "./types";

const threeMonthsFromNow = new Date();
threeMonthsFromNow.setMonth(threeMonthsFromNow.getMonth() + 3);
const today = new Date();

const TotalOverviewCards_ContractFragment = graphql(/* GraphQL */ `
    fragment TotalOverviewCards_Contract on Contract {
        id
        startDate
        endDate
        totalSpend
    }
`);

export interface TotalOverviewCardsProps {
    contracts: FragmentType<typeof TotalOverviewCards_ContractFragment>[];
    setStatusFilter: (status: ContractStatus) => void;
}

export const TotalOverviewCards: React.FC<TotalOverviewCardsProps> = (props) => {
    // Active contracts are contracts where the end date is at least 6 months in the future

    const contracts = getFragmentData(TotalOverviewCards_ContractFragment, props.contracts);

    const activeContracts = contracts.filter((contract) => {
        if (!contract.endDate) return false;
        const endDate = new Date(contract.endDate);
        const threeMonthsFromNow = new Date();
        threeMonthsFromNow.setMonth(threeMonthsFromNow.getMonth() + 6);
        return endDate >= threeMonthsFromNow;
    });

    // Expiring contracts are contracts where the end date is less than 6 months from now, and the end date is greater than or equal to today
    const expiringContracts = contracts.filter((contract) => {
        if (!contract.endDate) return false;
        const endDate = new Date(contract.endDate);
        const today = new Date();
        return endDate < threeMonthsFromNow && endDate >= today;
    });

    const expiredContracts = contracts.filter((contract) => {
        if (!contract.endDate) return false;
        const endDate = new Date(contract.endDate);
        return endDate < today;
    });

    return (
        <Grid container spacing={3}>
            <Grid size={4}>
                <InfoCard
                    title={<FormattedMessage defaultMessage="Contracts expiring in next 3 months" />}
                    icon={
                        <ShapeIcon color="warning">
                            <Timer />
                        </ShapeIcon>
                    }
                    numberOfContracts={expiringContracts?.length ?? 0}
                    spend={expiringContracts?.reduce((acc, contract) => acc + (contract.totalSpend ?? 0), 0) ?? 0}
                />
            </Grid>
            <Grid size={4}>
                <InfoCard
                    title={<FormattedMessage defaultMessage="Contracts expired in last 3 months" />}
                    icon={
                        <ShapeIcon color="error">
                            <XCircle />
                        </ShapeIcon>
                    }
                    numberOfContracts={expiredContracts?.length ?? 0}
                    spend={expiredContracts?.reduce((acc, contract) => acc + (contract.totalSpend ?? 0), 0) ?? 0}
                />
            </Grid>
            <Grid size={4}>
                <InfoCard
                    title={<FormattedMessage defaultMessage="Active contracts" />}
                    icon={
                        <ShapeIcon color="success">
                            <CheckCircle />
                        </ShapeIcon>
                    }
                    numberOfContracts={activeContracts?.length ?? 0}
                    spend={activeContracts?.reduce((acc, contract) => acc + (contract.totalSpend ?? 0), 0) ?? 0}
                />
            </Grid>
        </Grid>
    );
};
