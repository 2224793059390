import { formatDateAsUTC } from "@ignite-analytics/locale";
import { Chip, Link as Stack, Typography } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import { ReactElement } from "react";
import { makeGraphqlRouter } from "src/http";

import { useCompanyCurrency } from "@/contexts/CompanyCurrencyContext";
import { usePermissionCheck } from "@/hooks/usePermissionCheck";
import { env } from "@/env";

import { CustomField } from "../contractListItem";
import { SupplierCustomField } from "@/components/CustomField/SupplierCustomField";
import { TitleCustomField } from "@/components/CustomField/TitleCustomField";
import { UserCell } from "./UserCell";

const fixDmsContacts = env.REACT_APP_ENV !== "local";

const getSupplierQuery = `
    query getSupplier($input: GetSupplierInput!) {
        getSupplier(input: $input) {
            supplier {
                id
                name
            }
        }
    }
`;

const formatDate = (date: string | Date | number) => {
    return formatDateAsUTC(date, { month: "short" });
};

const fetchSupplierById = (id: string): Promise<string> =>
    makeGraphqlRouter()
        .post("", {
            query: getSupplierQuery,
            variables: {
                input: {
                    id,
                },
            },
        })
        .then((response) => response.data.data.getSupplier.supplier.id);

const onSupplierClick = async (supplierId: string) => {
    const winnerId = await fetchSupplierById(supplierId);
    const newPath = `/supplier-profile/${winnerId}/overview`;
    window.postMessage(
        {
            type: "route-change",
            path: newPath,
            from: window.location.pathname,
        },
        window.location.origin
    );
};

type RenderSupplierChipProps = {
    id?: string;
    name?: string;
};
export const RenderSupplierChip = ({ id, name }: RenderSupplierChipProps) => {
    const canOpenSupplierCard = usePermissionCheck("suppliers", "general", "read").data ?? false;
    if (!id) {
        return <Typography>-</Typography>;
    }
    return (
        <Chip
            disabled={!canOpenSupplierCard}
            onClick={() => onSupplierClick(id)}
            label={name ?? "Missing name"}
            size="small"
        />
    );
};

type RenderUsersChipProps = {
    users: { firstName: string; lastName: string }[];
};
export const RenderUsersChip = ({ users }: RenderUsersChipProps) => {
    return (
        <>
            {users.map((user) => (
                <Chip sx={{ mx: 0.5 }} label={`${user.firstName} ${user.lastName}`} key={user.firstName} size="small" />
            ))}
        </>
    );
};

type RenderDateCellProps = {
    date?: string;
};
export const RenderDateCell = ({ date }: RenderDateCellProps) => {
    if (!date) {
        return <Typography>-</Typography>;
    }
    return formatDate(date);
};

type RenderMonetaryAmountCellProps = {
    amount?: number;
};
export const RenderMonetaryAmountCell = ({ amount }: RenderMonetaryAmountCellProps) => {
    const currency = useCompanyCurrency();
    if (amount === undefined) {
        return <Typography sx={{ textAlign: "right" }}>-</Typography>;
    }
    return (
        <Typography sx={{ textAlign: "right" }} variant="inherit">
            {currency.formatCompact(amount)}
        </Typography>
    );
};

type CustomFieldProps = {
    field: CustomField;
};
export const RenderCustomField = ({ field }: CustomFieldProps): ReactElement => {
    const canOpenSupplierCard = usePermissionCheck("suppliers", "general", "read").data ?? false;
    const currency = useCompanyCurrency();

    switch (field.dataType) {
        case "TITLE":
            return <TitleCustomField contractId={field.data.value ?? ""} title={field.data.label} />;

        case "SUPPLIER":
            return (
                <>
                    {field.data?.value && (
                        <SupplierCustomField
                            name={field.data.label}
                            id={field.data.value}
                            canOpen={canOpenSupplierCard}
                        />
                    )}
                </>
            );
        case "END_DATE":
            return <>{field.data ? formatDate(field.data) : ""}</>;
        case "TEXT":
            return (
                <Typography variant="textSm" noWrap>
                    {field.data}
                </Typography>
            );
        case "TEXT_LIST":
        case "TAG":
            return <>{field.data}</>;
        case "NUMBER":
        case "NUMBER_AGGREGATION":
            return (
                <Typography variant="textSm" sx={{ textAlign: "right" }}>
                    {Number.isInteger(Number(field.data)) ? field.data : Number(field.data).toFixed(2)}
                </Typography>
            );
        case "MONETARY_AMOUNT":
            return (
                <Typography variant="textSm" sx={{ textAlign: "right" }} key={field.id}>
                    {field.data?.currency} {field.data?.amount}
                </Typography>
            );
        case "SPEND": {
            // the currency code is written in the header, so we don't need to display it here
            let amount = currency.formatInteger(field.data);
            if (currency.code) {
                amount = amount.replace(currency.code, "");
            }
            return (
                <Typography variant="textSm" sx={{ textAlign: "right" }} key={field.id}>
                    {amount}
                </Typography>
            );
        }
        case "BOOLEAN":
            return (
                <Typography key={field.id} sx={{ textAlign: "center" }}>
                    <Checkbox checked={field.data ?? false} />
                </Typography>
            );
        case "DATE_AGGREGATION":
        case "DATE":
            return (
                <Typography variant="textSm" key={field.id}>
                    {field.data ? formatDate(field.data) : ""}
                </Typography>
            );
        case "CONTACT":
        case "COMPANY":
            return <>{field.data?.label ?? ""}</>;
        case "USER":
            return <UserCell userIds={field.data?.value ? [field.data?.value] : []} />;
        case "GROUP_STRUCTURE":
            return <>{field.data?.id?.label ?? ""}</>;
        case "TAG_LIST":
            return (
                <Stack display="block">
                    {field.data?.map((item) => (
                        <Chip sx={{ m: 0.5 }} label={item} key={field.name + item} size="small" />
                    ))}
                </Stack>
            );
        case "CONTACT_LIST":
            return (
                <Stack display="block">
                    {field.data?.map((item) => (
                        <Chip sx={{ m: 0.5 }} label={item.label} key={item.value} size="small" />
                    ))}
                </Stack>
            );
        case "USER_LIST":
            return (
                <>
                    {fixDmsContacts && <UserCell userIds={field.data.map((d) => d.value as string)} />}
                    {!fixDmsContacts && (
                        <Stack display="block">
                            {field.data?.map((item) => (
                                <Chip sx={{ m: 0.5 }} label={item.label} key={item.value} size="small" />
                            ))}
                        </Stack>
                    )}
                </>
            );
        case "GROUP_STRUCTURE_LIST":
            return (
                <Stack display="block">
                    {field.data?.ids?.map((item) => (
                        <Chip sx={{ m: 0.5 }} label={item.label} key={item.value} size="small" />
                    ))}
                </Stack>
            );
        default:
            return <Typography variant="textSm" key={field.id} />;
    }
};
