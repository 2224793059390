import { useEffect, useMemo, useState } from "react";
import { useErrorHandler } from "src/errorHandling/ErrorHandlerContext";
import { makeContractsApiHttp } from "src/http";

export const useContractsApiFetch = <T>(url: string) => {
    const { handleError } = useErrorHandler();
    const [data, setData] = useState<T>();
    const [isLoading, setLoading] = useState(false);
    const [error, setError] = useState<Error>();
    const http = useMemo(() => makeContractsApiHttp(), []);

    useEffect(() => {
        setLoading(true);
        http.get<T>(url)
            .then((res) => {
                setData(res.data);
            })
            .catch((err) => {
                handleError(err);
                setError(err);
            })
            .finally(() => setLoading(false));
    }, [url, handleError, setLoading, http]);

    return {
        data,
        setData,
        error,
        isLoading,
        refetch: async () => {
            setLoading(true);
            return http
                .get<T>(url)
                .then((res) => {
                    setData(res.data);
                })
                .catch((err) => {
                    handleError(err);
                    setError(err);
                })
                .finally(() => setLoading(false));
        },
    };
};
