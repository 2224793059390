import { useIntl } from "react-intl";

import { AskDocumentQuestion } from "@/components/AskDocumentQuestion";
import { FileAttachments } from "@/components/FileAttachments";
import { useAttachments } from "@/components/FileAttachments/hooks";

import { SidebarCard } from "./SidebarCard";
import { detailRoute } from "@/routes/detail/$id";

export interface DocumentsCardProps {
    editMode: boolean;
    canEdit: boolean;
}

export const DocumentsCard: React.FC<DocumentsCardProps> = ({ editMode, canEdit }) => {
    const { id } = detailRoute.useParams();
    const { formatMessage } = useIntl();
    const attachments = useAttachments(id);

    return (
        <SidebarCard label={formatMessage({ defaultMessage: "Documents" })}>
            <>
                <FileAttachments
                    upload={async (file) => {
                        await attachments.upload(file);
                        await attachments.refetch();
                    }}
                    edit={editMode}
                    canEdit={canEdit}
                    attachments={attachments.data.filter((att) => att.url !== null)}
                    onDelete={(fileId) => attachments.delete(fileId)}
                    onToggleVisibility={(fileId, checked) => attachments.toggleVisibility(fileId, checked)}
                />
                {attachments.data.length > 0 && <AskDocumentQuestion availableDocuments={attachments.data} />}
            </>
        </SidebarCard>
    );
};
