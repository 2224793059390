import { createRoute } from "@tanstack/react-router";

import { FullWidthSpinner } from "@/components/ui/FullWidthSpinner";

import { contractDetailQueryKey, defaultLayoutQueryKey, supplierNameQueryKey } from "@/querykeys";
import { queryClient } from "@/contexts";

import { currentIntl } from "@/contexts/IntlContext";
import { getSupplierNameById } from "@/hooks/useFetchSupplier";

import { getContractById } from "@/pages/detail/loaders";
import { getLayoutByIdOrDefault } from "@/pages/list/loaders";
import { DetailPage } from "@/pages/detail";
import { rootRoute } from "@/routes/routes";

export const detailRoute = createRoute({
    getParentRoute: () => rootRoute,
    pendingComponent: FullWidthSpinner,
    pendingMs: 0,
    path: "/detail/$id",
    component: DetailPage,
    loader: async ({ context, params }) => {
        const contract = await queryClient.ensureQueryData({
            queryKey: contractDetailQueryKey(context.session.tenant, params.id),
            queryFn: () => getContractById(params.id),
        });
        let layout = await queryClient.ensureQueryData({
            queryKey: defaultLayoutQueryKey(context.session.tenant),
            queryFn: () => getLayoutByIdOrDefault("default"),
        });

        if (layout === undefined || layout === null) {
            // fallback to a default layout, which contain all custom fields
            layout = {
                id: "tmp",
                groups: [
                    {
                        name: currentIntl().formatMessage({ defaultMessage: "Other details" }),
                        items: contract.customFields.map((field, index) => ({
                            refId: field.id,
                            name: field.name,
                            visible: true,
                            order: index,
                        })),
                        visible: true,
                        order: 1,
                    },
                ],
            };
        }
        let supplierName: string | null = null;
        if (contract.supplierId !== null && contract.supplierId !== undefined && contract.supplierId !== "") {
            supplierName = await queryClient.ensureQueryData({
                queryKey: supplierNameQueryKey(context.session.tenant, contract.supplierId),
                queryFn: () => getSupplierNameById(contract.supplierId!),
            });
        }
        return { contract, supplierName, layout };
    },
});
