import { ArrowLeft, FileCheck, Pen, Trash } from "@ignite-analytics/icons";
import { Stack, IconButton, Typography, Button } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { FormattedMessage, useIntl } from "react-intl";
import { useMutation } from "@tanstack/react-query";
import { useRouter } from "@tanstack/react-router";
import { useMemo, useState } from "react";

import { patchContract as loadersPatchContract } from "../loaders";
import { contractDetailQueryKey, contractListQueryKeyPredicate } from "@/querykeys";
import { useSessionContext } from "@/contexts/SessionContext";
import { queryClient } from "@/contexts";
import { computePatchContractData } from "../utils/computePatch";
import { makeContractsApiHttp } from "@/http";
import { useErrorHandler } from "@/errorHandling/ErrorHandlerContext";
import { Contract } from "@/types";
import { RiddleMenuWithButton } from "@/components/ui/Menu";
import DeletePrompt from "@/components/DeletePrompt";
import { detailRoute } from "@/routes/detail/$id";

export interface ContractDetailsHeaderProps {
    referenceContract: Contract;
    contract: Contract;
    editMode: boolean;
    canEdit: boolean;
    displayHeaderShadow: boolean;
    onDiscardChanges: () => void;
    onSaveChangesSuccess: (data: Contract) => void;
    onActivateEditMode: () => void;
}

export const ContractDetailsHeader: React.FC<ContractDetailsHeaderProps> = ({
    referenceContract,
    contract,
    editMode,
    canEdit,
    displayHeaderShadow,
    onDiscardChanges,
    onSaveChangesSuccess,
    onActivateEditMode,
}) => {
    const [deleteContractDialogOpen, setDeleteContractDialogOpen] = useState<boolean>(false);
    const { id } = detailRoute.useParams();
    const { history, invalidate } = useRouter();
    const { tenant } = useSessionContext();
    const { formatMessage } = useIntl();
    const http = useMemo(() => makeContractsApiHttp(), []);
    const { handleError } = useErrorHandler();

    const patchMutation = useMutation({
        mutationFn: loadersPatchContract,
        onSuccess: (data) => {
            onSaveChangesSuccess(data);
            queryClient.setQueryData(contractDetailQueryKey(tenant, id), data);
            queryClient.removeQueries({ predicate: contractListQueryKeyPredicate });
        },
    });

    const deleteContract = async () => {
        if (contract) {
            await http
                .delete(`contracts/${id}`)
                .then(() => {
                    invalidate(); // deleted contract -> invalidate list cache
                    queryClient.removeQueries({ predicate: contractListQueryKeyPredicate });
                    history.back();
                })
                .catch(handleError);
        }
    };

    return (
        <Stack
            direction="row"
            mx="auto"
            justifyContent="center"
            width="100%"
            position="sticky"
            sx={{
                zIndex: 1,
                background: (theme) => theme.palette.background.default,
                transition: "box-shadow 0.2s",
                top: "var(--appbar-height, 0px)",
            }}
            boxShadow={(theme) => (displayHeaderShadow ? theme.shadows : undefined)}
        >
            <Stack
                justifyContent="space-between"
                direction="row"
                px={4}
                width="100%"
                maxWidth={2500}
                py={1}
                gap={3}
                alignItems="center"
            >
                <Stack direction="row" gap={1.5} alignItems="center">
                    <IconButton
                        sx={{ alignSelf: "start" }}
                        size="small"
                        color="ghostGray"
                        onClick={() => {
                            history.back();
                        }}
                    >
                        <ArrowLeft />
                    </IconButton>
                    <Typography fontWeight={700} variant="h6">
                        {contract.title}
                    </Typography>
                </Stack>
                {editMode ? (
                    <Stack direction="row" gap={1.5}>
                        <Button color="secondary" onClick={onDiscardChanges} size="small">
                            <FormattedMessage defaultMessage="Discard changes" />
                        </Button>
                        <LoadingButton
                            loading={patchMutation.isPending}
                            startIcon={<FileCheck />}
                            onClick={async () => {
                                patchMutation.mutate({
                                    id: contract.id,
                                    data: computePatchContractData(contract, referenceContract),
                                });
                            }}
                            size="small"
                        >
                            <FormattedMessage defaultMessage="Save changes" />
                        </LoadingButton>
                    </Stack>
                ) : (
                    <RiddleMenuWithButton
                        buttonLabel={formatMessage({ defaultMessage: "Options" })}
                        size="small"
                        entries={[
                            {
                                label: formatMessage({ defaultMessage: "Edit contract" }),
                                icon: { pos: "start", component: <Pen /> },
                                disabled: !canEdit,
                                disabledTooltipText: formatMessage({
                                    defaultMessage:
                                        "Only administrators or contract responsible with editor access can modify this contract.",
                                }),
                                onClick: onActivateEditMode,
                            },
                            {
                                label: formatMessage({ defaultMessage: "Delete contract" }),
                                danger: true,
                                disabled: !canEdit,
                                disabledTooltipText: formatMessage({
                                    defaultMessage:
                                        "Only administrators or contract responsible with editor access can modify this contract.",
                                }),
                                icon: { pos: "start", component: <Trash /> },
                                onClick: () => setDeleteContractDialogOpen(true),
                            },
                        ]}
                    />
                )}
                <DeletePrompt
                    open={deleteContractDialogOpen}
                    onDelete={async () => {
                        await deleteContract();
                        setDeleteContractDialogOpen(false);
                    }}
                    onCancel={() => setDeleteContractDialogOpen(false)}
                />
            </Stack>
        </Stack>
    );
};
