import { MenuItem, Select, Typography } from "@mui/material";

import { FormattedMessage } from "react-intl";
import { ContractPermissions } from "@/hooks/useContractPermissions/types";

export interface SelectUserPermissionLevelProps {
    contractIds: string[];
    getPermissionLevel: (user: ContractPermissions) => {
        accessLevel: string;
        numberOfContractsWithAccess: number;
    };
    user: ContractPermissions;
    onChange: (user: ContractPermissions, permissionLevel: string) => void;
}

export const SelectUserPermissionLevel: React.FC<SelectUserPermissionLevelProps> = ({
    contractIds,
    getPermissionLevel,
    user,
    onChange,
}) => {
    const numberOfContracts = contractIds.length;

    const { accessLevel, numberOfContractsWithAccess } = getPermissionLevel(user);

    if (contractIds.length > 1) {
        const hasPartialAccess =
            numberOfContractsWithAccess !== contractIds.length &&
            numberOfContractsWithAccess !== numberOfContracts &&
            numberOfContractsWithAccess !== 0;

        return (
            <Select value={accessLevel} size="small" onChange={(e) => onChange(user, e.target.value)}>
                {hasPartialAccess && (
                    <MenuItem value="partialAccess">
                        <Typography variant="textSm">
                            <FormattedMessage
                                defaultMessage="Can access {numberOfContractsWithAccess} of {numberOfContracts}"
                                values={{ numberOfContractsWithAccess, numberOfContracts }}
                            />
                        </Typography>
                    </MenuItem>
                )}

                <MenuItem value="readAll">
                    <Typography variant="textSm">
                        <FormattedMessage
                            defaultMessage="Can access all {numberOfContracts}"
                            values={{ numberOfContracts }}
                        />
                    </Typography>
                </MenuItem>

                <MenuItem value="removeAll">
                    <Typography variant="textSm" color="error">
                        <FormattedMessage defaultMessage="Remove access" />
                    </Typography>
                </MenuItem>
            </Select>
        );
    }

    return (
        <Select value={accessLevel} size="small" onChange={(e) => onChange(user, e.target.value)}>
            <MenuItem value="readAll">
                <Typography variant="textSm">
                    <FormattedMessage defaultMessage="Can view" />
                </Typography>
            </MenuItem>
            <MenuItem value="removeAll">
                <Typography variant="textSm" color="error">
                    <FormattedMessage defaultMessage="Remove access" />
                </Typography>
            </MenuItem>
        </Select>
    );
};
