import { Stack, Switch, Typography } from "@mui/material";
import { DataGridPro } from "@mui/x-data-grid-pro";
import { FormattedMessage } from "react-intl";
import { useState } from "react";
import { TotalOverviewCards } from "./TotalOverviewCards";

import { useColumns } from "./useColumns";
import { useTotalOverviewRows } from "./useTotalOverviewRows";
import { InformationTooltip } from "@/components/InformationTooltip";
import { TableToolbar, TableToolbarProps } from "./TableToolbar";

declare module "@mui/x-data-grid-pro" {
    interface ToolbarPropsOverrides extends TableToolbarProps {}
}

export const TotalOverview: React.FC = () => {
    const [searchTerm, setSearchTerm] = useState<string>("");
    const columns = useColumns();
    const {
        rows,
        onSortContracts,
        unfilteredContracts,
        loading,
        showMyContracts,
        onToggleShowMyContracts,
        onSetStatusFilter,
        statusFilter,
    } = useTotalOverviewRows(searchTerm);

    const onSearch = (value: string) => {
        setSearchTerm(value);
    };

    return (
        <Stack spacing={2}>
            <Stack direction="row" spacing={2} alignItems="center" justifyContent="space-between">
                <Typography variant="textLg" fontWeight={500}>
                    <FormattedMessage defaultMessage="Contracts" />
                </Typography>
                <Stack direction="row" spacing={1} alignItems="center">
                    <Switch value={showMyContracts} onChange={onToggleShowMyContracts} />
                    <Typography color="textTextSecondary" variant="textSm">
                        <FormattedMessage defaultMessage="Show my contracts" />
                    </Typography>
                    <InformationTooltip
                        content={<FormattedMessage defaultMessage="Show only contracts that you are responsible for" />}
                    />
                </Stack>
            </Stack>
            <TotalOverviewCards contracts={unfilteredContracts} setStatusFilter={onSetStatusFilter} />

            <Stack height="70vh">
                <DataGridPro
                    rows={rows}
                    columns={columns}
                    onSortModelChange={onSortContracts}
                    disableColumnMenu
                    disableColumnResize
                    disableColumnFilter
                    loading={loading}
                    slotProps={{
                        loadingOverlay: {
                            variant: "skeleton",
                            noRowsVariant: "skeleton",
                        },
                        toolbar: {
                            totalNumberOfRows: rows.length,
                            searchTerm,
                            onSearch,
                            onSetStatusFilter,
                            statusFilter,
                        },
                    }}
                    slots={{
                        toolbar: TableToolbar,
                    }}
                />
            </Stack>
        </Stack>
    );
};
