import { FormattedMessage } from "react-intl";
import {
    ActiveChip,
    ExpiredChip,
    ExpiringInDaysChip,
    ExpiringInMonthsChip,
    MissingDatesChip,
    NotStartedChip,
} from "./chips";

const MILLISECONDS_PER_DAY = 24 * 60 * 60 * 1000;
const DAYS_PER_MONTH = 30;
const MILLISECONDS_PER_MONTH = DAYS_PER_MONTH * MILLISECONDS_PER_DAY;

export const getStatusChipContent = (endDate: Date | null, startDate?: Date | null) => {
    const timeNow = new Date();

    if (!endDate) {
        return {
            chip: <MissingDatesChip />,
            tooltipTitle: <FormattedMessage defaultMessage="Missing end date" />,
        };
    }

    if (startDate && new Date(startDate) > timeNow) {
        return {
            chip: <NotStartedChip />,
            tooltipTitle: <FormattedMessage defaultMessage="Not started" />,
        };
    }

    const getExpiringSoonChip = (endDate: Date) => {
        const timeNow = new Date();
        const timeDifference = endDate.getTime() - timeNow.getTime();
        const months = Math.floor(timeDifference / MILLISECONDS_PER_MONTH);
        // Add 1 to include the current day in the count (e.g. if difference is 2.2 days, we want to show "3 days remaining" not "2")
        const days = Math.floor(timeDifference / MILLISECONDS_PER_DAY) + 1;
        if (months > 0) {
            return <ExpiringInMonthsChip months={months} />;
        }
        return <ExpiringInDaysChip days={days} />;
    };

    // If end date is before now, return "expired"
    if (new Date(endDate) < timeNow) {
        return { chip: <ExpiredChip />, tooltipTitle: <FormattedMessage defaultMessage="Expired" /> };
    }

    // If end date is before 6 months from now, return "expiring soon"
    const threeMonthsFromNow = new Date(timeNow);
    threeMonthsFromNow.setMonth(timeNow.getMonth() + 3);
    if (new Date(endDate) < threeMonthsFromNow) {
        // Calculate months and days until expiry
        return {
            chip: getExpiringSoonChip(endDate),
            tooltipTitle: <FormattedMessage defaultMessage="Expiring in less than three months" />,
        };
    }
    // If end date is after 6 months from now, return "active"
    return {
        chip: <ActiveChip />,
        tooltipTitle: <FormattedMessage defaultMessage="Expiring in more than three months" />,
    };
};
