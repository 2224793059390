import React, { useRef, useEffect, useMemo, useState } from "react";
import { keepPreviousData, useMutation, useQuery } from "@tanstack/react-query";
import {
    Box,
    Button,
    Chip,
    Divider,
    Grid,
    IconButton,
    Input,
    Paper,
    Stack,
    TextField,
    Typography,
} from "@mui/material";
import { useRouter } from "@tanstack/react-router";
import { useErrorHandler } from "src/errorHandling/ErrorHandlerContext";
import { makeContractsApiHttp } from "src/http";
import { usePermissionHandler } from "src/contexts/PermissionsContext";
import { track } from "@ignite-analytics/track";
import { useBreadcrumbs } from "src/hooks/useBreadcrumbs";
import { AccessOverview } from "src/components/AccessOverview";
import { ManageAccessPopup } from "src/components/ManageAccessPopup/manageAccessPopup";
import { useContractAccessors } from "src/hooks/useContractAccessors";
import dayjs from "dayjs";
import { FormattedMessage, useIntl } from "react-intl";
import { ArrowLeft, FileCheck, Pen, Plus, Trash } from "@ignite-analytics/icons";
import { LoadingButton } from "@mui/lab";
import { DesktopDatePicker } from "@mui/x-date-pickers";
import { Contract, ContractFields, PatchContractCommand } from "@/types";
import { useSessionContext } from "@/contexts/SessionContext";
import { contractDetailRoute } from "@/Router";
import { RiddleMenu, RiddleMenuWithButton } from "@/components/ui/Menu";
import { useCompanyCurrency } from "@/contexts/CompanyCurrencyContext";
import { useUsers } from "@/entities/users";
import { ContactsDropdown } from "@/components/ui/ContactDropdown/contactsEdit";
import { useContractsApiFetch } from "@/hooks/useContractsApiFetch";
import { useContractsApiPost } from "@/hooks/useContractsApiPost";
import { useDeleteAlert } from "@/hooks/useAlerts";
import { Alert } from "@/components/Alerts/dumb/models";
import { hasValue } from "@/helpers/hasValue";
import { ContractResponsibleDisplay } from "@/components/UserDisplay/ContractResponsibleView";
import { NotesV2 } from "@/components/Notes/NotesV2";
import { useNotes } from "@/components/Notes/NotesV2/hooks";
import { FileAttachments } from "@/components/FileAttachments";
import { useAttachments } from "@/components/FileAttachments/hooks";
import { SupplierDropDownV2 } from "@/components/SupplierDropdownV2";
import { useSuppliersSearch } from "@/components/SupplierDropdownV2/hooks";
import DeletePrompt from "@/components/DeletePrompt";
import { AddAlertPopupV2 } from "@/components/Alerts/AddAlertV2";
import { queryClient } from "@/contexts";
import { contractDetailQueryKey, contractListQueryKeyPredicate } from "@/querykeys";
import { getContractById, patchContract as loadersPatchContract } from "./loaders";
import { usePermissionCheck } from "@/hooks/usePermissionCheck";
import { Group } from "@/types/Layout";
import { addContact, editContact, getContactsBySupplierId } from "@/hooks/supplierContacts";
import { ContactPerson, EditContactPersonDialog } from "@/components/UserDisplay/Row";
import { SidebarCard } from "./sidebar";
import { ContractCustomField, ContractField } from "./field";
import { EmtpyCardContent } from "@/components/EmptyCardContent";
import { normalizeDateString } from "@/helpers/dates";

function computePatchCommandV2(dirty: Contract, reference: Contract) {
    const res: PatchContractCommand[] = [];
    if (dirty.title !== reference.title) {
        res.push({ operation: "replace", path: "title", data: dirty.title });
    }
    if (dirty.description !== reference.description) {
        res.push({ operation: "replace", path: "description", data: dirty.description });
    }
    if (dirty.startDate !== reference.startDate) {
        res.push({ operation: "replace", path: "startDate", data: dirty.startDate });
    }
    if (dirty.endDate !== reference.endDate) {
        res.push({ operation: "replace", path: "endDate", data: dirty.endDate });
    }
    if (dirty.renewalDate !== reference.renewalDate) {
        res.push({ operation: "replace", path: "renewalDate", data: dirty.renewalDate });
    }
    if (dirty.supplierId !== reference.supplierId || dirty.contactPersonIds !== reference.contactPersonIds) {
        res.push({
            operation: "replace",
            path: "supplier",
            data: { supplierId: dirty.supplierId, contactPersonIds: dirty.contactPersonIds },
        });
    }
    if (dirty.contractResponsibleIds !== reference.contractResponsibleIds) {
        res.push({ operation: "replace", path: "contractResponsibleIds", data: dirty.contractResponsibleIds });
    }
    if (dirty.customFields !== reference.customFields) {
        res.push({ operation: "replace", path: "customFields", data: dirty.customFields });
    }
    return res;
}
export const DetailPage = () => {
    const { id } = contractDetailRoute.useParams();
    const { handleError } = useErrorHandler();
    const data = contractDetailRoute.useLoaderData();

    // Represents the contract. Whenever we do a local update to the contract, we'll do
    // setContract({...contract, field: newval}).
    // Therefore, this object contains potentially local changes. The "server" version will
    // awlays be the data.contract object.
    const [contract, setContract] = useState<Contract>(data.contract);

    useEffect(() => {
        setContract(data.contract);
    }, [data.contract]);

    const { updateBreadCrumbs } = useBreadcrumbs();
    const [manageAccessPopupOpen, setManageAccessPopupOpen] = useState(false);
    const restricted = useMemo(() => contract?.isPrivate ?? false, [contract]);
    const responsible = useMemo(() => contract?.contractResponsibleIds ?? [], [contract]);
    const { accessors, refetch } = useContractAccessors(id, restricted, responsible);
    const { checkPermissions } = usePermissionHandler();
    const hasGeneralWritePermission = checkPermissions("general", "write");
    const hasAdministratorPermission = checkPermissions("general", "create");
    const canOpenSupplierCard = usePermissionCheck("suppliers", "general", "read").data ?? false;
    const http = useMemo(() => makeContractsApiHttp(), []);
    const { id: userId } = useSessionContext();
    const session = useSessionContext();
    const canEdit = useMemo(() => {
        if (contract.contractResponsibleIds?.includes(userId) && hasGeneralWritePermission) {
            return true;
        }
        return hasAdministratorPermission;
    }, [contract, hasGeneralWritePermission, hasAdministratorPermission, userId]);
    const [edit, setEdit] = useState(false);
    const [supplierName, setSupplierName] = useState<string | null>(data.supplierName);
    const notes = useNotes(id);
    const attachments = useAttachments(id);
    const [deleteContractDialogOpen, setDeleteContractDialogOpen] = useState(false);
    const router = useRouter();

    // Check if the tenant has renewal date - default to yes before the data loads to optimize for tenants having it
    const { data: columns } = useContractsApiFetch<ContractFields>("/contracts/metadata");
    const hasRenewalDate = columns === undefined || columns.specificFields.renewalDate !== undefined;

    const { users } = useUsers();

    const contractResponsibleUsers = useMemo(() => {
        return (contract?.contractResponsibleIds || [])
            .map((id) => users.find((user) => user.id === id))
            .filter(hasValue);
    }, [contract, users]);

    const currency = useCompanyCurrency();
    // TODO: handle case when supplier is not set
    const alerts = useContractsApiFetch<Alert[]>(`contracts/${id}/reminders`);
    const [alertModalOpen, setAlertModalOpen] = useState(false);
    const apiPost = useContractsApiPost();
    const deleteAlert = useDeleteAlert();
    const search = useSuppliersSearch();
    const { formatMessage } = useIntl();

    const patchMutation = useMutation({
        mutationFn: loadersPatchContract,
        onSuccess: (data) => {
            setEdit(false);
            setContract(data);
            queryClient.setQueryData(contractDetailQueryKey(session.tenant, id), data);
            queryClient.removeQueries({ predicate: contractListQueryKeyPredicate });
        },
    });

    const navigateToSupplier = (supplierId: string) => {
        const newPath = `${window.location.pathname}/supplier-page/${supplierId}/overview`;
        window.postMessage(
            {
                type: "route-change",
                path: newPath,
                from: window.location.pathname,
            },
            window.location.origin
        );
    };

    useEffect(() => {
        track("Contract Details: Contract page viewed", {
            contractId: id,
            isViewer: !checkPermissions("general", "write"),
        });
    }, [id, checkPermissions]);

    useEffect(() => {
        if (contract) {
            updateBreadCrumbs({ page: "detail", title: contract.title ?? "" });
        }
    }, [contract, updateBreadCrumbs]);

    const contacts = useQuery({
        placeholderData: keepPreviousData,
        enabled: true,
        queryKey: [session.tenant, "contracts", id, "contacts", contract.supplierId, "t"],
        queryFn: () => getContactsBySupplierId(contract.supplierId),
        staleTime: 1000 * 1,
    });
    const [addContactDialogOpen, setAddContactDialogOpen] = useState(false);
    const [selectExistingContactAnchorEl, setSelectExistingContactAnchorEl] = useState<HTMLElement | null>(null);

    const deleteContract = async () => {
        if (contract) {
            await http
                .delete(`contracts/${id}`)
                .then(() => {
                    router.invalidate(); // deleted contract -> invalidate list cache
                    queryClient.removeQueries({ predicate: contractListQueryKeyPredicate });
                    router.history.back();
                })
                .catch(handleError);
        }
    };

    function customfieldRenderOrder(contract: Contract, group: Group) {
        return group.items
            .filter((item) => item.visible)
            .sort((a, b) => a.order - b.order)
            .map((f) => contract.customFields.find((cf) => cf.id === f.refId))
            .filter((x) => x != null);
    }

    // The header should be sticky when the user scrolls. We achieve that by inserting a zero-sized component
    // at the top and observing whether we have scrolled or not.
    const boxRef = useRef<HTMLDivElement>(null);
    const [headerShadow, setHeaderShadow] = useState(false);
    useEffect(() => {
        if (boxRef.current) {
            const obs = new IntersectionObserver(
                (entries) => {
                    entries.forEach((entry) => {
                        if (entry.isIntersecting) {
                            setHeaderShadow(false);
                        } else {
                            setHeaderShadow(true);
                        }
                    });
                },
                { threshold: 1 }
            );
            obs.observe(boxRef.current);
            return () => obs.disconnect();
        }
    }, [boxRef]);

    return (
        <Box>
            {/* header */}
            <Stack
                direction="row"
                mx="auto"
                justifyContent="center"
                width="100%"
                position="sticky"
                sx={{
                    zIndex: 1,
                    background: (theme) => theme.palette.background.default,
                    transition: "box-shadow 0.2s",
                    top: "var(--appbar-height, 0px)",
                }}
                boxShadow={(theme) => (headerShadow ? theme.shadows : undefined)}
            >
                <Stack
                    justifyContent="space-between"
                    direction="row"
                    px={4}
                    width="100%"
                    maxWidth={2500}
                    py={1}
                    gap={3}
                    alignItems="center"
                >
                    <Stack direction="row" gap={1.5} alignItems="center">
                        <IconButton
                            sx={{ alignSelf: "start" }}
                            size="small"
                            color="ghostGray"
                            onClick={() => {
                                router.history.back();
                            }}
                        >
                            <ArrowLeft />
                        </IconButton>
                        <Typography fontWeight={700} variant="h6">
                            {contract.title}
                        </Typography>
                    </Stack>
                    {edit ? (
                        <Stack direction="row" gap={1.5}>
                            <Button
                                color="secondary"
                                onClick={() => {
                                    setContract(data.contract);
                                    setEdit(false);
                                }}
                                size="small"
                            >
                                <FormattedMessage defaultMessage="Discard changes" />
                            </Button>
                            <LoadingButton
                                loading={patchMutation.isPending}
                                startIcon={<FileCheck />}
                                onClick={async () => {
                                    patchMutation.mutate({
                                        id: contract.id,
                                        data: computePatchCommandV2(contract, data.contract),
                                    });
                                }}
                                size="small"
                            >
                                <FormattedMessage defaultMessage="Save changes" />
                            </LoadingButton>
                        </Stack>
                    ) : (
                        <RiddleMenuWithButton
                            buttonLabel={formatMessage({ defaultMessage: "Options" })}
                            size="small"
                            entries={[
                                {
                                    label: formatMessage({ defaultMessage: "Edit contract" }),
                                    icon: { pos: "start", component: <Pen /> },
                                    disabled: !canEdit,
                                    disabledTooltipText: formatMessage({
                                        defaultMessage:
                                            "Only administrators or contract responsible with editor access can modify this contract.",
                                    }),
                                    onClick: () => {
                                        setEdit(true);
                                    },
                                },
                                {
                                    label: formatMessage({ defaultMessage: "Delete contract" }),
                                    danger: true,
                                    disabled: !canEdit,
                                    disabledTooltipText: formatMessage({
                                        defaultMessage:
                                            "Only administrators or contract responsible with editor access can modify this contract.",
                                    }),
                                    icon: { pos: "start", component: <Trash /> },
                                    onClick: () => setDeleteContractDialogOpen(true),
                                },
                            ]}
                        />
                    )}
                    <DeletePrompt
                        open={deleteContractDialogOpen}
                        onDelete={async () => {
                            await deleteContract();
                            setDeleteContractDialogOpen(false);
                        }}
                        onCancel={() => setDeleteContractDialogOpen(false)}
                    />
                </Stack>
            </Stack>

            <Stack px={4} gap={3} alignItems="start" maxWidth={2500} justifyContent="center" mx="auto">
                {/* header */}
                <Box ref={boxRef} position="absolute" top={0} />
                <Grid container direction="row" spacing={3}>
                    <Grid item xs={8}>
                        <Stack gap={3} p={2.5} component={Paper} elevation={1}>
                            <Typography variant="textLg" fontWeight={500}>
                                <FormattedMessage defaultMessage="Basic details" />
                            </Typography>
                            <Divider />
                            <Grid container rowGap={3} columnSpacing={3}>
                                <ContractField
                                    edit={edit}
                                    xs={12}
                                    label={formatMessage({ defaultMessage: "Contract title" })}
                                >
                                    {contract.title}
                                    <TextField
                                        fullWidth
                                        sx={{ minWidth: "260px" }}
                                        value={contract.title}
                                        onChange={(e) => setContract({ ...contract, title: e.target.value })}
                                        placeholder={formatMessage({ defaultMessage: "Add title" })}
                                    />
                                </ContractField>
                                <ContractField
                                    blank={contract.description == null}
                                    edit={edit}
                                    xs={12}
                                    label={formatMessage({ defaultMessage: "Description" })}
                                >
                                    <Typography variant="textMd">{contract.description}</Typography>
                                    <Input
                                        disableUnderline
                                        minRows={3}
                                        value={contract.description}
                                        onChange={(e) => setContract({ ...contract, description: e.target.value })}
                                        multiline
                                        fullWidth
                                        placeholder={formatMessage({ defaultMessage: "Add description" })}
                                    />
                                </ContractField>
                                <ContractField
                                    xs={8}
                                    edit={edit}
                                    label={formatMessage({ defaultMessage: "Supplier" })}
                                    blank={contract.supplierId == null}
                                >
                                    <Stack direction="row">
                                        <Chip
                                            disabled={!canOpenSupplierCard}
                                            sx={{
                                                cursor: "pointer",
                                            }}
                                            label={supplierName}
                                            onClick={() => {
                                                if (contract.supplierId) {
                                                    navigateToSupplier(contract.supplierId);
                                                }
                                            }}
                                        />
                                    </Stack>
                                    <SupplierDropDownV2
                                        options={search.matches}
                                        loading={search.loading}
                                        onSearch={search.update}
                                        fullWidth
                                        value={
                                            contract.supplierId
                                                ? { id: contract.supplierId, name: supplierName ?? "" }
                                                : null
                                        }
                                        onChange={(row) => {
                                            if (row && contract) {
                                                setContract({ ...contract, supplierId: row.id });
                                                setSupplierName(row.name);
                                            }
                                            if (!row && contract) {
                                                setContract({ ...contract, supplierId: null });
                                                setSupplierName("");
                                            }
                                        }}
                                    />
                                </ContractField>
                                <ContractField
                                    xs={4}
                                    label={formatMessage({ defaultMessage: "Total spend" })}
                                    blank={contract.totalSpend === 0 || contract.totalSpend == null}
                                    edit={edit}
                                >
                                    {currency.formatInteger(contract.totalSpend)}
                                    <TextField fullWidth disabled value={currency.formatInteger(contract.totalSpend)} />
                                </ContractField>
                                <ContractField
                                    edit={edit}
                                    xs={4}
                                    label={formatMessage({ defaultMessage: "Start date" })}
                                    blank={contract.startDate == null}
                                >
                                    {normalizeDateString(contract.startDate)?.format("DD MMM YYYY")}
                                    <DesktopDatePicker
                                        slotProps={{ textField: { fullWidth: true }, field: { clearable: true } }}
                                        maxDate={contract.endDate ? dayjs(contract.endDate) : undefined}
                                        value={normalizeDateString(contract.startDate)}
                                        timezone="UTC"
                                        onChange={(value) =>
                                            setContract({
                                                ...contract,
                                                startDate: value == null ? null : value.format(),
                                            })
                                        }
                                    />
                                </ContractField>
                                <ContractField
                                    edit={edit}
                                    xs={4}
                                    label={formatMessage({ defaultMessage: "End date" })}
                                    blank={contract.endDate == null}
                                >
                                    {normalizeDateString(contract.endDate)?.format("DD MMM YYYY")}
                                    <DesktopDatePicker
                                        slotProps={{ textField: { fullWidth: true }, field: { clearable: true } }}
                                        value={normalizeDateString(contract.endDate)}
                                        timezone="UTC"
                                        minDate={contract.startDate ? dayjs(contract.startDate) : undefined}
                                        onChange={(value) =>
                                            setContract({
                                                ...contract,
                                                endDate: value == null ? null : value.format(),
                                            })
                                        }
                                    />
                                </ContractField>
                                {hasRenewalDate && (
                                    <ContractField
                                        edit={edit}
                                        xs={4}
                                        label={formatMessage({ defaultMessage: "Renewal date" })}
                                        blank={contract.renewalDate == null}
                                    >
                                        {normalizeDateString(contract.renewalDate)?.format("DD MMM YYYY")}
                                        <DesktopDatePicker
                                            slotProps={{
                                                textField: { fullWidth: true },
                                                field: { clearable: true },
                                            }}
                                            minDate={contract.startDate ? dayjs(contract.startDate) : undefined}
                                            value={normalizeDateString(contract.renewalDate)}
                                            timezone="UTC"
                                            onChange={(value) =>
                                                setContract({
                                                    ...contract,
                                                    renewalDate: value == null ? null : value.format(),
                                                })
                                            }
                                        />
                                    </ContractField>
                                )}
                            </Grid>
                            <Divider />

                            {data.layout.groups.map((g) => (
                                <Stack key={g.id} gap={2}>
                                    <Typography variant="textLg" fontWeight="500">
                                        {g.name}
                                    </Typography>
                                    <Grid container rowGap={3} columnSpacing={3}>
                                        {customfieldRenderOrder(contract, g).map((customField) => (
                                            <ContractCustomField
                                                key={customField.id}
                                                edit={edit}
                                                customField={customField}
                                                onChange={(value) => {
                                                    const updated = contract.customFields.map((cf) =>
                                                        cf.id === customField.id ? value : cf
                                                    );
                                                    setContract((curr) => ({ ...curr, customFields: updated }));
                                                }}
                                            />
                                        ))}
                                    </Grid>
                                </Stack>
                            ))}
                        </Stack>
                    </Grid>
                    <Grid item xs={4}>
                        <Stack gap={2} minWidth="318px" maxWidth="1157px">
                            <SidebarCard
                                label={formatMessage({ defaultMessage: "Access to this contract" })}
                                edit={edit}
                                onClickEdit={() => {
                                    setManageAccessPopupOpen(true);
                                }}
                            >
                                <AccessOverview
                                    restricted={contract?.isPrivate}
                                    canEdit={canEdit}
                                    onEdit={() => setManageAccessPopupOpen(true)}
                                    max={5}
                                    accessors={accessors}
                                />
                            </SidebarCard>
                            <ManageAccessPopup
                                onClose={() => setManageAccessPopupOpen(false)}
                                isOpen={manageAccessPopupOpen}
                                contractIds={[id]}
                                updateContracts={async () => {
                                    setContract(await getContractById(id));
                                    await refetch();
                                }}
                                defaultAccess={contract?.isPrivate ? "restricted" : "public"}
                            />
                            <SidebarCard
                                label={formatMessage({ defaultMessage: "Contract responsible" })}
                                blank={contractResponsibleUsers.length === 0}
                                edit={edit}
                            >
                                <ContractResponsibleDisplay users={contractResponsibleUsers} max={5} />
                                <ContactsDropdown
                                    multiple
                                    defaultValue={contractResponsibleUsers}
                                    options={users}
                                    onChange={(e) => {
                                        setContract({
                                            ...contract,
                                            contractResponsibleIds: e.map((user) => user.id),
                                        });
                                    }}
                                />
                            </SidebarCard>

                            <SidebarCard
                                label={formatMessage({ defaultMessage: "Contact persons" })}
                                editLabel={formatMessage({ defaultMessage: "Add new" })}
                                edit={edit}
                                editDisabled={contract.supplierId === null}
                                onClickEdit={(e) => {
                                    if (contacts.data?.length === 0) {
                                        // no point in showing the menu of contacts if there are none
                                        // --> shortcut to add contact dialog
                                        setAddContactDialogOpen(true);
                                        return;
                                    }
                                    setSelectExistingContactAnchorEl(e.currentTarget);
                                }}
                            >
                                <Stack gap={2}>
                                    {(contacts.data ?? [])
                                        .filter((c) => contract.contactPersonIds.includes(c.id))
                                        .map((contact) => (
                                            <ContactPerson
                                                firstName={contact.firstName}
                                                lastName={contact.lastName}
                                                profilePicture={contact.profilePicture}
                                                edit={edit}
                                                onRemove={async () => {
                                                    setContract({
                                                        ...contract,
                                                        contactPersonIds: contract.contactPersonIds.filter(
                                                            (id) => id !== contact.id
                                                        ),
                                                    });
                                                }}
                                                onEdit={async (values) => {
                                                    await editContact(contact.id, values);
                                                    contacts.refetch();
                                                }}
                                                key={contact.id}
                                                email={contact.email}
                                                phone={contact.phone}
                                            />
                                        ))}
                                    {contract.contactPersonIds.length === 0 && (
                                        <EmtpyCardContent
                                            title={formatMessage({ defaultMessage: "No contact persons" })}
                                            subtitle={formatMessage({
                                                defaultMessage: "You can add contact persons by editing this contract.",
                                            })}
                                        />
                                    )}
                                    <RiddleMenu
                                        entries={[
                                            {
                                                label: formatMessage({ defaultMessage: "Create new contact" }),
                                                divider: true,
                                                icon: { component: <Plus />, pos: "start" },
                                                onClick: () => {
                                                    setAddContactDialogOpen(true);
                                                    setSelectExistingContactAnchorEl(null);
                                                },
                                            },
                                            ...(contacts.data ?? []).map((contact) => ({
                                                label: `${contact.firstName} ${contact.lastName}`,
                                                disabled: contract.contactPersonIds.includes(contact.id),
                                                onClick: () => {
                                                    setContract({
                                                        ...contract,
                                                        contactPersonIds: [...contract.contactPersonIds, contact.id],
                                                    });
                                                    setSelectExistingContactAnchorEl(null);
                                                },
                                            })),
                                        ]}
                                        sx={{ width: 240 }}
                                        onClose={() => {
                                            setSelectExistingContactAnchorEl(null);
                                        }}
                                        anchorEl={selectExistingContactAnchorEl}
                                    />
                                    <EditContactPersonDialog
                                        open={addContactDialogOpen}
                                        onClose={() => {
                                            setAddContactDialogOpen(false);
                                        }}
                                        onEdit={async (values) => {
                                            if (contract.supplierId === null) {
                                                return;
                                            }
                                            const id = await addContact(contract.supplierId, values);
                                            if (!id) {
                                                return;
                                            }
                                            await contacts.refetch();
                                            setContract({
                                                ...contract,
                                                contactPersonIds: [...contract.contactPersonIds, id],
                                            });
                                        }}
                                    />
                                </Stack>
                            </SidebarCard>

                            <SidebarCard label={formatMessage({ defaultMessage: "Documents" })}>
                                <FileAttachments
                                    upload={async (file) => {
                                        await attachments.upload(file);
                                        await attachments.refetch();
                                    }}
                                    edit={edit}
                                    canEdit={canEdit}
                                    attachments={attachments.data.filter((att) => att.url !== null)}
                                    onDelete={(fileId) => attachments.delete(fileId)}
                                    onToggleVisibility={(fileId, checked) =>
                                        attachments.toggleVisibility(fileId, checked)
                                    }
                                />
                            </SidebarCard>

                            <SidebarCard
                                edit={edit}
                                label={formatMessage({ defaultMessage: "Alerts" })}
                                editLabel={formatMessage({ defaultMessage: "Add new" })}
                                onClickEdit={() => {
                                    setAlertModalOpen(true);
                                }}
                            >
                                <Stack gap={2.5}>
                                    {(alerts.data || []).map((al) => (
                                        <Stack direction="row" alignItems="center" key={al.id}>
                                            <Stack>
                                                <Typography variant="textSm" fontWeight={500}>
                                                    {al.message}
                                                </Typography>
                                                <Typography variant="textSm" color="GrayText" fontWeight={400}>
                                                    {dayjs(al.scheduledOn).format("DD MMM YYYY")}
                                                </Typography>
                                            </Stack>
                                            <IconButton
                                                sx={{ ml: "auto", visibility: edit ? "visible" : "hidden" }}
                                                onClick={() =>
                                                    deleteAlert(contract.id, al.id).then(() => alerts.refetch())
                                                }
                                            >
                                                <Trash />
                                            </IconButton>
                                        </Stack>
                                    ))}
                                    <AddAlertPopupV2
                                        open={alertModalOpen}
                                        onClose={() => {
                                            setAlertModalOpen(false);
                                        }}
                                        onSubmit={async (message, scheduledOn) => {
                                            return apiPost(`contracts/${id}/reminders`, {
                                                scheduledOn,
                                                message,
                                                refId: id,
                                            }).then(() => {
                                                alerts.refetch();
                                                setAlertModalOpen(false);
                                            });
                                        }}
                                    />
                                </Stack>
                            </SidebarCard>
                            <SidebarCard label={formatMessage({ defaultMessage: "Notes" })}>
                                <NotesV2
                                    notes={notes.notes}
                                    onUpdate={(id, text) => notes.update(id, text)}
                                    onCreate={(text) => notes.add(text)}
                                    onDelete={(id) => notes.delete(id)}
                                />
                            </SidebarCard>
                        </Stack>
                    </Grid>
                </Grid>
            </Stack>
        </Box>
    );
};
