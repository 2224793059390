import { Plus } from "@ignite-analytics/icons";
import { Stack } from "@mui/material";
import { keepPreviousData, useQuery } from "@tanstack/react-query";
import { useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import { EmtpyCardContent } from "@/components/EmptyCardContent";
import { RiddleMenu } from "@/components/ui/Menu";
import { ContactPerson, EditContactPersonDialog } from "@/components/UserDisplay/Row";
import { useSessionContext } from "@/contexts/SessionContext";
import { addContact, editContact, getContactsBySupplierId } from "@/hooks/supplierContacts";

import { Contract } from "@/types";
import { SidebarCard } from "./SidebarCard";
import { detailRoute } from "@/routes/detail/$id";

export interface ContractContactCardProps {
    contract: Contract;
    editMode: boolean;
    onContractChange: (contract: Contract) => void;
}

export const ContractContactCard: React.FC<ContractContactCardProps> = ({ contract, editMode, onContractChange }) => {
    const [addContactDialogOpen, setAddContactDialogOpen] = useState(false);
    const [selectExistingContactAnchorEl, setSelectExistingContactAnchorEl] = useState<HTMLElement | null>(null);

    const { id } = detailRoute.useParams();
    const { formatMessage } = useIntl();
    const { tenant } = useSessionContext();

    const contacts = useQuery({
        placeholderData: keepPreviousData,
        enabled: true,
        queryKey: [tenant, "contracts", id, "contacts", contract.supplierId, "t"],
        queryFn: () => getContactsBySupplierId(contract.supplierId),
        staleTime: 1000 * 1,
    });

    return (
        <SidebarCard
            label={formatMessage({ defaultMessage: "Contact persons" })}
            editLabel={formatMessage({ defaultMessage: "Add new" })}
            edit={editMode}
            editDisabled={contract.supplierId === null}
            editDisabledTooltip={
                <FormattedMessage defaultMessage="You must set a supplier for the contract to add a contact person" />
            }
            onClickEdit={(e) => {
                if (contacts.data?.length === 0) {
                    // no point in showing the menu of contacts if there are none
                    // --> shortcut to add contact dialog
                    setAddContactDialogOpen(true);
                    return;
                }
                setSelectExistingContactAnchorEl(e.currentTarget);
            }}
        >
            <Stack gap={2}>
                {(contacts.data ?? [])
                    .filter((c) => contract.contactPersonIds.includes(c.id))
                    .map((contact) => (
                        <ContactPerson
                            firstName={contact.firstName}
                            lastName={contact.lastName}
                            profilePicture={contact.profilePicture}
                            edit={editMode}
                            onRemove={async () => {
                                onContractChange({
                                    ...contract,
                                    contactPersonIds: contract.contactPersonIds.filter((id) => id !== contact.id),
                                });
                            }}
                            onEdit={async (values) => {
                                await editContact(contact.id, values);
                                contacts.refetch();
                            }}
                            key={contact.id}
                            email={contact.email}
                            phone={contact.phone}
                        />
                    ))}
                {contract.contactPersonIds.length === 0 && (
                    <EmtpyCardContent
                        title={formatMessage({ defaultMessage: "No contact persons" })}
                        subtitle={formatMessage({
                            defaultMessage: "You can add contact persons by editing this contract.",
                        })}
                    />
                )}
                <RiddleMenu
                    entries={[
                        {
                            label: formatMessage({ defaultMessage: "Create new contact" }),
                            divider: true,
                            icon: { component: <Plus />, pos: "start" },
                            onClick: () => {
                                setAddContactDialogOpen(true);
                                setSelectExistingContactAnchorEl(null);
                            },
                        },
                        ...(contacts.data ?? []).map((contact) => ({
                            label: `${contact.firstName} ${contact.lastName}`,
                            disabled: contract.contactPersonIds.includes(contact.id),
                            onClick: () => {
                                onContractChange({
                                    ...contract,
                                    contactPersonIds: [...contract.contactPersonIds, contact.id],
                                });
                                setSelectExistingContactAnchorEl(null);
                            },
                        })),
                    ]}
                    sx={{ width: 240 }}
                    onClose={() => {
                        setSelectExistingContactAnchorEl(null);
                    }}
                    anchorEl={selectExistingContactAnchorEl}
                />
                <EditContactPersonDialog
                    open={addContactDialogOpen}
                    onClose={() => {
                        setAddContactDialogOpen(false);
                    }}
                    onEdit={async (values) => {
                        if (contract.supplierId === null) {
                            return;
                        }
                        const id = await addContact(contract.supplierId, values);
                        if (!id) {
                            return;
                        }
                        await contacts.refetch();
                        onContractChange({
                            ...contract,
                            contactPersonIds: [...contract.contactPersonIds, id],
                        });
                    }}
                />
            </Stack>
        </SidebarCard>
    );
};
