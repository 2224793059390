import { useIntl } from "react-intl";
import { useCallback, useMemo } from "react";
import { AccessLevel, AccessLevels } from "./types";
import { useErrorHandler } from "@/errorHandling/ErrorHandlerContext";
import { useSnackbar } from "@/contexts/useSnackbar";
import { makeContractsApiHttp } from "@/http";

export function useSavePermissions(onClose: () => void, refetchContracts: () => void) {
    const { handleError } = useErrorHandler();
    const { postSnackbar } = useSnackbar();
    const { formatMessage } = useIntl();
    const http = useMemo(() => makeContractsApiHttp(), []);
    const onSave = useCallback(
        (contractIds: string[], access: AccessLevel) => {
            http.patch(`/contracts/access/bulk`, {
                operations: contractIds.map((contractId) => ({
                    path: `${contractId}/isPrivate`,
                    operation: "replace",
                    value: access === AccessLevels.RESTRICTED,
                })),
            })
                .then(() => {
                    onClose();
                    refetchContracts();
                    postSnackbar({ message: formatMessage({ defaultMessage: "Access was successfully updated" }) });
                })
                .catch(handleError);
        },
        [http, onClose, refetchContracts, postSnackbar, formatMessage, handleError]
    );

    return onSave;
}
