/* eslint-disable */
import * as types from './graphql';
import type { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';

/**
 * Map of all GraphQL operations in the project.
 *
 * This map has several performance disadvantages:
 * 1. It is not tree-shakeable, so it will include all operations in the project.
 * 2. It is not minifiable, so the string of a GraphQL query will be multiple times inside the bundle.
 * 3. It does not support dead code elimination, so it will add unused operations.
 *
 * Therefore it is highly recommended to use the babel or swc plugin for production.
 */
const documents = {
    "\n    query GetContractStatistics($input: GetContractStatisticsInput!) {\n        getContractStatistics(input: $input) {\n            active {\n                totalSpend\n                count\n            }\n            expiring {\n                totalSpend\n                count\n            }\n            renewing {\n                totalSpend\n                count\n            }\n        }\n    }\n": types.GetContractStatisticsDocument,
    "\n    query contracts_searchSuppliers($input: GetSuppliersInput!) {\n        getSuppliers(input: $input) {\n            suppliers {\n                id\n                name\n            }\n        }\n    }\n": types.Contracts_SearchSuppliersDocument,
    "\n    query CompanyCurrencyContext_GetCurrentCustomer {\n        getCurrentCustomer {\n            currency\n        }\n    }\n": types.CompanyCurrencyContext_GetCurrentCustomerDocument,
    "\n    query getUsers_contracts {\n        getUsers {\n            result {\n                id\n                firstName\n                lastName\n                email\n                roles\n            }\n        }\n    }\n": types.GetUsers_ContractsDocument,
    "\n    query Contacts_GetContactsBySupplierId($input: GetContactsBySupplierIdInput!) {\n        getContactsBySupplierId(input: $input) {\n            result {\n                contacts {\n                    id\n                    firstName\n                    lastName\n                    email\n                    phone {\n                        number\n                    }\n                }\n            }\n        }\n    }\n": types.Contacts_GetContactsBySupplierIdDocument,
    "\n    mutation UpsertContact($input: UpsertContactsInput!) {\n        upsertContacts(input: $input) {\n            result {\n                id\n                firstName\n                lastName\n                email\n                phone {\n                    number\n                }\n            }\n        }\n    }\n": types.UpsertContactDocument,
    "\n    query GetContactById($input: GetContactsInput!) {\n        getContacts(input: $input) {\n            result {\n                id\n                firstName\n                lastName\n                email\n                phone {\n                    number\n                }\n            }\n        }\n    }\n": types.GetContactByIdDocument,
    "\n        query getPivot($input: GetPivotInput!) {\n            getPivot(input: $input) {\n                pivotResponse\n            }\n        }\n    ": types.GetPivotDocument,
    "\n    query getSupplier($input: GetSupplierInput!) {\n        getSupplier(input: $input) {\n            supplier {\n                id\n                name\n            }\n        }\n    }\n": types.GetSupplierDocument,
    "\n    query getGroup($input: GetGroupInput!) {\n        getGroup(input: $input) {\n            group {\n                id\n                name\n            }\n        }\n    }\n": types.GetGroupDocument,
    "\n    query getGroupsByGroupStructure($input: GetGroupsByGroupStructureInput!) {\n        getGroupsByGroupStructure(input: $input) {\n            groups {\n                id\n                name\n                parentId\n                groupStructureId\n                level\n                hasChildren\n            }\n        }\n    }\n": types.GetGroupsByGroupStructureDocument,
    "\n    query getGroupPath($input: GetParentsByChildIdInput!) {\n        getParentsByChildId(input: $input) {\n            groups {\n                id\n                name\n            }\n        }\n    }\n": types.GetGroupPathDocument,
    "\n    query getTagValuesByColumnId($input: GetTagValuesByColumnIdInput!) {\n        getTagValuesByColumnId(input: $input) {\n            tagValues {\n                value\n            }\n        }\n    }\n": types.GetTagValuesByColumnIdDocument,
    "\n    mutation addTagValueToColumn($input: AddTagValueToColumnInput!) {\n        addTagValueToColumn(input: $input) {\n            tag {\n                id\n            }\n        }\n    }\n": types.AddTagValueToColumnDocument,
    "\n    query contracts_list($input: GetContractsInput) {\n        getContracts(input: $input) {\n            data {\n                id\n                title\n                supplier {\n                    id\n                    name\n                }\n                startDate\n                endDate\n                renewalDate\n                responsibles {\n                    id\n                    firstName\n                    lastName\n                    email\n                }\n                totalSpend\n                customFields {\n                    id\n                    name\n                    dataType\n                    data\n                }\n            }\n        }\n    }\n": types.Contracts_ListDocument,
};

/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 *
 *
 * @example
 * ```ts
 * const query = graphql(`query GetUser($id: ID!) { user(id: $id) { name } }`);
 * ```
 *
 * The query argument is unknown!
 * Please regenerate the types.
 */
export function graphql(source: string): unknown;

/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n    query GetContractStatistics($input: GetContractStatisticsInput!) {\n        getContractStatistics(input: $input) {\n            active {\n                totalSpend\n                count\n            }\n            expiring {\n                totalSpend\n                count\n            }\n            renewing {\n                totalSpend\n                count\n            }\n        }\n    }\n"): (typeof documents)["\n    query GetContractStatistics($input: GetContractStatisticsInput!) {\n        getContractStatistics(input: $input) {\n            active {\n                totalSpend\n                count\n            }\n            expiring {\n                totalSpend\n                count\n            }\n            renewing {\n                totalSpend\n                count\n            }\n        }\n    }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n    query contracts_searchSuppliers($input: GetSuppliersInput!) {\n        getSuppliers(input: $input) {\n            suppliers {\n                id\n                name\n            }\n        }\n    }\n"): (typeof documents)["\n    query contracts_searchSuppliers($input: GetSuppliersInput!) {\n        getSuppliers(input: $input) {\n            suppliers {\n                id\n                name\n            }\n        }\n    }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n    query CompanyCurrencyContext_GetCurrentCustomer {\n        getCurrentCustomer {\n            currency\n        }\n    }\n"): (typeof documents)["\n    query CompanyCurrencyContext_GetCurrentCustomer {\n        getCurrentCustomer {\n            currency\n        }\n    }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n    query getUsers_contracts {\n        getUsers {\n            result {\n                id\n                firstName\n                lastName\n                email\n                roles\n            }\n        }\n    }\n"): (typeof documents)["\n    query getUsers_contracts {\n        getUsers {\n            result {\n                id\n                firstName\n                lastName\n                email\n                roles\n            }\n        }\n    }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n    query Contacts_GetContactsBySupplierId($input: GetContactsBySupplierIdInput!) {\n        getContactsBySupplierId(input: $input) {\n            result {\n                contacts {\n                    id\n                    firstName\n                    lastName\n                    email\n                    phone {\n                        number\n                    }\n                }\n            }\n        }\n    }\n"): (typeof documents)["\n    query Contacts_GetContactsBySupplierId($input: GetContactsBySupplierIdInput!) {\n        getContactsBySupplierId(input: $input) {\n            result {\n                contacts {\n                    id\n                    firstName\n                    lastName\n                    email\n                    phone {\n                        number\n                    }\n                }\n            }\n        }\n    }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n    mutation UpsertContact($input: UpsertContactsInput!) {\n        upsertContacts(input: $input) {\n            result {\n                id\n                firstName\n                lastName\n                email\n                phone {\n                    number\n                }\n            }\n        }\n    }\n"): (typeof documents)["\n    mutation UpsertContact($input: UpsertContactsInput!) {\n        upsertContacts(input: $input) {\n            result {\n                id\n                firstName\n                lastName\n                email\n                phone {\n                    number\n                }\n            }\n        }\n    }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n    query GetContactById($input: GetContactsInput!) {\n        getContacts(input: $input) {\n            result {\n                id\n                firstName\n                lastName\n                email\n                phone {\n                    number\n                }\n            }\n        }\n    }\n"): (typeof documents)["\n    query GetContactById($input: GetContactsInput!) {\n        getContacts(input: $input) {\n            result {\n                id\n                firstName\n                lastName\n                email\n                phone {\n                    number\n                }\n            }\n        }\n    }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n        query getPivot($input: GetPivotInput!) {\n            getPivot(input: $input) {\n                pivotResponse\n            }\n        }\n    "): (typeof documents)["\n        query getPivot($input: GetPivotInput!) {\n            getPivot(input: $input) {\n                pivotResponse\n            }\n        }\n    "];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n    query getSupplier($input: GetSupplierInput!) {\n        getSupplier(input: $input) {\n            supplier {\n                id\n                name\n            }\n        }\n    }\n"): (typeof documents)["\n    query getSupplier($input: GetSupplierInput!) {\n        getSupplier(input: $input) {\n            supplier {\n                id\n                name\n            }\n        }\n    }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n    query getGroup($input: GetGroupInput!) {\n        getGroup(input: $input) {\n            group {\n                id\n                name\n            }\n        }\n    }\n"): (typeof documents)["\n    query getGroup($input: GetGroupInput!) {\n        getGroup(input: $input) {\n            group {\n                id\n                name\n            }\n        }\n    }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n    query getGroupsByGroupStructure($input: GetGroupsByGroupStructureInput!) {\n        getGroupsByGroupStructure(input: $input) {\n            groups {\n                id\n                name\n                parentId\n                groupStructureId\n                level\n                hasChildren\n            }\n        }\n    }\n"): (typeof documents)["\n    query getGroupsByGroupStructure($input: GetGroupsByGroupStructureInput!) {\n        getGroupsByGroupStructure(input: $input) {\n            groups {\n                id\n                name\n                parentId\n                groupStructureId\n                level\n                hasChildren\n            }\n        }\n    }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n    query getGroupPath($input: GetParentsByChildIdInput!) {\n        getParentsByChildId(input: $input) {\n            groups {\n                id\n                name\n            }\n        }\n    }\n"): (typeof documents)["\n    query getGroupPath($input: GetParentsByChildIdInput!) {\n        getParentsByChildId(input: $input) {\n            groups {\n                id\n                name\n            }\n        }\n    }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n    query getTagValuesByColumnId($input: GetTagValuesByColumnIdInput!) {\n        getTagValuesByColumnId(input: $input) {\n            tagValues {\n                value\n            }\n        }\n    }\n"): (typeof documents)["\n    query getTagValuesByColumnId($input: GetTagValuesByColumnIdInput!) {\n        getTagValuesByColumnId(input: $input) {\n            tagValues {\n                value\n            }\n        }\n    }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n    mutation addTagValueToColumn($input: AddTagValueToColumnInput!) {\n        addTagValueToColumn(input: $input) {\n            tag {\n                id\n            }\n        }\n    }\n"): (typeof documents)["\n    mutation addTagValueToColumn($input: AddTagValueToColumnInput!) {\n        addTagValueToColumn(input: $input) {\n            tag {\n                id\n            }\n        }\n    }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n    query contracts_list($input: GetContractsInput) {\n        getContracts(input: $input) {\n            data {\n                id\n                title\n                supplier {\n                    id\n                    name\n                }\n                startDate\n                endDate\n                renewalDate\n                responsibles {\n                    id\n                    firstName\n                    lastName\n                    email\n                }\n                totalSpend\n                customFields {\n                    id\n                    name\n                    dataType\n                    data\n                }\n            }\n        }\n    }\n"): (typeof documents)["\n    query contracts_list($input: GetContractsInput) {\n        getContracts(input: $input) {\n            data {\n                id\n                title\n                supplier {\n                    id\n                    name\n                }\n                startDate\n                endDate\n                renewalDate\n                responsibles {\n                    id\n                    firstName\n                    lastName\n                    email\n                }\n                totalSpend\n                customFields {\n                    id\n                    name\n                    dataType\n                    data\n                }\n            }\n        }\n    }\n"];

export function graphql(source: string) {
  return (documents as any)[source] ?? {};
}

export type DocumentType<TDocumentNode extends DocumentNode<any, any>> = TDocumentNode extends DocumentNode<  infer TType,  any>  ? TType  : never;