import { Container, ContainerProps, styled } from "@mui/material";

const AppLayoutContainer = styled(Container)(({ theme }) => ({
    "*:where(html:not([data-new-layout])) &": {
        overflow: "auto",
        position: "absolute",
        top: 0,
        bottom: 0,
        right: 0,
        left: 0,
        display: "flex",
        flexDirection: "column",
        padding: theme.spacing(0, 4, 2, 4),
    },
    "*:where(html[data-new-layout]) &": {
        flex: "1 1 calc(100vh - var(--appbar-height))",
        display: "flex",
        flexDirection: "column",
        minHeight: 0,
        paddingBottom: theme.spacing(4),
        paddingTop: theme.spacing(2),
    },
}));

/**
 * App Layout should never grow beyond the size of the screen, except for special cases where the screen is extremely small.
 * It should automatically fill, but never exceed, the available height. The parent element is a flex container.
 */
const AppLayout: React.FC<ContainerProps> = (props) => {
    return <AppLayoutContainer {...props} maxWidth={false} disableGutters={false} />;
};

export { AppLayout };
