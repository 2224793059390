import { Button, Collapse, Stack } from "@mui/material";
import { FormattedMessage } from "react-intl";

export interface AddPeopleButtonProps {
    onClick: () => void;
    visible: boolean;
}

export const AddPeopleButton: React.FC<AddPeopleButtonProps> = ({ onClick, visible }) => {
    return (
        <Stack>
            <Collapse in={visible} orientation="horizontal" collapsedSize={0}>
                <Button
                    onClick={onClick}
                    variant="contained"
                    color="primary"
                    disabled={!visible}
                    sx={{ whiteSpace: "nowrap" }}
                >
                    <FormattedMessage defaultMessage="Add people" />
                </Button>
            </Collapse>
        </Stack>
    );
};
