import { Search, ChevronLeft } from "@ignite-analytics/icons";
import { Collapse, IconButton, Stack, TextField } from "@mui/material";
import { AddPeopleButton } from "./AddPeopleButton";

export interface SearchUsersProps {
    search: string;
    showGoBackButton: boolean;
    addPeopleButtonVisible: boolean;
    inputRef: React.RefObject<HTMLInputElement>;
    onClose: () => void;
    onFocus: () => void;
    onAddUsers: () => void;
    onSearch: (search: string) => void;
}

export const SearchUsers: React.FC<SearchUsersProps> = ({
    search,
    showGoBackButton,
    addPeopleButtonVisible,
    inputRef,
    onClose,
    onFocus,
    onAddUsers,
    onSearch,
}) => {
    return (
        <Stack direction="row" alignItems="center" spacing={1}>
            <Collapse in={showGoBackButton} orientation="horizontal" collapsedSize={0}>
                <IconButton onClick={onClose}>
                    <ChevronLeft />
                </IconButton>
            </Collapse>
            <TextField
                placeholder="Add users"
                slotProps={{ input: { startAdornment: <Search /> } }}
                value={search}
                onChange={(e) => onSearch(e.target.value)}
                fullWidth
                inputRef={inputRef}
                onFocus={onFocus}
            />
            <AddPeopleButton onClick={onAddUsers} visible={addPeopleButtonVisible} />
        </Stack>
    );
};
