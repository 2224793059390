import { MessageFormatElement } from "react-intl";
import localeDataEnUS from "./compiled/en-US.json";
import localeDataNbNO from "./compiled/nb-NO.json";

type LocaleData = Record<string, MessageFormatElement[]>;

export const getMessages = (locale: string): LocaleData => {
    switch (locale) {
        case "nb-NO":
            return localeDataNbNO;
        case "en-US":
            return localeDataEnUS;
        default:
            return localeDataEnUS;
    }
};
