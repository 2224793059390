/* eslint-disable */
import * as types from './graphql';
import type { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';

/**
 * Map of all GraphQL operations in the project.
 *
 * This map has several performance disadvantages:
 * 1. It is not tree-shakeable, so it will include all operations in the project.
 * 2. It is not minifiable, so the string of a GraphQL query will be multiple times inside the bundle.
 * 3. It does not support dead code elimination, so it will add unused operations.
 *
 * Therefore it is highly recommended to use the babel or swc plugin for production.
 * Learn more about it here: https://the-guild.dev/graphql/codegen/plugins/presets/preset-client#reducing-bundle-size
 */
const documents = {
    "\n    query contracts_askDocumentQuestion($input: AskDocumentQuestionRequest!) {\n        askDocumentQuestion(input: $input) {\n            answer\n        }\n    }\n": types.Contracts_AskDocumentQuestionDocument,
    "\n    mutation deleteContracts($ids: [ID!]!) {\n        deleteContracts(ids: $ids) {\n            deletedIds\n        }\n    }\n": types.DeleteContractsDocument,
    "\n    mutation bulkAddContractResponsibles($input: BulkAddContractResponsiblesRequest!) {\n        bulkAddContractResponsibles(input: $input) {\n            contractIds\n        }\n    }\n": types.BulkAddContractResponsiblesDocument,
    "\n    mutation bulkCreateReminders($input: BulkCreateRemindersRequest!) {\n        bulkCreateReminders(input: $input) {\n            reminderIds\n        }\n    }\n": types.BulkCreateRemindersDocument,
    "\n    mutation EditRestrictedAccess_UpdateContractPermissions($input: UpdateContractPermissionsInput!) {\n        updateContractPermissions(input: $input) {\n            count\n        }\n    }\n": types.EditRestrictedAccess_UpdateContractPermissionsDocument,
    "\n    query UseGetSupplierTableRowsByNameSearch_SupplierNameSearch($input: GetSuppliersInput!) {\n        getSuppliers(input: $input) {\n            suppliers {\n                id\n                name\n            }\n        }\n    }\n": types.UseGetSupplierTableRowsByNameSearch_SupplierNameSearchDocument,
    "\n    query GroupSelect_GetGroupsByGroupStructure($input: GetAllGroupsByGroupStructureInput!) {\n        getAllGroupsByGroupStructure(input: $input) {\n            groups {\n                id\n                level\n                name\n                parentId\n            }\n        }\n    }\n": types.GroupSelect_GetGroupsByGroupStructureDocument,
    "\n    query CompanyCurrencyContext_GetCurrentCustomer {\n        getCurrentCustomer {\n            currency\n        }\n    }\n": types.CompanyCurrencyContext_GetCurrentCustomerDocument,
    "\n    query getUsers_contracts {\n        getUsers {\n            result {\n                id\n                firstName\n                lastName\n                email\n                roles\n            }\n        }\n    }\n": types.GetUsers_ContractsDocument,
    "\n    query Contacts_GetContactsBySupplierId($input: GetContactsBySupplierIdInput!) {\n        getContactsBySupplierId(input: $input) {\n            result {\n                contacts {\n                    id\n                    firstName\n                    lastName\n                    email\n                    phone {\n                        number\n                    }\n                }\n            }\n        }\n    }\n": types.Contacts_GetContactsBySupplierIdDocument,
    "\n    mutation UpsertContact($input: UpsertContactsInput!) {\n        upsertContacts(input: $input) {\n            result {\n                id\n                firstName\n                lastName\n                email\n                phone {\n                    number\n                }\n            }\n        }\n    }\n": types.UpsertContactDocument,
    "\n    query GetContactById($input: GetContactsInput!) {\n        getContacts(input: $input) {\n            result {\n                id\n                firstName\n                lastName\n                email\n                phone {\n                    number\n                }\n            }\n        }\n    }\n": types.GetContactByIdDocument,
    "\n        query getPivot($input: GetPivotInput!) {\n            getPivot(input: $input) {\n                pivotResponse\n            }\n        }\n    ": types.GetPivotDocument,
    "\n    query UseContractPermissions_GetContractPermissions($input: GetContractPermissionsInput!) {\n        getContractPermissions(input: $input) {\n            contractPermissions {\n                user {\n                    id\n                    firstName\n                    lastName\n                    email\n                }\n                roleID\n                reads {\n                    contractId\n                    isResponsible\n                    grantedReadAccess\n                }\n                writes {\n                    contractId\n                    isResponsible\n                    grantedReadAccess\n                }\n            }\n        }\n    }\n": types.UseContractPermissions_GetContractPermissionsDocument,
    "\n    query getSupplier($input: GetSupplierInput!) {\n        getSupplier(input: $input) {\n            supplier {\n                id\n                name\n            }\n        }\n    }\n": types.GetSupplierDocument,
    "\n    query getGroup($input: GetGroupInput!) {\n        getGroup(input: $input) {\n            group {\n                id\n                name\n            }\n        }\n    }\n": types.GetGroupDocument,
    "\n    query getGroupsByGroupStructure($input: GetGroupsByGroupStructureInput!) {\n        getGroupsByGroupStructure(input: $input) {\n            groups {\n                id\n                name\n                parentId\n                groupStructureId\n                level\n                hasChildren\n            }\n        }\n    }\n": types.GetGroupsByGroupStructureDocument,
    "\n    query getGroupPath($input: GetParentsByChildIdInput!) {\n        getParentsByChildId(input: $input) {\n            groups {\n                id\n                name\n            }\n        }\n    }\n": types.GetGroupPathDocument,
    "\n    query getTagValuesByColumnId($input: GetTagValuesByColumnIdInput!) {\n        getTagValuesByColumnId(input: $input) {\n            tagValues {\n                value\n            }\n        }\n    }\n": types.GetTagValuesByColumnIdDocument,
    "\n    mutation addTagValueToColumn($input: AddTagValueToColumnInput!) {\n        addTagValueToColumn(input: $input) {\n            tag {\n                id\n            }\n        }\n    }\n": types.AddTagValueToColumnDocument,
    "\n    query contracts_list($input: GetContractsInput) {\n        getContracts(input: $input) {\n            data {\n                id\n                title\n                supplier {\n                    id\n                    name\n                }\n                startDate\n                endDate\n                renewalDate\n                responsibles {\n                    id\n                    firstName\n                    lastName\n                    email\n                }\n                totalSpend\n                customFields {\n                    id\n                    name\n                    dataType\n                    data\n                }\n            }\n        }\n    }\n": types.Contracts_ListDocument,
    "\n    fragment TotalOverviewCards_Contract on Contract {\n        id\n        startDate\n        endDate\n        totalSpend\n    }\n": types.TotalOverviewCards_ContractFragmentDoc,
    "\n    fragment TotalOverview_Contract on Contract {\n        id\n        title\n        startDate\n        endDate\n        supplier {\n            id\n            name\n        }\n        responsibles {\n            id\n            firstName\n            lastName\n        }\n    }\n": types.TotalOverview_ContractFragmentDoc,
    "\n    query TotalOverview_GetContracts($input: GetContractsInput) {\n        getContracts(input: $input) {\n            data {\n                ...TotalOverview_Contract\n                ...TotalOverviewCards_Contract\n            }\n            total\n        }\n    }\n": types.TotalOverview_GetContractsDocument,
};

/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 *
 *
 * @example
 * ```ts
 * const query = graphql(`query GetUser($id: ID!) { user(id: $id) { name } }`);
 * ```
 *
 * The query argument is unknown!
 * Please regenerate the types.
 */
export function graphql(source: string): unknown;

/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n    query contracts_askDocumentQuestion($input: AskDocumentQuestionRequest!) {\n        askDocumentQuestion(input: $input) {\n            answer\n        }\n    }\n"): (typeof documents)["\n    query contracts_askDocumentQuestion($input: AskDocumentQuestionRequest!) {\n        askDocumentQuestion(input: $input) {\n            answer\n        }\n    }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n    mutation deleteContracts($ids: [ID!]!) {\n        deleteContracts(ids: $ids) {\n            deletedIds\n        }\n    }\n"): (typeof documents)["\n    mutation deleteContracts($ids: [ID!]!) {\n        deleteContracts(ids: $ids) {\n            deletedIds\n        }\n    }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n    mutation bulkAddContractResponsibles($input: BulkAddContractResponsiblesRequest!) {\n        bulkAddContractResponsibles(input: $input) {\n            contractIds\n        }\n    }\n"): (typeof documents)["\n    mutation bulkAddContractResponsibles($input: BulkAddContractResponsiblesRequest!) {\n        bulkAddContractResponsibles(input: $input) {\n            contractIds\n        }\n    }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n    mutation bulkCreateReminders($input: BulkCreateRemindersRequest!) {\n        bulkCreateReminders(input: $input) {\n            reminderIds\n        }\n    }\n"): (typeof documents)["\n    mutation bulkCreateReminders($input: BulkCreateRemindersRequest!) {\n        bulkCreateReminders(input: $input) {\n            reminderIds\n        }\n    }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n    mutation EditRestrictedAccess_UpdateContractPermissions($input: UpdateContractPermissionsInput!) {\n        updateContractPermissions(input: $input) {\n            count\n        }\n    }\n"): (typeof documents)["\n    mutation EditRestrictedAccess_UpdateContractPermissions($input: UpdateContractPermissionsInput!) {\n        updateContractPermissions(input: $input) {\n            count\n        }\n    }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n    query UseGetSupplierTableRowsByNameSearch_SupplierNameSearch($input: GetSuppliersInput!) {\n        getSuppliers(input: $input) {\n            suppliers {\n                id\n                name\n            }\n        }\n    }\n"): (typeof documents)["\n    query UseGetSupplierTableRowsByNameSearch_SupplierNameSearch($input: GetSuppliersInput!) {\n        getSuppliers(input: $input) {\n            suppliers {\n                id\n                name\n            }\n        }\n    }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n    query GroupSelect_GetGroupsByGroupStructure($input: GetAllGroupsByGroupStructureInput!) {\n        getAllGroupsByGroupStructure(input: $input) {\n            groups {\n                id\n                level\n                name\n                parentId\n            }\n        }\n    }\n"): (typeof documents)["\n    query GroupSelect_GetGroupsByGroupStructure($input: GetAllGroupsByGroupStructureInput!) {\n        getAllGroupsByGroupStructure(input: $input) {\n            groups {\n                id\n                level\n                name\n                parentId\n            }\n        }\n    }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n    query CompanyCurrencyContext_GetCurrentCustomer {\n        getCurrentCustomer {\n            currency\n        }\n    }\n"): (typeof documents)["\n    query CompanyCurrencyContext_GetCurrentCustomer {\n        getCurrentCustomer {\n            currency\n        }\n    }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n    query getUsers_contracts {\n        getUsers {\n            result {\n                id\n                firstName\n                lastName\n                email\n                roles\n            }\n        }\n    }\n"): (typeof documents)["\n    query getUsers_contracts {\n        getUsers {\n            result {\n                id\n                firstName\n                lastName\n                email\n                roles\n            }\n        }\n    }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n    query Contacts_GetContactsBySupplierId($input: GetContactsBySupplierIdInput!) {\n        getContactsBySupplierId(input: $input) {\n            result {\n                contacts {\n                    id\n                    firstName\n                    lastName\n                    email\n                    phone {\n                        number\n                    }\n                }\n            }\n        }\n    }\n"): (typeof documents)["\n    query Contacts_GetContactsBySupplierId($input: GetContactsBySupplierIdInput!) {\n        getContactsBySupplierId(input: $input) {\n            result {\n                contacts {\n                    id\n                    firstName\n                    lastName\n                    email\n                    phone {\n                        number\n                    }\n                }\n            }\n        }\n    }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n    mutation UpsertContact($input: UpsertContactsInput!) {\n        upsertContacts(input: $input) {\n            result {\n                id\n                firstName\n                lastName\n                email\n                phone {\n                    number\n                }\n            }\n        }\n    }\n"): (typeof documents)["\n    mutation UpsertContact($input: UpsertContactsInput!) {\n        upsertContacts(input: $input) {\n            result {\n                id\n                firstName\n                lastName\n                email\n                phone {\n                    number\n                }\n            }\n        }\n    }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n    query GetContactById($input: GetContactsInput!) {\n        getContacts(input: $input) {\n            result {\n                id\n                firstName\n                lastName\n                email\n                phone {\n                    number\n                }\n            }\n        }\n    }\n"): (typeof documents)["\n    query GetContactById($input: GetContactsInput!) {\n        getContacts(input: $input) {\n            result {\n                id\n                firstName\n                lastName\n                email\n                phone {\n                    number\n                }\n            }\n        }\n    }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n        query getPivot($input: GetPivotInput!) {\n            getPivot(input: $input) {\n                pivotResponse\n            }\n        }\n    "): (typeof documents)["\n        query getPivot($input: GetPivotInput!) {\n            getPivot(input: $input) {\n                pivotResponse\n            }\n        }\n    "];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n    query UseContractPermissions_GetContractPermissions($input: GetContractPermissionsInput!) {\n        getContractPermissions(input: $input) {\n            contractPermissions {\n                user {\n                    id\n                    firstName\n                    lastName\n                    email\n                }\n                roleID\n                reads {\n                    contractId\n                    isResponsible\n                    grantedReadAccess\n                }\n                writes {\n                    contractId\n                    isResponsible\n                    grantedReadAccess\n                }\n            }\n        }\n    }\n"): (typeof documents)["\n    query UseContractPermissions_GetContractPermissions($input: GetContractPermissionsInput!) {\n        getContractPermissions(input: $input) {\n            contractPermissions {\n                user {\n                    id\n                    firstName\n                    lastName\n                    email\n                }\n                roleID\n                reads {\n                    contractId\n                    isResponsible\n                    grantedReadAccess\n                }\n                writes {\n                    contractId\n                    isResponsible\n                    grantedReadAccess\n                }\n            }\n        }\n    }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n    query getSupplier($input: GetSupplierInput!) {\n        getSupplier(input: $input) {\n            supplier {\n                id\n                name\n            }\n        }\n    }\n"): (typeof documents)["\n    query getSupplier($input: GetSupplierInput!) {\n        getSupplier(input: $input) {\n            supplier {\n                id\n                name\n            }\n        }\n    }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n    query getGroup($input: GetGroupInput!) {\n        getGroup(input: $input) {\n            group {\n                id\n                name\n            }\n        }\n    }\n"): (typeof documents)["\n    query getGroup($input: GetGroupInput!) {\n        getGroup(input: $input) {\n            group {\n                id\n                name\n            }\n        }\n    }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n    query getGroupsByGroupStructure($input: GetGroupsByGroupStructureInput!) {\n        getGroupsByGroupStructure(input: $input) {\n            groups {\n                id\n                name\n                parentId\n                groupStructureId\n                level\n                hasChildren\n            }\n        }\n    }\n"): (typeof documents)["\n    query getGroupsByGroupStructure($input: GetGroupsByGroupStructureInput!) {\n        getGroupsByGroupStructure(input: $input) {\n            groups {\n                id\n                name\n                parentId\n                groupStructureId\n                level\n                hasChildren\n            }\n        }\n    }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n    query getGroupPath($input: GetParentsByChildIdInput!) {\n        getParentsByChildId(input: $input) {\n            groups {\n                id\n                name\n            }\n        }\n    }\n"): (typeof documents)["\n    query getGroupPath($input: GetParentsByChildIdInput!) {\n        getParentsByChildId(input: $input) {\n            groups {\n                id\n                name\n            }\n        }\n    }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n    query getTagValuesByColumnId($input: GetTagValuesByColumnIdInput!) {\n        getTagValuesByColumnId(input: $input) {\n            tagValues {\n                value\n            }\n        }\n    }\n"): (typeof documents)["\n    query getTagValuesByColumnId($input: GetTagValuesByColumnIdInput!) {\n        getTagValuesByColumnId(input: $input) {\n            tagValues {\n                value\n            }\n        }\n    }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n    mutation addTagValueToColumn($input: AddTagValueToColumnInput!) {\n        addTagValueToColumn(input: $input) {\n            tag {\n                id\n            }\n        }\n    }\n"): (typeof documents)["\n    mutation addTagValueToColumn($input: AddTagValueToColumnInput!) {\n        addTagValueToColumn(input: $input) {\n            tag {\n                id\n            }\n        }\n    }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n    query contracts_list($input: GetContractsInput) {\n        getContracts(input: $input) {\n            data {\n                id\n                title\n                supplier {\n                    id\n                    name\n                }\n                startDate\n                endDate\n                renewalDate\n                responsibles {\n                    id\n                    firstName\n                    lastName\n                    email\n                }\n                totalSpend\n                customFields {\n                    id\n                    name\n                    dataType\n                    data\n                }\n            }\n        }\n    }\n"): (typeof documents)["\n    query contracts_list($input: GetContractsInput) {\n        getContracts(input: $input) {\n            data {\n                id\n                title\n                supplier {\n                    id\n                    name\n                }\n                startDate\n                endDate\n                renewalDate\n                responsibles {\n                    id\n                    firstName\n                    lastName\n                    email\n                }\n                totalSpend\n                customFields {\n                    id\n                    name\n                    dataType\n                    data\n                }\n            }\n        }\n    }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n    fragment TotalOverviewCards_Contract on Contract {\n        id\n        startDate\n        endDate\n        totalSpend\n    }\n"): (typeof documents)["\n    fragment TotalOverviewCards_Contract on Contract {\n        id\n        startDate\n        endDate\n        totalSpend\n    }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n    fragment TotalOverview_Contract on Contract {\n        id\n        title\n        startDate\n        endDate\n        supplier {\n            id\n            name\n        }\n        responsibles {\n            id\n            firstName\n            lastName\n        }\n    }\n"): (typeof documents)["\n    fragment TotalOverview_Contract on Contract {\n        id\n        title\n        startDate\n        endDate\n        supplier {\n            id\n            name\n        }\n        responsibles {\n            id\n            firstName\n            lastName\n        }\n    }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n    query TotalOverview_GetContracts($input: GetContractsInput) {\n        getContracts(input: $input) {\n            data {\n                ...TotalOverview_Contract\n                ...TotalOverviewCards_Contract\n            }\n            total\n        }\n    }\n"): (typeof documents)["\n    query TotalOverview_GetContracts($input: GetContractsInput) {\n        getContracts(input: $input) {\n            data {\n                ...TotalOverview_Contract\n                ...TotalOverviewCards_Contract\n            }\n            total\n        }\n    }\n"];

export function graphql(source: string) {
  return (documents as any)[source] ?? {};
}

export type DocumentType<TDocumentNode extends DocumentNode<any, any>> = TDocumentNode extends DocumentNode<  infer TType,  any>  ? TType  : never;