import { useMemo } from "react";

import { useQuery } from "@apollo/client";
import { graphql } from "@/generated";
import { ContractPermissions, ContractPermissionSchema, User } from "./types";

export function searchMatch(user: User | null | undefined, search?: string) {
    if (!search) return true;
    const fullName = `${user?.firstName} ${user?.lastName}`.toLowerCase();
    return fullName.includes(search.toLowerCase());
}

const UseContractPermissions_GetContractPermissions = graphql(/* GraphQL */ `
    query UseContractPermissions_GetContractPermissions($input: GetContractPermissionsInput!) {
        getContractPermissions(input: $input) {
            contractPermissions {
                user {
                    id
                    firstName
                    lastName
                    email
                }
                roleID
                reads {
                    contractId
                    isResponsible
                    grantedReadAccess
                }
                writes {
                    contractId
                    isResponsible
                    grantedReadAccess
                }
            }
        }
    }
`);

export interface ContractPermissionsResult {
    permissions: ContractPermissions[];
    editPermissions: ContractPermissions[];
    readPermissions: ContractPermissions[];
    noPermissions: ContractPermissions[];
    loading: boolean;
    refetch: () => Promise<any>;
}

export function useContractPermissions(contractIds: string[], search?: string): ContractPermissionsResult {
    const { data, loading, refetch } = useQuery(UseContractPermissions_GetContractPermissions, {
        variables: {
            input: {
                contractIds,
            },
        },
    });

    const validPermissions = useMemo(
        () =>
            data?.getContractPermissions.contractPermissions
                .map((permission) => ContractPermissionSchema.safeParse(permission))
                .filter((result): result is { success: true; data: ContractPermissions } => result.success)
                .map((result) => result.data) ?? [],
        [data?.getContractPermissions.contractPermissions]
    );

    const { editPermissions, readPermissions, noPermissions } = useMemo(() => {
        const editPermissions: ContractPermissions[] = [];
        const readPermissions: ContractPermissions[] = [];
        const noPermissions: ContractPermissions[] = [];

        if (!validPermissions) return { editPermissions, readPermissions, noPermissions };
        validPermissions.forEach((permission) => {
            const user = permission.user;
            if (!user) return;
            if (permission.writes.length > 0 && searchMatch(user, search)) {
                editPermissions.push(permission);
                return;
            }
            if (permission.reads.length > 0 && searchMatch(permission?.user, search)) {
                readPermissions.push(permission);
                return;
            }
            if (searchMatch(permission?.user, search)) {
                noPermissions.push(permission);
            }
        });

        return { editPermissions, readPermissions, noPermissions };
    }, [validPermissions, search]);

    return {
        permissions: validPermissions,
        editPermissions,
        readPermissions,
        noPermissions,
        loading,
        refetch,
    };
}
