import { makeContractsApiHttp as http } from "src/http";
import { Contract, PatchContractCommand } from "@/types";

export async function getContractById(id: string) {
    return http()
        .get<Contract>(`/contracts/${id}`)
        .then((response) => response.data);
}

export async function patchContract(params: { id: string; data: PatchContractCommand[] }) {
    const { id, data } = params;
    await http().patch(`/contracts/${id}`, data);
    return getContractById(id);
}
