import { Query } from "@tanstack/react-query";

export function contractDetailQueryKey(tenant: string, id: string) {
    return [tenant, "contract", id];
}
export function supplierNameQueryKey(tenant: string, id: string) {
    return [tenant, "supplier", id];
}
export function contractListQueryKeyPredicate(query: Query) {
    return query.queryKey[1] === "contracts";
}
export function defaultLayoutQueryKey(tenant: string) {
    return [tenant, "layouts", "default"];
}
