import { gql, useQuery } from "@apollo/client";
import * as Sentry from "@sentry/react";
import { useEffect, useMemo, useState } from "react";
import { makeGraphqlRouter } from "src/http";
import { TENANT } from "src/localStorageKeys";
import { env } from "@/env";

const tenantFromLocalStorage = localStorage.getItem(TENANT);

const getUsersQuery = `
    query getUsers($input: GetUsersInput!) {
        getUsers(input: $input) {
            result {
                id
                firstName
                lastName
                email
                lastActivity
                roles
                scimmed
            }
        }
    }
`;

const getUsersDoc = gql`
    query getUsers_contracts {
        getUsers {
            result {
                id
                firstName
                lastName
                email
                roles
            }
        }
    }
`;

export type Contact = {
    id: string;
    firstName?: string;
    lastName?: string;
    email: string;
    phone?: string;
    profilePicture?: string;
};

/* eslint-enable camelcase */

export interface User {
    id: string;
    firstName: string;
    lastName: string;
    email: string;
    roles: string[];
    profilePicture?: string;
}

const userToContact = (user: User): Contact => ({
    id: user.id,
    email: user.email,
    firstName: user.firstName,
    lastName: user.lastName,
    profilePicture: user.profilePicture as string,
});

export function useUsers(userIds?: string[]): { loading: boolean; users: User[] } {
    const queryResult = useQuery(getUsersDoc);
    const users = useMemo(() => {
        const usersIdsSet = new Set(userIds);
        if (queryResult.data) {
            let matches = queryResult.data.getUsers.result;
            if (userIds !== undefined) {
                matches = matches.filter(({ id }: { id: any }) => usersIdsSet.has(id));
            }
            return matches;
        }
        return [];
    }, [queryResult.data, userIds]);
    if (queryResult.loading) {
        return { loading: true, users: [] };
    }
    if (queryResult.error) {
        Sentry.captureMessage("Failed to fetch users", { tags: { app: "contracts-app" } });
        return { loading: false, users: [] };
    }
    return {
        loading: false,
        users,
    };
}

export function useContacts(userIds?: string[]) {
    const { loading, users } = useUsers(userIds);
    const contacts = useMemo(() => users.map(userToContact), [users]);
    return { loading, contacts };
}

export function useUserSearch(term?: string, userIds?: string[]) {
    const [users, setUsers] = useState<User[]>([]);
    const [searchTerm, setSearchTerm] = useState(term);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setLoading(true);
        makeGraphqlRouter()
            .post("", {
                query: getUsersQuery,
                variables: { input: { terms: searchTerm ?? "", userIds } },
            })
            .then((response) => {
                setUsers(response.data?.data?.getUsers?.result ?? []);
            });
    }, [searchTerm, userIds]);
    return { users, setSearchTerm, loading };
}

/**
 * @deprecated Use useSessionContext instead
 */
export function useFetchOrySessionDirectly() {
    const [userId, setUserId] = useState<string | null>(null);
    const [firstName, setFirstName] = useState<string | null>(null);
    const [lastName, setLastName] = useState<string | null>(null);
    const [tenant, setTenant] = useState<string | null>(null);
    const [email, setEmail] = useState<string | null>(null);
    useEffect(() => {
        fetch(`${env.REACT_APP_ORY_API_URL}/sessions/whoami`, { credentials: "include" })
            .then((res) => res.json())
            .then((j) => {
                setUserId(j.identity.id);
                setFirstName(j.identity.traits?.name?.first);
                setLastName(j.identity.traits?.name?.last);
                setEmail(j.identity.traits?.email);
                setTenant(
                    tenantFromLocalStorage && tenantFromLocalStorage?.length > 0
                        ? tenantFromLocalStorage
                        : j.identity.metadata_public.tenant
                );
            });
    }, []);
    return { userId, firstName, lastName, tenant, email };
}
