import { FormattedMessage } from "react-intl";
import { InlineFilters } from "@ignite-analytics/filters";
import { Plus } from "@ignite-analytics/icons";
import { Button, Stack, Typography } from "@mui/material";
import { GridApi, GridDensity } from "@mui/x-data-grid-pro";
import ContractStatusFilters from "@/components/ContractStatusFilters";
import { TableActionBar } from "@/routes/list/ActionBar/TableActionBar";
import { readPinnedFiltersFieldsIds } from "@/routes/list/proTableManagmentDao";
import { SearchField } from "@/routes/list/SearchField";

interface CustomToolbarProps {
    tenant: string;
    writeAccess: boolean;
    density: GridDensity;
    onDensityChange: (density: GridDensity) => void;
    apiRef: React.MutableRefObject<GridApi>;
    openCreateContractModal: () => void;
    searchTerm: string | undefined;
    onSearch: (value: string) => void;
    setOpenLayoutPopup: () => void;
    canEditLayouts: boolean;
    hasRenewalDate: boolean;
    onPinnedFilter: (fieldId: string) => void;
}

export const CustomToolbar: React.FC<CustomToolbarProps> = ({
    tenant,
    writeAccess,
    density,
    onDensityChange,
    apiRef,
    openCreateContractModal,
    searchTerm,
    onSearch,
    setOpenLayoutPopup,
    canEditLayouts,
    hasRenewalDate,
    onPinnedFilter,
}) => {
    return (
        <Stack py={2.5} gap={1.5} direction="row" alignItems="center">
            <Button
                size="small"
                disabled={!writeAccess}
                data-testid="add-contract-btn"
                onClick={openCreateContractModal}
                startIcon={<Plus />}
            >
                <Typography variant="textSm">
                    <FormattedMessage defaultMessage="Add contract" />
                </Typography>
            </Button>

            <SearchField initialValue={searchTerm} onSearch={onSearch} />
            <Stack>
                {/* wrap in a Stack, otherwise the filter will be too wide */}
                {/* hasEditPermission is used to check if user can pin filters. We store pinned filters in localStorage */}
                <InlineFilters
                    hideFilterOptions
                    onPinField={onPinnedFilter}
                    pinnedFields={readPinnedFiltersFieldsIds(tenant)}
                    hasEditPermission
                />
            </Stack>
            <Stack direction="row" ml="auto" gap={1.5}>
                <ContractStatusFilters hasRenewalDate={hasRenewalDate} />
                <TableActionBar
                    density={density}
                    onDensityChange={onDensityChange}
                    apiRef={apiRef}
                    setOpenLayoutPopup={setOpenLayoutPopup}
                    canEditLayouts={canEditLayouts}
                />
            </Stack>
        </Stack>
    );
};
