import { Button, Paper, Stack, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { ContactsDropdown } from "@/components/ui/ContactDropdown/contactsEdit";

import { Contract } from "@/types";
import { InformationTooltip } from "@/components/InformationTooltip";
import { ViewAllUsersPopover } from "./AccessCard/ViewAllUsersPopover";
import { useContractPermissions } from "@/hooks/useContractPermissions/index";
import { UserAvatarWithRole } from "@/components/UserAvatars/UserAvatarWithRole";

export interface ContractResponsibleCardProps {
    contract: Contract;
    editMode: boolean;
    onContractChange: (contract: Contract) => void;
}

export const ContractResponsibleCard: React.FC<ContractResponsibleCardProps> = ({
    contract,
    editMode,
    onContractChange,
}) => {
    const [editOpen, setEditOpen] = useState(false);

    // Only show users with admin, editor or contract-editor role
    // can be set as contract responsible
    const { permissions, refetch } = useContractPermissions([contract.id]);

    const contractResponsibleWithPermissions = permissions.filter(
        (permission) =>
            permission.writes.some((write) => write.isResponsible) ||
            permission.reads.some((read) => read.isResponsible)
    );
    const contractResponsible = contractResponsibleWithPermissions.map((permission) => permission.user);
    const options = permissions.map((permission) => permission.user);

    useEffect(() => {
        if (!editMode) {
            setEditOpen(false);
            refetch();
        }
    }, [editMode, refetch]);

    return (
        <Stack p={2.5} gap={2.5} component={Paper} elevation={1} alignItems="stretch">
            <Stack width="100%" direction="row" alignItems="center" justifyContent="space-between">
                <Stack direction="row" alignItems="center" gap={1}>
                    <Typography variant="textLg" fontWeight={500}>
                        <FormattedMessage defaultMessage="Contract responsible" />
                    </Typography>

                    <InformationTooltip
                        content={
                            <FormattedMessage defaultMessage="Contract responsible can edit or delete contract. They are also the one who will get alerts that are added on this contract" />
                        }
                    />
                </Stack>
                {!editOpen && editMode && (
                    <Button color="secondary" size="small" onClick={() => setEditOpen(true)}>
                        <FormattedMessage defaultMessage="Edit" />
                    </Button>
                )}
            </Stack>
            <Stack>
                {editOpen && editMode ? (
                    <ContactsDropdown
                        multiple
                        defaultValue={contractResponsible}
                        options={options}
                        onChange={(contracts) => {
                            onContractChange({
                                ...contract,
                                contractResponsibleIds: contracts.map((user) => user.id),
                            });
                        }}
                    />
                ) : (
                    <Stack direction="row" alignItems="center" gap={1}>
                        <UserAvatarWithRole
                            permissions={contractResponsibleWithPermissions}
                            maxAvatars={5}
                            avatarSize={32}
                        />
                        <ViewAllUsersPopover permissions={contractResponsibleWithPermissions} />
                    </Stack>
                )}
            </Stack>
        </Stack>
    );
};
