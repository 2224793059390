import { ContractListSearchInput } from "@/routes/list/schema";
import { Status } from "@/types";

export const statusToSearchParams = (
    status: Status
): Pick<ContractListSearchInput, "status" | "sortOrder" | "sortBy"> => {
    switch (status) {
        case "all":
        case "active":
            return { status } as const;
        case "renewing-soon":
            return { status, sortBy: "renewalDate", sortOrder: "asc" } as const;
        case "expiring-soon":
            return { status, sortBy: "endDate", sortOrder: "asc" } as const;
        case "upcoming":
            return { status, sortBy: "startDate", sortOrder: "asc" } as const;
        case "expired":
            return { status, sortBy: "endDate", sortOrder: "desc" } as const;
    }
};
