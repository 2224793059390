import { createRoute } from "@tanstack/react-router";

import { AskDocumentExperimentPage } from "@/pages/askDocumentExperiment";
import { rootRoute } from "..";

export const askDocumentExperimentRoute = createRoute({
    getParentRoute: () => rootRoute,
    path: "ask-document-experiment",
    component: AskDocumentExperimentPage,
});
